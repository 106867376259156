import { Typography } from '@material-ui/core';
import noWebResourceImage from 'assets/images/noWebResource.svg';
import { BsModal, Icon, IconInput, Loading, Table } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrgSharedPapers, getOrgSharedPapersByName, deleteOrgSharedPapers } from 'services/api/organization/sharedPapers';
import { useAlert } from 'utils/hooks/useAlert';
import { UiActionBox, UiflexBox, UiTable } from 'styles';
import { UiOrganizationPaperBox, UiTableBox, UiNoDataBox } from './OrganizationPaper.style';

const SCHEMA = {
  year: {
    name: '學年度',
    defaultValue: '',
  },
  eduSubjectName: {
    name: '學制科目',
    defaultValue: '',
  },
  teacherName: {
    name: '出卷老師',
    defaultValue: '',
  },
  paperName: {
    name: '試卷名稱',
    defaultValue: '',
  },
};

export const OrganizationPaper = () => {
  const { setAlert } = useAlert();
  const { organizationId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [orgSharedPapersApiData, setOrgSharedPapersApiData] = useState();
  const [filterData, setFilterData] = useState({
    name: null,
  });
  const [pagination, setPagination] = useState({
    nowPage: 0,
    rowsPage: 10,
    totalPage: 0,
  });

  /* 取得機構試卷 */
  const fetchOrganizationPapers = async (params) => {
    setIsLoading(true);
    const { status, data } = params?.searchKey ? await getOrgSharedPapersByName(organizationId, params) : await getOrgSharedPapers(organizationId, params);
    if (status === 'success') {
      setOrgSharedPapersApiData(data);
      setPagination(prev => ({
        ...prev,
        totalPage: data.total,
      }));
    } else {
      setAlert('取得機構試卷失敗', 'error');
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    /* 預覽試卷 */
    const onViewPaper = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${params.id}`;
      window.open(url);
    };
    /* 開關刪除機構試卷彈窗 */
    const toggleDeleteModal = () => {
      setIsOpenDeleteModal(prev => !prev);
    };
    /* 點選刪除彈窗 確認按鈕 */
    const clickDeleteModalOkButton = async () => {
      setIsLoading(true);
      const deleteOrgSharedPapersParams = {
        paperIds: [params.id]
      };
      const { status } = await deleteOrgSharedPapers(organizationId, deleteOrgSharedPapersParams);
      if (status === 'success') {
        setAlert('刪除機構試卷成功', 'success');
      } else {
        setAlert('刪除機構試卷失敗', 'error');
      }
      setIsLoading(false);
      toggleDeleteModal();
      const fetchOrganizationPapersParams = {
        page: pagination.nowPage + 1,
        limit: pagination.rowsPage,
        searchKey: filterData.name,
      };
      await fetchOrganizationPapers(fetchOrganizationPapersParams);
    };
    return (
      <>
        <UiflexBox>
          <Icon name="watchFile" title="預覽試卷" onClick={onViewPaper} />
          <Icon name="delete" title="刪除試卷" onClick={toggleDeleteModal} />
        </UiflexBox>
        {isOpenDeleteModal &&
          <BsModal
            title="即將刪除 111學年度國小數學測驗"
            isFull={true}
            isLoading={isLoading}
            open={isOpenDeleteModal}
            onCancel={toggleDeleteModal}
            onOk={clickDeleteModalOkButton}
          >
            <Typography>刪除後其他老師將無法取用本試卷，出題老師的自有試卷將不受影響</Typography>
          </BsModal>}
      </>
    );
  };

  const papers = useMemo(() => {
    if (!orgSharedPapersApiData) return [];
    const orgPapers = orgSharedPapersApiData.papers.map(paper => {
      const orgPaper = {
        id: paper.uid,
        year: paper.year,
        eduSubjectName: paper.eduSubjectName,
        teacherName: paper.maintainer,
        paperName: paper.name,
      };
      return orgPaper;
    });
    return orgPapers;
  }, [orgSharedPapersApiData]);

  /* 換頁 */
  const changePageRows = pageParams => {
    const { newPage, newRowsPage } = pageParams;
    setPagination(prev => ({
      ...prev,
      nowPage: newPage,
      rowsPage: newRowsPage,
    }));
    const params = {
      page: newPage + 1,
      limit: newRowsPage,
      searchKey: filterData.name,
    };
    fetchOrganizationPapers(params);
  };

  /* 搜尋試卷名稱 */
  const searchOrganizationPapers = (paperName) => {
    const params = {
      page: 1,
      limit: pagination.rowsPage,
      searchKey: paperName,
    };
    setFilterData(prev => ({
      ...prev,
      name: paperName,
    }));
    fetchOrganizationPapers(params);
  };

  useEffect(() => {
    const params = {
      page: 1,
      limit: 10,
    };
    fetchOrganizationPapers(params);
  }, []);

  return (
    <UiOrganizationPaperBox>
      <UiActionBox>
        <UiflexBox>
          <IconInput
            placeholder="搜尋試卷名稱"
            onChange={searchOrganizationPapers}
            onClick={searchOrganizationPapers}
          />
        </UiflexBox>
      </UiActionBox>
      <UiTableBox>
        {isLoading ? (
          <Loading />
        ) : (
          papers.length ? (
            <UiTable>
              <Table
                data={papers}
                schema={SCHEMA}
                ActionComponents={ActionComponents}
                totalPage={pagination.totalPage}
                nowPage={pagination.nowPage}
                changePage_Rows={changePageRows}
              />
            </UiTable>
          ) : (
            <UiNoDataBox>
              <img src={noWebResourceImage} alt={noWebResourceImage} />
              <Typography>尚無資料</Typography>
            </UiNoDataBox>
          )
        )}
      </UiTableBox>
    </UiOrganizationPaperBox>
  );
};
