/* eslint-disable max-len */
import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tooltip as MatTooltip } from '@material-ui/core';
import { EDIT_STATUS } from 'constants/index';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Table,
  Button,
  IconInput,
  Select,
  TimePicker,
  Icon,
  Breadcrumbs,
  Loading,
  Tooltip,
} from 'components'; //IconInput, Select,
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { useSessions } from 'store/sessions';
import { useClass } from 'store/class';
import { getOrganizationSessionEduSubjectOptions } from 'services/api/organization/session';
import { useAlert } from 'utils/hooks/useAlert';
import { useDateInterval } from 'utils/hooks/useDateInterval';
import { SESSION_START_TIME_TYPE, } from 'constants/index';
import { UiTableInfo } from 'styles';
import { 
  UiButtonBox,
  UiResourceCell,
  UiResourceContent,
  UiResourceCount,
  UiStartTimeIcon,
} from './SessionManagementTable.style';
import { ActionComponents } from './ActionComponents';

/**
 * 課程列表
 */

const NOW = 'NOW';
const PROCESSING = 'PROCESSING';
const PAST = 'PAST';
const FUTURE = 'FUTURE';
const ALL = 'ALL';

const SESSION_START_TIME_TYPE_INFO = {
  [SESSION_START_TIME_TYPE.YET]: '尚未開始',
  [SESSION_START_TIME_TYPE.ALREADY]: '已結束',
  [SESSION_START_TIME_TYPE.PROCESSING]: '進行中'
};

const selectOptions = [
  {
    name: '今日課程',
    value: NOW
  },
  {
    name: '當前課程',
    value: PROCESSING
  },
  {
    name: '已上課程',
    value: PAST
  },
  {
    name: '未來課程',
    value: FUTURE
  },
  {
    name: '所有課程',
    value: ALL
  }
];

export const sessionManagementTableContext = createContext();

export const SessionManagementTable = ({
  noFilter = false,
  defaultDuration = ALL
}) => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const [, { todayInterval, processingInterval, pastInterval, futureInterval, allInterval }] = useDateInterval();
  const { organizationId, classId } = useParams();
  const [{ sessions, sessionSearchParams, groupSessionSearchParams },
    {
      getOrganizationSessions,
      setSessionSearchParams,
      setGroupSessionSearchParams,
  }] = useSessions();
  const [{ classes }] = useClass();

  const [
    {
      sessionName,
      groupName,
      hostName,
      educationalSystem,
      subject,
      hostMobileNumber,
      startAt,
      endAt,
      nowPage,
      rowsPage,
      goal,
      educationOptions,
      subjectMap,
      subjectOptions,
      condition,
      dateInterval,
      schema,
      isLoading,
      isOpenTooltip,
      isReverseTooltip,
    }, setState
  ] = useSetState({
    sessionName: '',
    groupName: '',
    hostName: '',
    educationalSystem: '',
    subject: '',
    hostMobileNumber: '',
    startAt: null,
    endAt: null,
    nowPage: classId ? groupSessionSearchParams.nowPage : sessionSearchParams.nowPage,
    rowsPage: classId ? groupSessionSearchParams.rowsPage : sessionSearchParams.rowsPage,
    goal: classId ? groupSessionSearchParams.goal : sessionSearchParams.goal,
    educationOptions: [],
    subjectMap: null,
    subjectOptions: [],
    condition: classId ? groupSessionSearchParams.condition : sessionSearchParams.condition,
    dateInterval: classId ? groupSessionSearchParams.dateInterval : sessionSearchParams.dateInterval,
    schema: {},
    isLoading: true,
    isOpenTooltip: [],
    isReverseTooltip: false,
  });

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes.dataInfo.name}-課程總覽`,
      link: `/organization/${organizationId}/class/${classId}/customer`,
      icon: 'importContacts'
    }
  ];

  const defaultSchema = {
    groupName: {
      name: '班級名稱',
      defaultValue: ''
    },
    name: {
      name: '課程名稱',
      defaultValue: ''
    },
    subject:{
      name:'科目',
      defaultValue:'--'
    },
    sessionStatus:{
      name:'狀態',
      defaultValue:'--'
    },
    startTime: {
      name: '時間',
      defaultValue: ''
    },
    classTime: {
      name: '課程時數',
      defaultValue: ''
    },
    hostName: {
      name: '授課教師',
      defaultValue: ''
    },
    resourceId: {
      name: '上課資源',
      defaultValue: ''
    },
    attendance: {
      name: '出勤',
      defaultValue: '-'
    }
  };

  const selectInputOptions = [
    {
      name: '課程名稱',
      value: 'sessionName',
    },
    !classId &&
    {
      name: '班級名稱',
      value: 'groupName',
    },
    {
      name: '授課教師名稱',
      value: 'hostName'
    }

  ];

  const resetFormData = (value) => {
    setState({
      sessionName: '',
      groupName: '',
      hostName: '',
      hostMobileNumber: '',
      startAt: '',
      rowsPage: 10,
      nowPage: 0,
      [goal]: value
    });
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'warning');
        return;
      } else {
        resetFormData(value);
      }
    }
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const educationChangeHandler = (value, key) => {
    console.log('education value', value);
    console.log('key', key);
    setState({
      educationalSystem: value,
      nowPage: 0
    });
  };

  const subjectChangeHandler = value => {
    console.log('subject value', value);
    setState({
      subject: value,
      nowPage: 0
    });
  };

  const onchangeHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const getDateDuration = dateType => {
    switch (dateType) {
      case NOW:
        return { ...todayInterval() };
      case PAST:
        return { ...pastInterval() };
      case PROCESSING:
        return { ...processingInterval() };
      case FUTURE:
        return { ...futureInterval() };
      default:
      case ALL:
        return { ...allInterval() };
    }
  };

  const onSelectChangeHandler = selected => {
    const dateParams = getDateDuration(selected);
    setState({
      nowPage: 0,
      dateInterval: selected,
      ...dateParams
    });
  };

  const goCreateSession = () => {
    history.push(`/organization/${organizationId}/class/${classId}/session/${EDIT_STATUS.CREATE}`);
  };

  const fetchOrganizationSessionEduSubjectOptions = async () => {
    const { isSuccess, data } = await getOrganizationSessionEduSubjectOptions(organizationId);
    if (isSuccess) {
      const { educationalSystems, subjects } = data;
      const nextEducationalSystems = educationalSystems.map( item => ({...item, value: item.code, }));

      setState({
        educationOptions: nextEducationalSystems,
        subjectMap: subjects,
      });
    } else {
      setAlert('取得 學制/科目 列表失敗！', 'error');
    }
  };

  useEffect(() => {
    fetchOrganizationSessionEduSubjectOptions();
    if (classId) delete defaultSchema.groupName;
    setState({
      schema: defaultSchema
    });
  }, []);

  useEffect(() => {
    if (!educationalSystem) return;
    setState({
      subject: '',
      subjectOptions: subjectMap[educationalSystem].map(item => ({...item, value: item.code, })),
    });
  }, [educationalSystem]);

  const fetchSessions = async () => {
    setState({ isLoading: true });
    const dateParams = startAt === null || endAt === null 
      ? getDateDuration(sessionSearchParams.dateInterval) 
      : { startAt, endAt };

    const params = {
      [goal]: condition,
      hostMobileNumber,
      educationalSystem,
      subject,
      ...dateParams,
      nowPage,
      rowsPage
    };
    if(classId) delete params.groupName;
    await getOrganizationSessions(params);
    setState({ isLoading: false });
  };

  useEffect(() => {
    fetchSessions();
  }, [
    sessionName,
    groupName,
    hostName,
    hostMobileNumber,
    educationalSystem,
    subject,
    startAt,
    endAt,
    nowPage,
    rowsPage]);

  const getStartTimeType = (startAt, endAt) => {
    const now = new Date().getTime();
    if (now < startAt) return SESSION_START_TIME_TYPE.YET;
    if (now > endAt) return SESSION_START_TIME_TYPE.ALREADY;
    return SESSION_START_TIME_TYPE.PROCESSING;
  };

  // eslint-disable-next-line react/prop-types
  const StartTimeIcon = ({ children, type }) => (
    <UiStartTimeIcon type={type}>
      <Icon
        className="icon"
        name="info"
        size="small"
        title={SESSION_START_TIME_TYPE_INFO[type]}
        haveBg={false}
      />
      <span>{children}</span>
    </UiStartTimeIcon>
  );

  /* Tooltip 開啟 */
  const mouseEnterHandler = (dataLen, index, e, resourceObject) => {
    const resourceCellHeight = document.querySelector(`.resourceCell${index}`).clientHeight;
    const tooltipHeight = 20 * resourceObject.length;
    const isReverse = index >= dataLen - 2 || window.innerHeight - e.clientY < tooltipHeight + resourceCellHeight;
    const newIsOpenTooltip = new Array(dataLen).fill(false);
    newIsOpenTooltip[index] = true;
    setTimeout(() => {
      setState({ isOpenTooltip: newIsOpenTooltip, isReverseTooltip: isReverse });
    }, 100);
  };

  /* Tooltip 關閉 */
  const mouseLeaveHandler = async (dataLen) => {
    const newIsOpenTooltip = new Array(dataLen).fill(false);
    setState({ isOpenTooltip: newIsOpenTooltip });
  };

  /* Tooltip 內容 */
  const TooltipContent = ({ resourceObject }) => {
    return(
      <>
        {
          resourceObject.map(obj => {
            const { resourceId, displayName } = obj;
            return <li key={resourceId}>{displayName}</li>;
          })
        }
      </>);
  };
  TooltipContent.propTypes = {
    resourceObject: PropTypes.func,
  };

  const formatSessionsData = data => data.map((item, index) => {
    const { endAt, hostAttendance, hostName, resourceId, resources, resourceObject, startAt } = item;
    const startTimeType = getStartTimeType(item.startAt, item.endAt);
    const resourcesName = _.map(resourceObject, 'displayName');
    const matTooltipTitle = <span style={{ fontSize: '0.875rem' }}>{resourcesName?.join('、')}</span>;
    const resId = resourceId && <MatTooltip title={matTooltipTitle}>
                                  <UiResourceContent>{resourcesName?.join('、')}</UiResourceContent>
                                </MatTooltip>;
    const resourcesId =
      resources.length === 1
        ? <MatTooltip title={matTooltipTitle}>
            <UiResourceContent>{resourcesName?.join('、')}</UiResourceContent>
          </MatTooltip>
        : resourcesName?.join('、').length <= 10
          ? <UiResourceContent>{resourcesName?.join('、')}</UiResourceContent>
          : <UiResourceCell
              className={`resourceCell${index}`}
              onMouseEnter={(e)=>mouseEnterHandler(data.length, index, e, resourceObject)}
              onMouseLeave={()=>mouseLeaveHandler(data.length)}
            >
              <UiResourceContent>{resourcesName?.join('、')}</UiResourceContent>
              <UiResourceCount>+{resources.length - 1}</UiResourceCount>
              <Tooltip
                isOpen={isOpenTooltip[index]}
                isReverse={isReverseTooltip}
              >
                <TooltipContent resourceObject={resourceObject}/>
              </Tooltip>
            </UiResourceCell>;

    return {
      ...item,
      resourceId: resId || resourcesId,  // 上課資源
      classTime: ((endAt - startAt) / 60 / 1000) + '分',
      startTime: (
        <StartTimeIcon type={startTimeType}>
          {format(new Date(startAt), 'yyyy-MM-dd HH:mm')} ~ {format(new Date(endAt), 'HH:mm')}
        </StartTimeIcon>
      ),
      attendance:(
        <>
          <div>{hostAttendance ? hostName :''}</div>
          {hostAttendance?.checkInAt ? format(new Date(hostAttendance?.checkInAt), 'HH:mm') : '-'}
          {' / '}
          {hostAttendance?.checkOutAt ? format(new Date(hostAttendance?.checkOutAt), 'HH:mm'): '-'}
        </>
      ),
    };
  });

   useEffect(()=>{
      const payload = {
        goal: goal || '',
        condition: condition || '',
        dateInterval: dateInterval || ALL,
        nowPage: nowPage || 0,
        rowsPage: rowsPage || 10,
      };
      classId ? setGroupSessionSearchParams(payload) : setSessionSearchParams(payload);
   },[goal,condition,dateInterval,nowPage,rowsPage]);

  return (
    <UiTable>
      {
        classId && <Breadcrumbs list={BreadcrumbsList} />
      }
      {
        !noFilter && (
          <UiActionBox>
            <UiflexBox>
              <Select
                label="搜尋目標" options={selectInputOptions}
                submitHandler={value => onSubmitHandler(value, 'goal')}
                value={goal}
              />
              <Select
                label="學制" 
                options={educationOptions}
                onChangeHandler={educationChangeHandler}
                value={educationalSystem}
              />
              <Select
                label="科目" 
                options={subjectOptions}
                submitHandler={subjectChangeHandler}
                value={subject}
              />
              <IconInput
                placeholder="搜尋"
                onChange={value => onSubmitHandler(value, 'condition')}
                value={condition}
              />
              <Select
                options={selectOptions}
                value={dateInterval}
                submitHandler={value => onSelectChangeHandler(value)}
                label="課程區間"
              />
              {
                dateInterval === NOW &&
                <>
                  <TimePicker
                    label="選擇課程開始時間"
                    value={startAt}
                    onChange={value => onchangeHandler(value, 'startAt')}
                  />
                  <TimePicker
                    label="選擇課程結束時間"
                    value={endAt}
                    onChange={value => onchangeHandler(value, 'endAt')}
                  />
                </>
              }
            </UiflexBox>
            {classId &&
              <UiButtonBox>
                <Button
                  buttonColor="info"
                  icon='exitToApp'
                  onClick={() => history.goBack()}
                >
                  回到上一頁
                </Button>
                <Button
                  buttonColor='highlight'
                  icon='add'
                  onClick={() => goCreateSession()}>新增課程</Button>
              </UiButtonBox>
            }

          </UiActionBox>
        )
      }
      {
        isLoading 
          ? <Loading />
          : sessions.total === 0
            ? <UiTableInfo>尚無課程</UiTableInfo>
            : <sessionManagementTableContext.Provider
                value={{
                  fetchSessions: fetchSessions,
                }}
              >
                <Table
                  data={formatSessionsData(sessions.data)}
                  schema={schema}
                  stateShowText={[]}
                  changePage_Rows={changePage_Rows}
                  totalPage={sessions.total}
                  ActionComponents={ActionComponents}
                  nowPage={nowPage}
                />
              </sessionManagementTableContext.Provider>

      }
    </UiTable>
  );
};

SessionManagementTable.propTypes = {
  noFilter: PropTypes.bool,
  defaultDuration: PropTypes.oneOf([ALL, NOW, PROCESSING, PAST, FUTURE, ALL])
};
