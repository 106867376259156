import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { getTime } from 'date-fns';
import { BsModal, Icon, OptionMenu, ResourceModal } from 'components';
import { EDIT_STATUS, SESSION_START_TIME_TYPE, PREVIEW_TIMESTAMP, } from 'constants/index';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useSessions } from 'store/sessions/index';
import { useUser } from 'store/user/index';
import { sessionManagementTableContext } from './SessionManagementTable';
import { UiIconBox, UiIcon } from './SessionManagementTable.style';


// eslint-disable-next-line react/prop-types
export const ActionComponents = ({ params: session }) => {
  const {
    id: sessionId,
    groupId,
    groupName,
    hostId,
    timeSpanId,
    resources,
    endAt,
    startAt,
    name,
    duration,
    subject,
    type,
    mmsCourseId,
    users,
    linkResources,
  } = session;
  const { fetchSessions } = useContext(sessionManagementTableContext);
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const [isOpen,setIsOpen] = useState(false);
  const [targetSession, setTargetSession] = useState('');
  const [targetClassId, setTargetClassId] = useState('');
  const [{ myOrganization }] = useUser();
  const [, { deleteOrganizationGroupSession }] = useSessions();
  const [{
    isLoading,
    isOpenOptionMenu,
    isOpenResourceModal,
    targetSessionId,
    targetTimeSpanId,
  }, setState ] = useSetState({
    isLoading: false,
    isOpenOptionMenu: false,
    isOpenResourceModal: false,
    targetSessionId: '',
    targetTimeSpanId: '',
  });

  const isMmsCourse = !!mmsCourseId;
  const isIndividualTutorialCenterCourse = users?.length === 1; // 是否為一對一補習班課
  const isCourseExpired = getTime(new Date(endAt)) < getTime(new Date());


  const getStartTimeType = (startAt, endAt) => {
    const now = new Date().getTime();
    if (now < startAt) return SESSION_START_TIME_TYPE.YET;
    if (now > endAt) return SESSION_START_TIME_TYPE.ALREADY;
    return SESSION_START_TIME_TYPE.PROCESSING;
  };
  const startTimeType = getStartTimeType(startAt, endAt);

  const ButtonClick = () => {
    setState({ isOpenResourceModal: true, sessionId });
  };

  const onViewSession = () => {
    const url = `${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${mmsCourseId || sessionId}`;
    window.open(url);
  };

  const modalToggle = (name,targetClass,sessionId,timeSpanId) => {
    name && setTargetSession(name);
    targetClass && setTargetClassId(targetClass);
    sessionId && setState({
      targetSessionId: sessionId,
      targetTimeSpanId: timeSpanId
    });
    setIsOpen(!isOpen);
  };

  const onDeleteSession = async () => {
    setState({isLoading:true});
    const isSuccess = await deleteOrganizationGroupSession(targetClassId, targetSessionId, targetTimeSpanId);
    setState({isLoading:false});
    modalToggle();
    if(isSuccess) await fetchSessions();
   };

     /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = resourceValue => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank');  // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false});
  };

  const location = {
    state: {
      sessionDuration: parseInt(duration),
      sessionEndAt: endAt,
      sessionHostId: hostId,
      sessionName: name,
      sessionResources: resources,
      sessionStartAt: startAt,
      sessionSubject: subject,
      sessionTimeSpanId: timeSpanId,
      sessionType: type,
      sessionMmsCourseId: mmsCourseId,
      sessionLinkResources: linkResources,
    }
  };

  // MMS 課的 optionMenu
  const mmsCourseMenu = [];
  // OptionMenu - 課後回饋
  const feedbackObject = {
    label: '課後回饋',
    onClick: () => {
      const pathname = `${process.env.REACT_APP_ONELINK_DOMAIN}/home/${organizationId}/class/${groupId}/sessions/${sessionId}/feedback`;
      window.open(pathname, '_blank');
    }
  };
  (isMmsCourse && isIndividualTutorialCenterCourse) && mmsCourseMenu.push(feedbackObject);

  // OneLink 課的 optionMenu
  const linkCourseMenu = [
    {
      label: '複製課程',
      onClick: () => {
        location.pathname = `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.COPY}`;
        history.push(location);
      }
    },
    {
      label: '刪除課程',
      onClick: () => modalToggle(name, groupId, sessionId, timeSpanId)
    },
  ];

  // 判斷課程是否「未過期」並加入至 menu
  if (!isCourseExpired) {
    // OptionMenu - 編輯課程
    const editObject = {
      label: '編輯課程',
      onClick: () => {
        location.pathname = `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.EDIT}`;
        history.push(location);
      }
    };
    if (isMmsCourse) {
      mmsCourseMenu.unshift(editObject);
    } else {
      linkCourseMenu.unshift(editObject);
    }
  }

  /* OptionMenu */
  const optionMenuData = isMmsCourse ? mmsCourseMenu : linkCourseMenu;


  /* 判斷現在是否為預課時間 */
  const isPreviewTime = () => {
    const nowTimestamp = Date.now();  // 現在時間
    const previewStartTime = startAt - PREVIEW_TIMESTAMP;  // 預課開始時間

    if(nowTimestamp >= previewStartTime) return true;
  };

  const showMonitor = useMemo(()=>{
    if(myOrganization?.organization?.adminMonitorSessionSetting){
      return myOrganization.organization.adminMonitorSessionSetting === 'disallow'? false
      :true;
    }
    return false;
  },[myOrganization]);

  const openOptionMenu = () => {
    setState({ isOpenOptionMenu: true });
  };
  const closeOptionMenu = () => {
    setState({ isOpenOptionMenu: false });
  };

  const clickSessionReportHandler = () => {
    const location = {
      state: {
        className: groupName,
        groupId,
        sessionType: type,
      }
    };

    classId
      ? location.pathname = `/organization/${organizationId}/class/${groupId}/session/${sessionId}/timespan/${timeSpanId}/report`
      : location.pathname = `/organization/${organizationId}/class/session/${sessionId}/timespan/${timeSpanId}/report`;

    history.push(location);
  };

  return (
    <>
      <BsModal open={isOpen} title="是否要刪除此課程" onOk={onDeleteSession} onCancel={modalToggle} isLoading={isLoading}>
        確認刪除課程 <span style={{color: '#f00'}}>{`"${targetSession}"`}</span>
      </BsModal>

      { /* 課程教材 彈窗 */
        isOpenResourceModal &&
          <ResourceModal
            session={session}
            isOpenResourceModal={isOpenResourceModal}
            onOk={clickResourceModalOkHandler}
            onCancel={clickResourceModalCancelHandler}
          />
      }

      <UiIconBox>
        {/* {
          !isExpired && (
            <Icon
              title="編輯"
              onClick={() =>
                history.push(
                  `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.EDIT}`
                )}
              name='edit'
            />
          )
        } */}
        {
          type === 'normal' &&startTimeType === SESSION_START_TIME_TYPE.PROCESSING && showMonitor &&
          <Icon
          title="監課"
          onClick={ButtonClick}
          name='visibility' />
        }
        {/* <Icon
          icolor={type === 'interactive' ? '#ccc':'#fff'}
          title="課程報表"
          onClick={() =>{
            if(type === 'interactive') return;
            history.push(
              `/organization/${organizationId}/session/${sessionId}/timespan/${timeSpanId}/summary`
            );}}
          name='artTrack' /> */}
        {
          (type === 'interactive' || type === 'video' || type === 'collaborative') &&
          <Icon
            title="觀課"
            name="visibility"
            onClick={onViewSession}
          />
        }
        {((type === 'interactive' || type === 'video' || type === 'collaborative') && isPreviewTime()) && <Icon
          title="課堂報告"
          name='assessment'
          onClick={clickSessionReportHandler}
        />}
        {/* <Icon
          title="複製課程"
          name='fileCopy'
          onClick={() =>
            history.push(
              `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.COPY}`
            )}
          />
        <Icon
          title="刪除"
          onClick={() => modalToggle(name,groupId,sessionId,timeSpanId)}
          name='delete' /> */}
        {!!optionMenuData.length &&
          <UiIcon>
            <Icon
              title="更多"
              name="moreVert"
              haveBg={false}
              icolor="#242C3F"
              onClick={openOptionMenu}
            />
              {
                isOpenOptionMenu &&
                  <OptionMenu
                    onClose={closeOptionMenu}
                    options={optionMenuData}
                  />
              }
          </UiIcon>}
      </UiIconBox>
    </>
  );
};

ActionComponents.propTypes = {
  params: {
    sessionId: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    hostId: PropTypes.string,
    hostName: PropTypes.string,
    timeSpanId: PropTypes.string,
    resourceId: PropTypes.string,
    resources: PropTypes.object,
    endAt: PropTypes.number,
    startAt: PropTypes.number,
    name: PropTypes.string,
    duration: PropTypes.number,
    subject: PropTypes.string,
    type: PropTypes.string,
  }
};