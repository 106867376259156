export class PromiseQueue {
  constructor(tasks = [], concurrentCount = 1) {
    this.total = tasks.length;
    this.todo = [...tasks];
    this.running = [];
    this.errors = [];
    this.complete = [];
    this.count = concurrentCount;
  }
  runNext() {
    return ((this.running.length < this.count) && this.todo.length) || this.errors.length !== 0;
  }
  async run(handleComplete, isStopWhenError = false) {
    try{
      while (this.runNext()) {
        const promise = this.todo.shift();
        const res =  await promise();
        this.running.shift();
        res.isSuccess
          ? this.complete.push(res)
          : this.errors.push(res);
        await handleComplete(false, this.complete, this.errors, this.total);
        this.run(handleComplete);
        this.running.push(promise);
      }
    } catch(error) {
      this.errors.push(error);
    }
  }
  async getErrors() {
    return this.errors;
  }
  async getComplete() {
    return this.complete;
  }
}
