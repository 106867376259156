import React from 'react';
import { Table } from 'components';
import { convertArrayToMapById } from 'utils/array';
import PropTypes from 'prop-types';
import {
  UiSessionPerformance,
  UiPerformanceTitle,
} from './SessionPerformance.style';


/**
 * 上課表現
 */

 const schema = {
  attendeeNumber:{
    name: '座號',
    defaultValue: '-',
  },
  nickname:{
    name: '姓名',
    defaultValue: '-',
  },
  trophy:{
    name: '總獎盃數',
    defaultValue: '-',
  },
  raiseHand:{
    name: '舉手次數',
    defaultValue: '-',
  },
  // speakerSecond:{
  //   name: '發言秒數',
  //   defaultValue: '-',
  // },
  inattentive:{
    name: '不專心次數',
    defaultValue: '-',
  },
};

export const SessionPerformance = ({
    data,
  }) => {
  const { userRecords, users, userRecordAverages } = data;
  const sessionPeriodFormat = (time, status) => {
    if (status === 'attended' || !status) {
      if (time === 0) return '0';
      const minSec = time/1000;
      const min = Math.floor(minSec/60);
      const sec = time%60;
      return `${min}:${sec}`;
    } else {
      return '-';
    }
  };

  const statusFormat = (value, status) => {
    if (status === 'attended' || !status) {
      if (value === 0) return '0';
      return value;
    } else {
      return '-';
    }
  };
  
  const userRecordsLength = userRecords.length;
  const usersMap = convertArrayToMapById(users);
  userRecords.push(userRecordAverages);
  let nextUserRecord = userRecords.map((record, index) => {
    const { userId, trophy, inattentive, raiseHand, speakerSecond, status, } = record;
    const speakerSecondTime = sessionPeriodFormat(speakerSecond, status);
    return {
      userId,
      attendeeNumber: index === userRecordsLength ? ' ' : usersMap[userId]?.attendeeNumber,
      nickname: index === userRecordsLength ? '班級平均' : usersMap[userId]?.nickname,
      trophy: statusFormat(trophy, status),
      raiseHand: statusFormat(raiseHand, status),
      speakerSecond: speakerSecondTime,
      inattentive: statusFormat(inattentive, status),
    };
  });

  return (
    <UiSessionPerformance>
      <UiPerformanceTitle>
        學生課堂表現
      </UiPerformanceTitle>
      <Table
        data={nextUserRecord || []}
        schema={schema}
        totalPage={userRecords.length}
        isNeedPagination={false}
      />
    </UiSessionPerformance>
  );
};

SessionPerformance.propTypes = {
  data: PropTypes.array,
};
