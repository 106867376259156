import styled from 'styled-components';

export const UiPdfUploadPage = styled.div`
	display: block;
`;

export const UiBsContentContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	// background: '#eaeff1',
	padding: `${theme.spacing(6, 4)}`,
}));