import React, { useEffect, Children, useCallback } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';


import { ATTENDANCE_STATUS } from 'constants/index';
import { useSetState } from 'utils/hooks/useSetState';
import { convertArrayToMapById } from 'utils/array';
import { SectionCard, Slider, SectionCardItem, HeartbeatPacketBar, Loading } from 'components';
import { useSessions } from 'store/sessions';
import {
  UiSessionSummary,
  UiScreenshot,
  UiLine,
  UiColumn,
  UiHeartbeatPacketWrapper,
  UiGridContainerRelative,
  UiSectionCardTitleRow,
  UiSectionCardRowItem,
  UiColumnTimeInfo,
  UiAttendanceStatus,
  UiColumnHeartbeatPacket,
  UiClassStatusWrapper
} from './SessionSummary.style';
import { UiGridContainer, UiGridItem } from 'styles';

/**
 * 課堂報表
 */

const CLASS_STATUS = {
  BEFORE: 'BEFORE',
  PROGRESSING: 'PROGRESSING',
  AFTER: 'AFTER'
};

const girds = {
  desktop: 25,
  notebook: 25,
  pad: 25,
  phone: 100
};

const girdsInfo = {
  desktop: 6,
  notebook: 6,
  pad: 6,
  phone: 6
};

const gridClassStatus = {
  desktop: 8,
  notebook: 8,
  pad: 8,
  phone: 8
};


// eslint-disable-next-line react/prop-types
const Screenshot = ({ url, timestamp }) => (
  <UiScreenshot>
    <img src={url} alt="" />
    <div>{format(timestamp, 'yyyy-MM-dd hh:mm')}</div>
  </UiScreenshot>
);

const getMinToTimestamp = (min = 0) => {
  return 1000 * 60 * min;
};


// eslint-disable-next-line react/prop-types
const SectionCardRow = ({ children }) => {
  const childrenArray = Children.toArray(children);
  return (
    <UiGridContainer align="center" justify="center">
      <UiColumn {...girdsInfo}>{childrenArray[0]}</UiColumn>
      <UiColumn {...girdsInfo}>{childrenArray[1]}</UiColumn>
      <UiColumn {...girdsInfo}>{childrenArray[2]}</UiColumn>
      <UiColumn {...girdsInfo}>{childrenArray[3]}</UiColumn>

      <UiColumn desktop={76} notebook={76} pad={76} phone={76}>
        <UiClassStatusWrapper>
          <UiColumnHeartbeatPacket desktop={24} notebook={24} pad={24} phone={24}>
            <UiGridContainerRelative align="center" justify="center">
              <UiHeartbeatPacketWrapper>
                {childrenArray[4]}
              </UiHeartbeatPacketWrapper>
              <UiColumn desktop={33} notebook={33} pad={33} phone={33} x={-50}>
                <UiLine />
              </UiColumn>
              <UiColumn desktop={33} notebook={33} pad={33} phone={33}>
                <UiLine />
              </UiColumn>
              <UiColumn desktop={33} notebook={33} pad={33} phone={33} x={50}>
                <UiLine />
              </UiColumn>
            </UiGridContainerRelative>
          </UiColumnHeartbeatPacket>


          <UiColumnHeartbeatPacket desktop={60} notebook={60} pad={60} phone={60}>

            <UiGridContainerRelative align="center" justify="center">
              <UiHeartbeatPacketWrapper>
                {childrenArray[5]}
              </UiHeartbeatPacketWrapper>
            </UiGridContainerRelative>
          </UiColumnHeartbeatPacket>

          <UiColumnHeartbeatPacket desktop={16} notebook={16} pad={16} phone={16}>
            <UiGridContainerRelative align="center" justify="center">
              <UiHeartbeatPacketWrapper>
                {childrenArray[6]}
              </UiHeartbeatPacketWrapper>
              <UiColumn desktop={50} notebook={50} pad={50} phone={50}>
                <UiLine />
              </UiColumn>
              <UiColumn desktop={50} notebook={50} pad={50} phone={50} x={50}>
                <UiLine />
              </UiColumn>
            </UiGridContainerRelative>
          </UiColumnHeartbeatPacket>
        </UiClassStatusWrapper>
      </UiColumn>

    </UiGridContainer>
  );
};

// eslint-disable-next-line react/prop-types
const SectionCardTitleRow = ({ startAt = 0, endAt = 0 }) => (
  <UiSectionCardTitleRow align="center" justify="center">
    <UiColumn {...girdsInfo}>姓名</UiColumn>
    <UiColumn {...girdsInfo}>身份</UiColumn>
    <UiColumn {...girdsInfo}>到課狀況</UiColumn>
    <UiColumn {...girdsInfo}>上課時長</UiColumn>
    <UiColumn desktop={76} notebook={76} pad={76} phone={76}>
      <UiClassStatusWrapper>
        <UiGridContainerRelative align="center" justify="center">
          <UiColumn {...gridClassStatus} x={-50}>
            教師預課
          <UiColumnTimeInfo>{format(startAt - getMinToTimestamp(20), 'HH:mm')}</UiColumnTimeInfo>
          </UiColumn>
          <UiColumn {...gridClassStatus}>
            學生預課
          <UiColumnTimeInfo>{format(startAt - getMinToTimestamp(10), 'HH:mm')}</UiColumnTimeInfo>
          </UiColumn>
          <UiColumn {...gridClassStatus} x={50}>
            開始上課
          <UiColumnTimeInfo>{format(startAt, 'HH:mm')}</UiColumnTimeInfo>
          </UiColumn>
          <UiColumn desktop={60} notebook={60} pad={60} phone={60}>
            上課中
        </UiColumn>
          <UiColumn {...gridClassStatus}>
            課堂結束
          <UiColumnTimeInfo>{format(endAt, 'HH:mm')}</UiColumnTimeInfo>
          </UiColumn>
          <UiColumn {...gridClassStatus} x={50}>
            拖課時限
          <UiColumnTimeInfo>{format(startAt + getMinToTimestamp(10), 'HH:mm')}</UiColumnTimeInfo>
          </UiColumn>
        </UiGridContainerRelative>
      </UiClassStatusWrapper>
    </UiColumn>
  </UiSectionCardTitleRow>
);


export const SessionSummary = () => {
  const { t } = useTranslation();
  const [{ sessionSummary }, { getSessionSummary }] = useSessions();
  const { session, pings, screenshots,course } = sessionSummary || {};
  const sessionUser = sessionSummary && session ? session.users || [] : [];

  const attendance = Object.keys(pings || {}).filter(userId => userId !== session.hostId).length;

  const [{ memberMap }, setState] = useSetState({ memberMap: {} });

  useEffect(() => {
    getSessionSummary();
  }, []);

  const getAttendanceStatus = ping => {
    if (!ping) return ATTENDANCE_STATUS.ABSENCE;
    const { startAt, endAt } = session;
    const [enterTime,] = ping[0];
    const [, leaveTime] = ping[ping.length - 1];
    if (enterTime > startAt) return ATTENDANCE_STATUS.LATE;
    if (endAt > leaveTime) return ATTENDANCE_STATUS.LEAVE_EARLY;
    return ATTENDANCE_STATUS.ON_TIME;
  };

  const getDuration = (pings = []) => {
    const totalTime = pings.reduce((total, [enterTime, leaveTime]) => total + (leaveTime - enterTime), 0);
    const totalSeconds = Math.round(totalTime / 1000);
    const minute = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return totalTime ? `${minute}分 ${seconds}秒` : '--';
  };

  const getMemberMap = useCallback(() => {
    const { users, hostId, hostName } = session;
    let teacherMap = {};

    teacherMap[hostId] = {
      id: hostId,
      nickname: hostName,
      role: '教師',
      attendance: getAttendanceStatus(pings[hostId]),
      duration: getDuration(pings[hostId]),
      ping: pings[hostId]
    };

    const studentMap = convertArrayToMapById(
      users ? users.map(item => ({
        ...item,
        role: '學生',
        attendance: getAttendanceStatus(pings[item.id]),
        duration: getDuration(pings[item.id]),
        ping: pings[item.id]
      })) :[]
    );

    const memberMap = {
      ...teacherMap,
      ...studentMap
    };

    return memberMap;

  }, [session]);

  useEffect(() => {
    if (!session || !pings) return;
    const memberMap = getMemberMap();
    setState({ memberMap });
  }, [session, pings]);

  const getClassStatusByTime = time => {
    const startPointTime = session.startAt - getMinToTimestamp(20);
    const endPointTime = session.endAt;
    if (time < session.startAt) {
      return {
        status: CLASS_STATUS.BEFORE,
        startPoint: startPointTime,
        timeLength: getMinToTimestamp(20)
      };
    }
    if (time > session.endAt) {
      return {
        status: CLASS_STATUS.AFTER,
        startPoint: endPointTime,
        timeLength: getMinToTimestamp(10)
      };
    }
    return {
      status: CLASS_STATUS.PROGRESSING,
      startPoint: session.startAt,
      timeLength: session.endAt - session.startAt
    };
  };

  const getHeartbeatPosition = (ping, classStatus) => {
    return ping.map(item => {
      const positionArray = item.map((time, index) => {
        const isActive = index % 2 === 0;
        const { status, timeLength, startPoint } = getClassStatusByTime(time);
        const left = ((time - startPoint) / timeLength) * 100;
        return {
          status,
          left: left < 0 ? 0 : left,
          active: isActive
        };
      });
      return positionArray.flat();
    }).flat().filter(item => item.status === classStatus);
  };




  return sessionSummary ? (
    <UiSessionSummary>
      <SectionCard title="基本資料" primary>
        <UiGridContainer>
          <UiGridItem {...girds}>
            <SectionCardItem label="班級名稱：">
              {session.groupName}
            </SectionCardItem>
            <SectionCardItem label="課堂名稱：">
              {session.name}
            </SectionCardItem>
            <SectionCardItem label="授課教師：">
              {session.hostName}
            </SectionCardItem>
            <SectionCardItem label="教學資源：">
              {session.resourceName}
            </SectionCardItem>
          </UiGridItem>
          <UiGridItem {...girds}>
            <SectionCardItem label="上課日期：">
              {format(session.startAt, 'yyyy-MM-dd')}
            </SectionCardItem>
            <SectionCardItem label="上課時間：">
              {format(session.startAt, 'hh:mm')}
            </SectionCardItem>
            <SectionCardItem label="上課時長：">
              {format(session.endAt - session.startAt, 'mm')} 分
              </SectionCardItem>
            <SectionCardItem label="實際時長：">
              --
            </SectionCardItem>
          </UiGridItem>
          <UiGridItem {...girds}>
            <SectionCardItem label="班級人數：">
              {sessionUser.length} 人
              </SectionCardItem>
            <SectionCardItem label="到課人數：">
              {attendance} 人
            </SectionCardItem>
            <SectionCardItem label="缺席人數：">
              {sessionUser.length - attendance} 人
            </SectionCardItem>
          </UiGridItem>
        </UiGridContainer>
      </SectionCard>
      <SectionCard title="教學資訊" primary>
        {
          course && (
            <>
              <SectionCardItem label="教學頁數：">
                {course.pageIndex || 0} 頁
              </SectionCardItem>
              <SectionCardItem label="畫記頁數：">
                {course.totalCanvasRecords || 0} 頁
              </SectionCardItem>
            </>
          )
        }
        <SectionCard title="課堂截圖" secondary>
          <Slider>
            {
              screenshots.map(screenshot => (
                <Screenshot
                  key={screenshot.timestamp}
                  url={screenshot.url}
                  timestamp={screenshot.timestamp}
                />
              ))
            }
          </Slider>
        </SectionCard>
      </SectionCard>

      <SectionCard primary title={
        <SectionCardTitleRow startAt={session.startAt} endAt={session.endAt} />
      }>
        {
          Object.entries(memberMap).map(([key, member]) => (
            <SectionCardRow key={key}>
              <UiSectionCardRowItem>
                <div>{member.nickname}</div>
              </UiSectionCardRowItem>
              <UiSectionCardRowItem>
                <div>{member.role}</div>
              </UiSectionCardRowItem>
              <UiSectionCardRowItem>
                <UiAttendanceStatus status={member.attendance}>
                  {t(`attendance.${member.attendance}`)}
                </UiAttendanceStatus>
              </UiSectionCardRowItem>
              <UiSectionCardRowItem>
                <div>{member.duration}</div>
              </UiSectionCardRowItem>
              <HeartbeatPacketBar
                dots={
                  member.ping ?
                    getHeartbeatPosition(member.ping, CLASS_STATUS.BEFORE)
                    : []
                }
              />
              <HeartbeatPacketBar
                dots={
                  member.ping ?
                    getHeartbeatPosition(member.ping, CLASS_STATUS.PROGRESSING)
                    : []
                }
              />
              <HeartbeatPacketBar
                dots={
                  member.ping ?
                    getHeartbeatPosition(member.ping, CLASS_STATUS.AFTER)
                    : []
                }
              />
            </SectionCardRow>
          ))
        }
      </SectionCard>

    </UiSessionSummary>
  ) : <Loading />;
};

