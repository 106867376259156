import styled from 'styled-components';
import { SESSION_START_TIME_TYPE } from 'constants/index';

export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;

	& > button {
		margin: 0 0.5rem;

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const UiIconBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	& > * + * {
		margin-left: 3px;
	}

	> .recordingIcon {
		background-color: #f00;
	}
`;

export const UiIcon = styled.div`
	display: flex;
	position: relative;
`;

export const UiResourceCell = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
`;

export const UiResourceContent = styled.div`
	width: 100px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const UiResourceCount = styled.span`
	background: #FF9800;
	border-radius: 4px;
	color: #FFF;
	padding: 2px 4px;
`;

export const UiStartTimeIcon = styled.div`
display: flex;
align-items: center;
justify-content: center;

& > span {
	display: inline-block;
	padding-left: 0.5rem;
}

& > .icon {
	color:
		${(
			{ theme,
			type }
		) => {
		switch (type) {
		case SESSION_START_TIME_TYPE.YET:
		return theme.palette.main.info;
		case SESSION_START_TIME_TYPE.ALREADY:
		return theme.palette.main.disable;
		default:
		return theme.palette.main.warning;
		}
		}};
}
`;