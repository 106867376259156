import styled from 'styled-components';


export const UiOrganizationForm = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;

	& > div{
		width: 50vw;
	}
`;

export const UiHomeSettingButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 14px;
	width: 100%;
	color: ${({ theme }) => theme.palette.text.normal};
	background-color: ${({ theme }) => theme.palette.btn.menu};
	border-radius: 15px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;

	& > svg {
		margin-right: 0.5rem;
		font-size: 1.5rem;
		color: ${({ theme }) => theme.palette.text.show};
	}

	& > input {
		display: none;
	}
`;

export const UiButtonBox = styled.div`
	display: flex;
	display: none;
	justify-content: flex-end;
	width: 100%;

	& > button + button {
		margin-left: 0.5rem;
	}
`;
export const UiFilterBox = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

export const UiButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	& > button {
		margin: 0.5rem 0;
	}

	& > button + button {
		margin: 0.5rem 0.5rem 0.5rem;
	}
`;

export const UiBoxWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const UiAdvancedSettingBox = styled.div`
	padding-top: 40px;
`;

export const UiAdvancedSettingTitle = styled.div`
	font-size: 1.1rem;
	font-weight: bold;
	border-bottom: 1px solid #DADADA;
	padding-bottom: 10px;
`;

export const UiAdvancedSettingLogoBox = styled.div`
	height: 80px;
	width: 288px;
	padding: 16px 24px;
	border: 1px solid #E4E7EC;
`;

export const UiAdvancedSettingLogo = styled.img`
	height: ${ props => props.src === '' && '100%'};
	width: ${ props => props.src === '' && '100%'};
	position: relative;
	
	&:before {
		content: '';
		height: 100%;
		width: 100%;
		background-color: #F2F4F8;
		position: absolute;
	}
`;

export const UiCropperModalContentBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 520px;
	height: 400px;
	text-align: center;
	border: ${props => (props.border ? '1px dashed #D5D7DE' : 'none')};

	.uploading-icon{
		width: 100px;
		height: 100px;
		viewBox: 0 0 100 100;
	}

	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 6px;
		width: 153px;
		color: #fff;
		background-color: #45A5E6;
		border-radius: 4px;
		cursor: pointer;
		opacity: 1;
		transition: 0.35s;

		&:hover {
			opacity: 0.8;
		}

		svg {
			margin-right: 5px;
		}

		input {
			display: none;
		}
	}
`;