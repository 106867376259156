import styled from 'styled-components';

export const UiHomeInfo = styled.div`
	display: block;
`;

export const UiHomeInfoTitle = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
	color: ${({ theme }) => theme.palette.text.normal};
	margin-bottom: 1rem;
`;

export const UiInfoBox = styled.div`
	display: flex;
	align-items: flex-start;
	width: 70%;
	font-size: 1rem;
	color: ${({ theme }) => theme.palette.text.normal};
`;

export const UiDescription = styled.div`
	margin-right: 1rem;
	width: 100%;
	max-width: 60%;
`;

export const UiDetail = styled.div`
	padding: 0.3rem 2rem;
	background-color: ${({ theme }) => theme.palette.background.sec};
	border: 1px solid ${({ theme }) => theme.palette.line.dark};
	border-radius: 5px;

	& > div {
		display: flex;
		align-items: center;
		padding: 3px 0;
		width: 100%;

		& > svg {
			margin-right: 5px;
			font-size: 1.7rem;
			color: ${({ theme }) => theme.palette.text.normal};
		}
	}
`;
