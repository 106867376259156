import { Api } from 'services/api';

/**
 * 取得機構分享卷
 * @param {*} organizationId
 * @param {*} params
 * @returns
 */
export const getOrgSharedPapers = async (organizationId, params) => {
  const response = await Api.get(`/organizations/${organizationId}/orgSharedPapers`, { params });
  return response;
};

/**
 * 取得機構分享卷（含 search key）
 * @param {*} organizationId
 * @param {*} params
 * @returns
 */
export const getOrgSharedPapersByName = async (organizationId, params) => {
  const response = await Api.get(`/organizations/${organizationId}/orgSharedPapersByName`, { params });
  return response;
};

/**
 * 建立機構分享卷
 * @param {*} organizationId
 * @param {*} params
 * @returns
 */
export const createOrgSharedPapers = async (organizationId, params) => {
  const response = await Api.post(`/organizations/${organizationId}/orgSharedPapers`, params );
  return response;
};

/**
 * 刪除機構分享卷
 * @param {*} organizationId
 * @param {*} params
 * @returns
 */
export const deleteOrgSharedPapers = async (organizationId, params) => {
  const response = await Api.delete(`/organizations/${organizationId}/orgSharedPapers`, params );
  return response;
};