import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import PropTypes from 'prop-types';

import { IconInput, Select } from 'components';
import { useClass } from 'store/class';
import { useResource } from 'store/resource';
import { UiContentSearch, UiSelectContainer, UiInputContainer, } from './ContentSearch.style';

export const ContentSearch = ({ placeholder, selectedOption, tabIndex, onChangeSelect, onChangeSearch, onClickSearch }) => {
  const [{ myClasses, classes }] = useClass();
  const [{ bookstoreTags, }, { getBookstoreTags, }] = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const { dataInfo: { educationName } } = myClasses;
  const { dataInfo: { educationName: orgEducationName } } = classes;
  const OTHER = 'other';

  /* enter 搜尋按鈕 */
  const changeSearchHandler = (inputValue) => {
    onChangeSearch(inputValue);
  };

  /* click 搜尋按鈕 */
  const clickSearchHandler = (inputValue) => {
    onClickSearch(inputValue);
  };

  /* 取得公開資源的搜尋選項 */
  const fetchTags = async () => {
    if(educationName === OTHER || orgEducationName === OTHER) return;
    setIsLoading(true);
    const tagCategory = ['SchoolYear', 'EducationalSystem', 'Grade', 'Subject'];
    await Promise.all(tagCategory.map(category => getBookstoreTags({ category })));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTags();  // 取得公開資源搜尋項
  }, []);

  /* 格式化搜尋選項需要的資料 */
  const formatTagOptions = useCallback((tags = []) => {
    return tags?.map(item => ({ name: item.label, value: item.tagId })).concat([{ name: '清除條件', value: '' }]) || [];
  },[]);

  /* 學年度選項 */
  const schoolYearOptions = useMemo(() => {
    return formatTagOptions(bookstoreTags.data['SchoolYear']);
  }, [bookstoreTags.data]);

  /* 學制選項 */
  const educationalSystemOptions = useMemo(() => {
    return formatTagOptions(bookstoreTags.data['EducationalSystem']);
  }, [bookstoreTags.data]);

  /* 年級選項 */
  const gradeOptions = useMemo(() => {
    return formatTagOptions(bookstoreTags.data['Grade']);
  }, [bookstoreTags.data]);

  /* 科目選項 */
  const subjectOptions = useMemo(() => {
    return formatTagOptions(bookstoreTags.data['Subject']);
  });

  return (
    <UiContentSearch>
      {
        tabIndex === 0 &&
          <IconInput 
            isFull={true}
            placeholder={placeholder} 
            onChange={changeSearchHandler} 
            onClick={clickSearchHandler} />
      }
      {
        tabIndex === 1 &&
          <>
            <UiSelectContainer>
              <Select
                label="選擇學年度"
                loading={isLoading}
                options={schoolYearOptions}
                submitHandler={onChangeSelect('schoolYear')}
                value={selectedOption.schoolYear}
                />
              <Select
                label="選擇學制"
                loading={isLoading}
                options={educationalSystemOptions}
                submitHandler={onChangeSelect('educationalSystem')} 
                value={selectedOption.educationalSystem}
                />
              <Select
                label="選擇年級"
                loading={isLoading}
                options={gradeOptions}
                submitHandler={onChangeSelect('grade')}
                value={selectedOption.grade}
                />
              <Select
                label="選擇科目"
                loading={isLoading}
                options={subjectOptions}
                submitHandler={onChangeSelect('subject')} 
                value={selectedOption.subject}
                />
            </UiSelectContainer>
            <UiInputContainer>
              <IconInput 
                isFull={true}
                placeholder={placeholder} 
                onChange={changeSearchHandler} 
                onClick={clickSearchHandler} />
            </UiInputContainer>
          </>
      }
    </UiContentSearch>
  );
};

export default ContentSearch;

ContentSearch.propTypes = {
  placeholder: PropTypes.string,
  selectedOption: PropTypes.object,
  tabIndex: PropTypes.number,
  onChangeSelect: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onClickSearch: PropTypes.func,
};
