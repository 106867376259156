import styled from 'styled-components';

export const UiCustomerForm = styled.div`
	display: flex;
	justify-content: center;
	padding: 0 20px;
	flex-wrap: wrap;

	& > div {
		height: max-content;
	}
`;

export const UiCustomerButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

export const UiFormBox = styled.div`
	> form {
		padding: 0 1.5rem;
	}
`;

export const UiFormText = styled.div`
	margin: 10px 0;
	padding: 0 1.5rem;
	font-size: 1.1rem;
`;