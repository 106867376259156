import { MenuItem } from '@material-ui/core';
import styled from 'styled-components';

export const UiMonitorUserMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  minWidth: '160px',
  padding: '8px',
  cursor: 'unset',
  '&:hover': {
    background: 'unset',
  },
}));