import styled from 'styled-components';

export const UiContentListChecked = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`;

export const UiListCheckedWrapper = styled.div`
`;

export const UiTitleContainer = styled.div`
  border-bottom: 1px solid #E4E7EC;;
  color: ${({ theme, ckbLen }) => ckbLen === 6 ? theme.palette.main.danger : '#45485C'};
  font-family: Noto Sans CJK TC;
  font-size: 0.93rem;
  margin-bottom: 12px;
  padding-bottom: 12px;
  text-align: left;
`;

export const UiListContainer = styled.div`
  text-align: left;
`;

export const UiChipBox = styled.div`
  margin-bottom: 10px;

  &.chip-enter {
    opacity: 0.01;
  };
  &.chip-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  };
  &.chip-exit {
    opacity: 1;
  };
  &.chip-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  };
`;

export const UiSnackbarContainer = styled.div`
  line-height: 48px;
  height: 48px;
  width: 100%;
  background: #454B5C;
  border-radius: 4px;
  color: #FFF;
  padding-left: 10px;

  &.snackbar-enter {
    opacity: 0.01;
  };
  &.snackbar-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  };
  &.snackbar-exit {
    opacity: 1;
  };
  &.snackbar-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  };
`;
