import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import {
  getCustomers as getCustomersApi,
  getAllCustomers as getAllCustomersApi,
  inviteOrganizationCustomers as inviteOrganizationCustomersApi,
  addUserToGroup as addUserToGroupApi,
  removeUserToGroup as removeUserToGroupApi,
  getCustomer as getCustomerApi,
  updateCustomer as updateCustomerApi,
  updateGroupAttendeeNumbers as updateGroupAttendeeNumbersApi
} from 'services/api/organization/customer';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { subtractionById } from 'utils/array';
import { ERROR_CODE } from 'errorCode/index';

const GET_CUSTOMER = 'GET_CUSTOMER';
const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
const GET_CUSTOMERS = 'GET_CUSTOMERS';
const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
const ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP';
const REMOVE_USER_TO_GROUP = 'REMOVE_USER_TO_GROUP';
const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS';
const UPDATE_CUSTOMERS_ATTENDEE_NUMBERS = 'UPDATE_CUSTOMERS_ATTENDEE_NUMBERS';


const initState = {
  customer: null,
  customers: {
    isLoaded: false,
    data: [],
    total: 0,
    seatsData: [],
    sendData: [],
  },
  subtractionCustomers: {
    data: [],
  }
};

const actions = {
  getCustomer: data => ({
    type: GET_CUSTOMER,
    payload: data,
  }),
  updateCustomer: params => ({
    type: UPDATE_CUSTOMER,
    payload: params,
  }),
  getCustomers: params => ({
    type: GET_CUSTOMERS,
    payload: {
      isLoaded: Boolean(params),
      ...params
    }
  }),
  getAllCustomers: params => ({
    type: GET_ALL_CUSTOMERS,
    payload: params,
  }),
  addUserToGroup: params => ({
    type: ADD_USER_TO_GROUP,
    payload: params,
  }),
  removeUserToGroup: params => ({
    type: REMOVE_USER_TO_GROUP,
    payload: params,
  }),
  clearCustomers: () => ({
    type: CLEAR_CUSTOMERS
  }),
  updataCustomersAttendeeNumbers: () => ({
    type: UPDATE_CUSTOMERS_ATTENDEE_NUMBERS,
  })
};

export const useCustomer = () => {
  const dispatch = useDispatch();
  const { organizationId, classId, customerId } = useParams();
  const isHome = Boolean(useRouteMatch({ path: '/home' }));
  const { customers, subtractionCustomers, customer } = useSelector(state => state.customer);
  const { setAlert } = useAlert();

  const getCustomer = async () => {
    const { data, isSuccess } = await getCustomerApi(organizationId, customerId)();
    if (!isSuccess) return;
    dispatch(actions.getCustomer(data));
    return data;
  };

  const updateCustomer = async params => {
    dispatch(actions.updateCustomer(params));
    const { isSuccess, error } = await updateCustomerApi(organizationId, customerId)(params);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      if(errorCode === 500002) {        
        setAlert(ERROR_CODE[errorCode], 'error', errorCode);
      } else {
        setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      }
    }
    return isSuccess;
  };


  const getCustomers = async params => {
    const { data, isSuccess } = await getCustomersApi(isHome)(organizationId, classId)(params);
    if (!isSuccess) return { userProfiles: [] };
    const { total, userProfiles } = data;
    const { seatsData, sendData } = getSeatsData(userProfiles);
    dispatch(actions.getCustomers({ data: userProfiles, total, seatsData, sendData }));
    return { userProfiles, total };
  };

  const getSeatsData = (userProfiles) => {
    if (!userProfiles) return;
    let seatsMaps = {};
    let maxNumber = 1;
    userProfiles.forEach((item, index) => {
      maxNumber = parseInt(item.attendeeNumber) > maxNumber ?
        item.attendeeNumber :
        maxNumber;
      seatsMaps[item.attendeeNumber] = item.id;
    });
    let seatsData = [];
    maxNumber = userProfiles.length > maxNumber ? userProfiles.length :
      maxNumber;
    for (let i = 0; i < maxNumber; i++) {
      const newItem = {
        num: parseInt(i + 1),
        isChoosed: seatsMaps[(i + 1)] ? true : false
      };
      seatsData = [...seatsData, newItem];
    }
    let sendData = {};
    userProfiles.forEach(item => {
      if (item.attendeeNumber) {
        sendData[item.id] = item.attendeeNumber;
      }

    });
    return { seatsData, sendData };
  };

  const getAllCustomers = async (params) => {
    const { data, isSuccess } = await getAllCustomersApi(isHome)(organizationId, classId)({ rowsPage: 100, ...params });

    if (!isSuccess) return { userProfiles: [] };

    const { userProfiles } = data;

    return { userProfiles };
  };


  const getSubtractionCustomers = async params => {
    const { classId, organizationId } = params;
    const { userProfiles } = await getCustomers({ classId, organizationId });
    const { userProfiles: alluserProfiles } = await getAllCustomers();
    const subtractionArray = subtractionById(alluserProfiles, userProfiles);
    dispatch(actions.getAllCustomers({ data: subtractionArray }));
    return subtractionArray;
  };

  const addUserToGroup = async (params) => {
    try {
      const { userIdList, userProfiles } = params;
      const { isSuccess, error } = await addUserToGroupApi(isHome)(organizationId, classId)({ userIdList });

      if (!isSuccess) throw error;

      dispatch(actions.addUserToGroup(userProfiles));
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      await getSubtractionCustomers({ organizationId, classId });
      return true;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return false;
    }
  };

  const removeUserToGroup = async params => {
    const { userProfiles } = params;
    const userId = userProfiles.id;
    const { isSuccess, error } = await removeUserToGroupApi(isHome)(organizationId, classId)({ userId });
    try {
      if (!isSuccess) throw error;
      dispatch(actions.removeUserToGroup(userProfiles));
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
      await getSubtractionCustomers({ organizationId, classId });
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
    return isSuccess;
  };

  const inviteCustomers = async params => {
    const { isSuccess, error } = await inviteOrganizationCustomersApi(organizationId)(params);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.INVITE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode, invitation } = error;
      if(errorCode === 20019) {
        setAlert(`已被邀請成為${invitation.groupName}的教師`, 'error', errorCode);
      } else {
        setAlert(ERROR_CODE[errorCode] || '', 'error', errorCode);
      }
    }
    return isSuccess;
  };

  const updateCustomersAttendeeNumbers = async params => {
    const { isSuccess, error } = await updateGroupAttendeeNumbersApi(classId)(params);

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');

    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    return isSuccess;

  };

  const clearCustomers = () => dispatch(actions.clearCustomers());

  useEffect(() => {
    clearCustomers();
  }, [classId, organizationId, isHome]);

  return [
    { customers, subtractionCustomers, customer }, // state
    {
      getCustomer,
      updateCustomer,
      getCustomers,
      getAllCustomers,
      getSubtractionCustomers,
      inviteCustomers,
      addUserToGroup,
      removeUserToGroup,
      updateCustomersAttendeeNumbers,
    } // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CUSTOMER: {
      return {
        ...state,
        customer: action.payload
      };
    }
    case GET_CUSTOMERS: {
      return {
        ...state,
        customers: { ...action.payload },
      };
    }
    case GET_ALL_CUSTOMERS: {
      return {
        ...state,
        subtractionCustomers: { ...action.payload },
      };
    }
    case CLEAR_CUSTOMERS: {
      return {
        ...state,
        customers: initState.customers
      };
    }
    default:
      return state;
  }
};

export default reducer;
