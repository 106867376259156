import React, { useEffect, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { add, format, getTime } from 'date-fns';

import { useHistory } from 'react-router-dom';

import { EDIT_STATUS, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { Table, IconInput, Select, Button, DatePicker, Icon, Modal, BsModal, UploadButtonExcel } from 'components';
import { useClass } from 'store/class';
import { useOrganization } from 'store/organization';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';

import { UiHiddenInput, UiInviteClassCode, UiInviteClassCodeDate, UiSvg } from './ClassTable.style';
import { UiTable, UiActionBox, UiflexBox, UiTableButtonBox, UiEmphasize } from 'styles';

import { getCheckOneClubId as getCheckOneClubIdApi } from 'services/api/oneClub';
import {
  getTeachers as getTeachersApi,
} from 'services/api/organization/teacher';
import {
  getCustomer as getCustomerApi,
  addUserToGroup as addUserToGroupApi,
  updateGroupAttendeeNumbers as updateGroupAttendeeNumbersApi,
} from 'services/api/organization/customer';
import { copyGroup as copyGroupApi } from 'services/api/organization/class';
import { useUser } from 'store/user';
import { PromiseQueue } from 'utils/promiseQueue';

/**
 * 班級列表
 */
const schema = {
  name: {
    name: '班級名稱',
    defaultValue: ''
  },
  createDate: {
    name: '建班時間',
    defaultValue: ''
  },
  expirationTime: {
    name: '有效時間',
    defaultValue: ''
  },
  isExpired: {
    name: '班級狀態',
    defaultValue: ''
  },
  ownerName: {
    name: '導師',
    defaultValue: ''
  }
};

const FIELD = {
  className: {
    index: 0,
    errorMsg: '有不重複班級請確認',
  },
  ownerId: {
    index: 1,
    errorMsg: '有不重複導師請確認',
  },
  educationName: {
    index: 2,
    errorMsg: '有不重複學制請確認',
  },
  grades: {
    index: 3,
    errorMsg: '有不重複年級請確認',
  },
  year: {
    index: 4,
    errorMsg: '有不重複學年度請確認',
  },
  studentId: {
    index: 5,
    errorMsg: '有重複學生請確認',
  },
  attendeeNumber: {
    index: 6,
    errorMsg: '座號有問題請確認',
  }
};

const EDU_MAP = [
  { name: '國小', value: 'E' },
  { name: '國中', value: 'J' },
  { name: '高中', value: 'H' },
];

const selectInputOptions = [
  {
    name: '搜尋導師暱稱',
    value: 'ownerName'
  },
  {
    name: '搜尋導師手機號碼',
    value: 'ownerMobileNumber'
  }
];

const expiredOptions = [
  {
    name: '全部',
    value: ''
  },
  {
    name: '未到期',
    value: 'false'
  },
  {
    name: '已到期',
    value: 'true'
  }
];

const expiredShowText = [
  {
    id: 'isExpired',
    state: {
      false: '未到期',
      true: '已到期',
    }
  }
];

export const ClassTable = () => {
  const { setAlert } = useAlert();
  const history = useHistory();
  const { organizationId } = useParams();
  const [{ myOrganization: { organization }}] = useUser();
  const [{ orgs: { dataInfo }}, { getOrgInfo }] = useOrganization();
  const [{ classes }, { getClasses, removeClassInfo, getClassInviteCode, createClassInfo, updateClassList }] = useClass();
  const { data: classData, total: classTotalPage, dataMap: classDataMap } = classes;
  const [
    { goal,
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
      deleteModalState,
      actionClassId,
      isLoading,
      isOpen,
      inviteClassCode, // 代碼
      inviteClassDate, // 代碼到期時間
      isInvitationCodeExpired, // 代碼是否過期
      cancelDisplay,
    }, setState] = useSetState({
      goal: '',
      nowPage: 0,
      rowsPage: 10,
      name: '',
      ownerName: '',
      ownerMobileNumber: '',
      createdAfter: null,
      status: '',
      expired: '',
      deleteModalState: false,
      actionClassId: null,
      isLoading: false,
      isOpen: false,
      inviteClassCode: null,
      inviteClassDate: null,
      isInvitationCodeExpired: null,
      cancelDisplay: true,
    });

  const hasCopyGroupPermission = organization.groupCopySetting === ORGANIZATION_SETTINGS_VALUE.ENABLED;
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const dateHandler = value => {
    setState({
      createdAfter: value,
      nowPage: 0
    });
  };

  /* 驗證 id 格式 */
  const validateIdFormat = (body, setViewData) => {
    let isCorrect = true;
    let result = [];
    const rules = /^[\w]+$/; // 英文 or 數字 or 英數字
    body.every((row) => {
      const ownerId = row[1];
      const studentId = row[5];
      isCorrect = rules.test(ownerId);
      isCorrect = rules.test(studentId);
      if (isCorrect) {
        const data = ['檢查中...', ...row];
        result.push(data);
      } else {
        const data = ['帳號格式錯誤', ...row];
        result.push(data);
      }
      return isCorrect;
    });
    setViewData(result);
    return !isCorrect;
  };
  /* 驗證欄位是否重複 */
  const validateRepeat = (body, setViewData, filedName) => {
    let isError = false;
    const viewData = body.map((row, curIndex , arr) => {
      const curValue = row[FIELD[filedName].index];
      const isNotRepeat = arr.some((row, index) => (curIndex !== index) && row[FIELD[filedName].index] !== curValue);
      !isError && (isError = isNotRepeat);
      return [isNotRepeat ? FIELD[filedName].errorMsg : '檢查中...' , ...row];
    });
    setViewData(viewData);
    return isError;
  };
  /* 驗證 studentId 是否重複 */
  const validateStudentIdRepeat = (body, setViewData) => {
    let isError = false;
    const viewData = body.map((row, curIndex , arr) => {
      const studentId = row[FIELD.studentId.index];
      const isRepeat = arr.some((row, index) => (curIndex !== index) && row[FIELD.studentId.index] === studentId);
      !isError && (isError = isRepeat);
      return [isRepeat ? FIELD.studentId.errorMsg : '檢查中...' , ...row];
    });
    setViewData(viewData);
    return isError;
  };
  /* 驗證  attendeeNumber 順序 */
  const validateAttendeeNumber = (body, setViewData) => {
    let isCorrect = true;
    let result = [];
    body.every((row, index) => {
      const attendeeNumber = row[6];
      isCorrect = index + 1 === attendeeNumber;
      if (isCorrect) {
        const data = ['檢查中...', ...row];
        result.push(data);
      } else {
        const data = ['座號順序錯誤', ...row];
        result.push(data);
      }
      return isCorrect;
    });
    setViewData(result);
    return !isCorrect;
  };
  /* 驗證 id 是否存在於 oneClub */
  const validateIdExistsOneClub = async (body, setViewData) => {
    let isError = false;
    const tasks = body.map(row => {
      const studentId = row[5];
      return getCheckOneClubIdApi(studentId);
    });
    const teacherId = body[0][1];
    tasks.push(getCheckOneClubIdApi(teacherId));
    const responses = await Promise.all(tasks);
    const result = body.map((row, index) => {
      const isUserExist = responses[index].status === 'success' && responses[index]?.data?.status === 'failure' && responses[index]?.data?.error?.errorCode === 20115;
      const message = isUserExist ? '檢查中...' : '帳號不存在OneClub或其他錯誤';
      return [message, ...row];
    });
    if (result.some(item => item[0] !== '檢查中...')) isError = true;
    setViewData(result);
    return isError;
  };
  /* 驗證 ownerId 是否存在於機構中 */
  const validateOwnerIdExistsOrganization = async (body, setViewData) => {
    let isError = false;
    const tasks = body.map(row => {
      const id = row[1];
      return getTeachersApi()(organizationId)({ targetUserId: id });
    });
    const responses = await Promise.all(tasks);
    const result = body.map((row, index) => {
      const messages = [];
      const isUserNotExist = responses[index].status === 'failure';
      isUserNotExist && messages.push('教師帳號不存在機構中');
      const message = messages.length > 0 ? messages.join(',') : '檢查中...';
      return [message, ...row];
    });
    if (result.some(item => item[0] !== '檢查中...')) isError = true;
    setViewData(result);
    return isError;
  };
  /* 驗證 studentId 是否存在於機構中 */
  const validateStudentIdExistsOrganization = async (body, setViewData) => {
    let isError = false;
    const tasks = body.map(row => {
      const id = row[5];
      return getCustomerApi(organizationId, id)();
    });
    const responses = await Promise.all(tasks);
    const result = body.map((row, index) => {
      const messages = [];
      const isUserNotExist = responses[index].status === 'failure';
      isUserNotExist && messages.push('學生帳號不存在機構中');
      const message = messages.length > 0 ? messages.join(',') : '驗證成功';
      return [message, ...row];
    });
    if (result.some(item => item[0] !== '驗證成功')) isError = true;
    setViewData(result);
    return isError;
  };
  /* 驗證 excel 格式 */
  const validateExcel = async (body, setViewData) => {
    let isError = false;
    isError = validateIdFormat(body, setViewData);
    if (isError) return isError;
    isError = validateRepeat(body, setViewData, 'className');
    if (isError) return isError;
    isError = validateRepeat(body, setViewData, 'ownerId');
    if (isError) return isError;
    isError = validateRepeat(body, setViewData, 'educationName');
    if (isError) return isError;
    isError = validateRepeat(body, setViewData, 'grades');
    if (isError) return isError;
    isError = validateRepeat(body, setViewData, 'year');
    if (isError) return isError;
    isError = validateStudentIdRepeat(body, setViewData);
    if (isError) return isError;
    isError = validateAttendeeNumber(body, setViewData);
    if (isError) return isError;
    isError = await validateIdExistsOneClub(body, setViewData);
    if (isError) return isError;
    isError = await validateOwnerIdExistsOrganization(body, setViewData);
    if (isError) return isError;
    isError = await validateStudentIdExistsOrganization(body, setViewData);
    return isError;
  };
  // table 操作
  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params: { id, invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired } }) => {
    const goPage = editStatus => () => history.push(`/organization/${organizationId}/class/${id}/${editStatus}`);

    /* 複製班級 */
    const copyGroup = async () => {
      const { status, data } = await copyGroupApi(organizationId, id);
      if (status === 'success') {
        updateClassList(data);
        setAlert('複製班級成功', 'success');
      } else {
        throw new Error('複製班級失敗');
      }
    };
    const clickCopy = async () => {
      try {
        await copyGroup();
      } catch (error) {
        setAlert(error.message, 'error');
      }
    };

    return (
      <UiTableButtonBox>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/session`)}
        >
          課程管理
        </Button>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/customer`)}
        >
          成員管理
        </Button>
        <Button
          onClick={() => {
            modalToggle();
            setInvitationCode({ invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired, id });
          }}
        >
          邀請代碼
        </Button>
        <Icon name="edit" title="編輯班級" onClick={goPage(EDIT_STATUS.EDIT)} />
        {hasCopyGroupPermission && <Icon name="fileCopy" title="複製班級" onClick={clickCopy} />}
        <Icon
          name="delete"
          title="刪除班級"
          type='danger'
          onClick={() => setState({ deleteModalState: true, actionClassId: id })}
        />
      </UiTableButtonBox>
    );
  };
  /* 打 API，設定座號 */
  const updateAttendeeNumber = async (classInfo, viewData, errors) => {
    const classId = classInfo.id;
    const payload = viewData.reduce((prev, curr) => {
      const importedData = [...curr];
      importedData.shift();
      const studentId = importedData[FIELD.studentId.index];
      const attendeeNumber = importedData[FIELD.attendeeNumber.index].toString();
      return {
        ...prev,
        numbers: {
          ...prev.numbers,
          [studentId]: attendeeNumber,
        }
      };
    }, {});
    if (!errors.length) await updateGroupAttendeeNumbersApi(classId)(payload);
  };
  /* 打 API，建立班級 */
  const createClass = async (tableData) => {
    const educationName = EDU_MAP.find(({ name }) => name === tableData[0].educationname).value;
    const expirationTime = getTime(add(Date.now(), { years: 1, days: 1 }));
    const params = {
      educationName,
      expirationTime,
      description: '',
      grades: [String(tableData?.[0]?.grades)],
      name: String(tableData?.[0]?.name),
      ownerId: String(tableData?.[0]?.ownerid),
      year: String(tableData?.[0]?.year),
    };
    const response = await createClassInfo(params);
    if (!response.isSuccess) throw new Error('建立班級失敗');
    return response;
  };
  /* 學生加入班級完成後動作 */
  const addUserToClassAfterComplete = (classInfo, tableData, setViewData, setIsLoading, setIsCompleteImport) => async (isStopped, complete, errors, total) => {
    const viewData = [];
    for (let i = 0; i < total; i++) {
      const data = tableData[i];
      const row = [data.name, data.ownerid, data.educationname, data.grades, data.year, data.studentid, data.attendeenumber];
      if (errors.length && complete.length === i) {
        // 有錯誤且已將完成的資料放進 viewData
        row.unshift('匯入失敗');
        viewData.push(row);
        break;
      } else if (errors.length) {
        // 第一筆資料即有錯誤
        row.unshift('匯入失敗');
        viewData.push(row);
        break;
      }
      else if (complete.length && complete.length > i) {
        // 無錯誤，且每次只能 set 一筆進 table
        row.unshift('匯入成功');
        viewData.push(row);
      }
    }
    await updateAttendeeNumber(classInfo, viewData, errors);
    setViewData(viewData);
    if ((complete.length + errors.length === total) || isStopped) {
      setIsCompleteImport(true);
      setIsLoading(false);
    }
  };
  /* 打 API，學生加入班級 */
  const addUserToClass = (classInfo, tableData, setViewData, setIsLoading, setIsCompleteImport) => {
    const classId = classInfo.id;
    const tasks = tableData.map(({ studentid }) => {
      const payload = { userIdList: [studentid] };
      return async () => addUserToGroupApi(isHome)(organizationId, classId)(payload);
    });
    const promiseQueue = new PromiseQueue(tasks, 1);
    promiseQueue.run(addUserToClassAfterComplete(classInfo, tableData, setViewData, setIsLoading, setIsCompleteImport), true);
  };
  /* 匯入班級彈窗 - 匯入 func */
  const joinClassIntoOrganization = async (tableData, setViewData, setIsLoading, setIsCompleteImport) => {
    try {
      setIsLoading(true);
      const { data: classInfo } = await createClass(tableData);
      addUserToClass(classInfo, tableData, setViewData, setIsLoading, setIsCompleteImport);
    } catch (error) {
      setIsLoading(false);
      setAlert(error, 'error');
    }
  };
  /* 匯入班級彈窗 - 完成匯入後 func */
  const handleAfterImport = () => {
    fetchGetClasses();
  };


  const setInvitationCode = ({ invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired, id }) => {
    setState({
      inviteClassCode: invitationCode,
      inviteClassDate: invitationCodeExpiredAt && format(new Date(invitationCodeExpiredAt), 'MM月dd日 HH:mm'),
      isInvitationCodeExpired,
      actionClassId: id || actionClassId,
    });
  };

  const fetchGetClasses = async () => {
    await getClasses({
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'wranning');
        return;
      } else {
        setState({
          ownerName: '',
          ownerMobileNumber: '',
          [goal]: value,
          nowPage: 0
        });
      }
    }
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const setDeleteModalStateHandler = state => {
    setState({ deleteModalState: state });
  };

  const buttons = [
    {
      text: '確認',
      func: async () => {
        await removeClassInfo(actionClassId);
        await fetchGetClasses();
        setDeleteModalStateHandler(false);
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  // 複製代碼
  const codeRef = useRef();
  const onCopyCode = () => {
    const copyText = codeRef.current;
    copyText.select(); // 選擇物件
    document.execCommand('Copy'); // 執行瀏覽器複製命令
    setAlert('複製成功!', 'success');  // 跳成功訊息
  };

  const getInviteClassCodeHandler = async () => {
    setState({ isLoading: true });
    const data = await getClassInviteCode(actionClassId);
    const { code: invitationCode, expiredAt: invitationCodeExpiredAt } = data;
    setInvitationCode({ invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired: false });
    await fetchGetClasses();
    setState({ isLoading: false, isInvitationCodeExpired: false });
  };

  const onModalOk = () => {
    if (inviteClassCode) {
      // 判斷過期
      if (isInvitationCodeExpired) {
        // 生成代碼
        getInviteClassCodeHandler();
      } else {
        //  關閉視窗
        modalToggle();
      }
    } else {
      // 生成代碼
      getInviteClassCodeHandler();
    }
  };

  const modalToggle = () => {
    setState({
      isOpen: !isOpen,
    });
  };

  const onOkTextHandle = () => {
    let text = null;
    if (inviteClassCode) {
      // 判斷過期
      if (isInvitationCodeExpired) {
        text = '產生';

      } else {
        text = '關閉';
      }
    } else {
      text = '產生';
    }
    return text;
  };

  useEffect(() => {
    getOrgInfo();
  }, []);

  useEffect(() => {
    if (inviteClassCode) {
      setState({
        cancelDisplay: false,
      });
    } else {
      setState({
        cancelDisplay: true,
      });
    }
  }, [inviteClassCode]);

  useEffect(() => {
    fetchGetClasses();
  }, [nowPage, rowsPage, name, ownerName, ownerMobileNumber, createdAfter, status, expired]);

  // eslint-disable-next-line react/prop-types
  const ModalText = ({ name }) => (
    <div>
      您確定要將 <UiEmphasize>{name}</UiEmphasize> 刪除嗎?這樣班級中的課程將會消失、班級成員也都無法再回到這個班級喔!!
    </div>
  );

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <IconInput
            placeholder='搜尋班級名稱'
            onChange={value => onSubmitHandler(value, 'name')}
          />
          <Select
            label="班級狀態"
            options={expiredOptions}
            submitHandler={value => onSubmitHandler(value, 'expired')}
          />
          <DatePicker
            label="搜尋建班時間"
            value={createdAfter}
            onChange={dateHandler}
          />
          <Select
            label="搜尋目標"
            options={selectInputOptions}
            submitHandler={value => onSubmitHandler(value, 'goal')}
          />
          {
            goal && <IconInput
              placeholder='搜尋條件'
              onChange={value => onSubmitHandler(value, 'condition')}
            />
          }
        </UiflexBox>
        <UiflexBox>
          {
            dataInfo?.organizationImportGroupsSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW &&
              <UploadButtonExcel
                validateExcel={validateExcel}
                handleJoin={joinClassIntoOrganization}
                handleAfterImport={handleAfterImport} >
                匯入班級
              </UploadButtonExcel>
          }
          <Button
            buttonColor='highlight'
            icon='add'
            onClick={() => history.push(`/organization/${organizationId}/class/create`)}>新增班級</Button>
        </UiflexBox>
      </UiActionBox>
      <Table
        data={
          classData.map(item => {
            item.createDate = format(new Date(item.createdAt), 'yyyy-MM-dd');
            item.expirationTime = format(new Date(item.expirationTime), 'yyyy-MM-dd');
            return item;
          })
        }
        schema={schema}
        stateShowText={expiredShowText}
        changePage_Rows={changePage_Rows}
        totalPage={classTotalPage}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />

      <Modal
        isOpen={deleteModalState}
        content={<ModalText name={classDataMap[actionClassId] && classDataMap[actionClassId].name} />}
        getModalState={state => setDeleteModalStateHandler(state)}
        buttons={buttons}
      />

      <BsModal
        open={isOpen}
        title="邀請代碼"
        onOk={onModalOk}
        onCancel={modalToggle}
        okText={onOkTextHandle()}
        cancelDisplay={cancelDisplay}
        isLoading={isLoading}
      >
        {
          inviteClassCode ?
            isInvitationCodeExpired ? <div>邀請代碼已過期，是否要產生邀請代碼?</div>
              : <>
                <UiInviteClassCode>
                  <div>透過邀請代碼可加入班級：</div>
                  <div>{inviteClassCode}</div>
                  <UiSvg onClick={onCopyCode}>
                    <Icon.Svg name="Copy" />
                  </UiSvg>
                </UiInviteClassCode>
                <UiInviteClassCodeDate>班級代碼過期日期：{inviteClassDate}</UiInviteClassCodeDate>
                <UiHiddenInput>
                  <input type="text" ref={codeRef} value={inviteClassCode} />
                </UiHiddenInput>
              </>
            : <div>目前沒有邀請代碼，是否要產生邀請代碼?</div>
        }
      </BsModal>
    </UiTable>);
};
