import React, { useEffect, useState, useMemo, useRef, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { TextField, Box, } from '@material-ui/core';

import { EDIT_STATUS, EDUCATION_LEVEL, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
// import { Button, Select, FormCheckBoxGroup, Checkbox,UploadImageBox, CitySelector, BsModal, } from 'components';
import { useOrganization } from 'store/organization';
import { isExist,validURL } from 'utils/helper';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { isDeepEqual } from 'utils/deepEqual';
import { useAlert } from 'utils/hooks/useAlert';
import getStatic from 'utils/getStatic';
import {
  Button,
  FormCheckBoxGroup,
  Checkbox,
  UploadImageBox,
  CitySelector,
  RadioGroup,
  BsModal,
  Icon,
} from 'components';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import {
  UiOrganizationForm,
  UiButtonWrapper,
  UiButtonBox,
  UiBoxWrapper,
  UiAdvancedSettingBox,
  UiAdvancedSettingTitle,
  UiAdvancedSettingLogoBox,
  UiAdvancedSettingLogo,
  UiCropperModalContentBox,
} from './BsOrganizationForm.style';

/**
 * 『機構管理』表單
 */
const statusOptions = [
  {
    label: '開通',
    value: 'active',
    isDisabled: true,
  },
  {
    label: '未開通',
    value: 'inactive',
    isDisabled: true,
  },
  {
    label: '停權',
    value: 'suspended',
    isDisabled: true,
  }
];


const educationNamesList = [{
  label: '國小',
  value: EDUCATION_LEVEL.ELEMENTARY,
  disabled: true,
}, {
  label: '國中',
  value: EDUCATION_LEVEL.JUNIOR,
  disabled: true,
}, {
  label: '高中',
  value: EDUCATION_LEVEL.SENIOR,
  disabled: true,
}, {
  label: '其他',
  value: EDUCATION_LEVEL.OTHER,
  disabled: true,
}];

export const BsOrganizationForm = ({ editStatus }) => {
  const prevFormValue = useRef({});
  const currentFormValue = useRef({});
  const [formData, setFormData] = useSetState({
    officialName: '',
    GUINumber: '',
    cityName: '',
    districtName: '',
    address: '',
    officialSiteUrl:'',
    thumbnailUrl:'',
    ownerMobileNumber: '',
    ownerName: '',
    comment: '',
    ownerId: '',
    educationNames: [],
    status: '',
    groupCreationSetting: ORGANIZATION_SETTINGS_VALUE.PRIVATE,
    publicFields: [],
    groupOwnerInviteStaffSetting: ORGANIZATION_SETTINGS_VALUE.DISALLOW,
    groupGradeSelectionSetting: ORGANIZATION_SETTINGS_VALUE.SINGLE,
    groupOwnerCreateSessionSetting: ORGANIZATION_SETTINGS_VALUE.DISALLOW,
    sessionAttendanceManagementSetting:ORGANIZATION_SETTINGS_VALUE.NONE,
    accessToPublicResourceSetting:'',
    adminMonitorSessionSetting:'',
    staffProfileOriginSetting:ORGANIZATION_SETTINGS_VALUE.DEFAULT,
    customerProfileOriginSetting:ORGANIZATION_SETTINGS_VALUE.DEFAULT,
    organizationCustomLogoUsageSetting: ORGANIZATION_SETTINGS_VALUE.DISALLOW,
    organizationLogoOriginSetting: ORGANIZATION_SETTINGS_VALUE.DEFAULT,
    customOrganizationLogoUrl: '',
  });
  const { upload } = useFirebaseStorage();
  const [{
    isLoading,
    isValidating,
    imageFile,
    isBlockingModalOpen,
    isModalCancelClick,
    isSubmitted,
    nextLocation,
    isModalOpen,
    isCropperModalOpen,
    cropperModalStep,
    cropperModalOkText,
    cropperModalOkDisplay,
    cropperModalUploadedImageFile,
    cropperModalUploadedImageSrc,
    cropperModalCroppedImageBlob,
  }, setState] = useSetState({
    isLoading: editStatus === EDIT_STATUS.CREATE ? false : true,
    isValidating: editStatus === EDIT_STATUS.EDIT,
    imageFile:null,
    isBlockingModalOpen: false,
    isModalCancelClick: false,
    isSubmitted: false,
    nextLocation: {},
    isModalOpen: false,
    isCropperModalOpen: false,
    cropperModalStep: 'expecting',
    cropperModalOkText: '下一步',
    cropperModalOkDisplay: true,
    cropperModalUploadedImageFile: '',
    cropperModalUploadedImageSrc: '',
    cropperModalCroppedImageBlob: '',
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { organizationId } = useParams();
  const [
    { orgs },
    { getOrgInfo, updateOrganizationInfo }
  ] = useOrganization();
  const { dataInfo: orgInfo } = orgs;

  /* 表單有無改變 */
  const isFormChange = (isBeforeunloadEvent, location) => {
    const prev = prevFormValue.current;
    const current = currentFormValue.current;

    // console.log('prev', prev);
    // console.log('current', current);

    // 表單上一個資料及目前資料比較
    if(isDeepEqual(prev, current))
      // 表單無改變
      return false;
    else{
      // 表單有改變
      !isBeforeunloadEvent &&
        setState({
          isBlockingModalOpen: true,
          nextLocation: location,
        });
      return true;
    }
  };

  /* Modal 放棄變更 click */
  const onCancelHandler = () => {
    setState({
      isBlockingModalOpen: false,
      isModalCancelClick: true,
     });
  };

  /* Modal 儲存變更 click */
  const onOkHandler = () => {
    setState({ isBlockingModalOpen: false });
    submitHandler();
  };

   /* 重新整理事件 */
  const onBeforeunloadHandler = (e) => {
    const isBeforeunloadEvent = true;
    const location = {};
    if(isFormChange(isBeforeunloadEvent, location)) e.returnValue = '確定離開此頁嗎？';
  };

  useEffect(() => {
    if (editStatus === EDIT_STATUS.CREATE) return;
    // 取得該組織資料
    getOrgInfo();
    setState({ isLoading: true });
  }, []);

  useEffect(() => {
    // edit & read
    if (organizationId) {
      // API key 動態生成，因沒有上傳自訂LOGO會沒有key，所以這邊先增加
      setFormData({ customOrganizationLogoUrl: '' });
      setFormData({ ...orgInfo });
      prevFormValue.current = ({ ...formData, ...orgInfo, });
    }
    setState({ isLoading: false });
  }, [orgInfo]);

  /* 加入重新整理事件 */
  useEffect(() => {
    if(isLoading) return;

    currentFormValue.current = formData;

    window.addEventListener('beforeunload', onBeforeunloadHandler);
    return() => {
      window.removeEventListener('beforeunload', onBeforeunloadHandler);
    };
  }, [formData]);

  /* Modal 放棄變更後，網頁跳轉 */
  useEffect(() => {
    if(isModalCancelClick){
      setState({ isModalCancelClick: false,});
      history.push(nextLocation.pathname);
    }
  }, [isModalCancelClick]);

  /* 處理網頁做任何導向前的彈出視窗 */
  useEffect(() => {
    if(isLoading) return;
    if(isSubmitted) return;

    const isBeforeunloadEvent = false;

    // 點選sidebar彈出視窗
    const unblock = history.block((location) => {
      if(isModalCancelClick) return true;
      if(isFormChange(isBeforeunloadEvent, location))return false;
      else return true;
    });

    return () => {
      unblock();
    };
  },[formData, isModalCancelClick, isSubmitted]);

  // 驗證
  const formValidationRules = {
    officialName: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    GUINumber: [
      {
        message: '格式不正確',
        validate: taxId => {
          if (!taxId) return true;
          const invalidList = '00000000,11111111';
          if (/^\d{8}$/.test(taxId) === false || invalidList.indexOf(taxId) !== -1) {
            return false;
          }

          const validateOperator = [1, 2, 1, 2, 1, 2, 4, 1];
          let sum = 0;
          let calculate = function (product) { // 個位數ㄏ  + 十位數
            let ones = product % 10,
              tens = (product - ones) / 10;
            return ones + tens;
          };
          for (let i = 0; i < validateOperator.length; i++) {
            sum += calculate(taxId[i] * validateOperator[i]);
          }
          return (sum % 10 === 0 || (taxId[6] === '7' && (sum + 1) % 10 === 0));
        }
      }
    ],
    cityName: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    address: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    officialSiteUrl:[
      {
        message:'本欄位只能填入網址',
        validate: value => validURL(value)
      }
    ],
    ownerName: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    ownerId: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    status: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ]
  };

  const validateForm = () => {
    let errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (!formValidationRules[key]) return;
      formValidationRules[key].forEach(rule => {
        if (!rule.validate(value)) {
          errors[key] = {
            message: rule.message
          };
        } else {
          delete errors[key];
        }
      });
    });
    return { errors };
  };

  useEffect(() => {
    if (!isValidating) return;
    const { errors } = validateForm();
    setErrors(errors);
  }, [isValidating, formData]);

  useEffect(() => {
    if(!isSubmitted) return;
    history.push(`/organization/${organizationId}`);
  }, [isSubmitted]);

  // 表單送出
  const submitHandler = async () => {
    setState({ isLoading: true, isValidating: true });

    // 大頭貼
    if(imageFile){
      const fileName = imageFile.name.split('.')[0];
      const fileType = imageFile.type.split('/')[1];
      const uploadPath = `${organizationId}/organizationPicture/${fileName}.${fileType}`;
      const { url } = await upload(uploadPath, imageFile);
      formData.thumbnailUrl = url;
    }

    // 自訂LOGO
    if(cropperModalCroppedImageBlob){
      const fileName = cropperModalUploadedImageFile.name.split('.')[0];
      const fileType = cropperModalUploadedImageFile.type.split('/')[1];
      const uploadPath = `${organizationId}/organizationPicture/${fileName}.${fileType}`;
      const { url } = await upload(uploadPath, cropperModalCroppedImageBlob);
      formData.customOrganizationLogoUrl = url;
    }

    const { errors } = validateForm();
    if (Object.keys(errors).length > 0) {
      setState({ isLoading: false });
      setErrors(errors);
      return;
    }

    setState({ isLoading: true });
    const success = await updateOrganizationInfo(organizationId, formData);
    setState({ isLoading: false });
    if (success) {
      setState({ isSubmitted: true });
      window.removeEventListener('beforeunload', onBeforeunloadHandler);
    }
  };

  const updateFormDataHandler = (property, value) => {
    setFormData({ [property]: value });
  };

  const uploadHandle = (src,file) => {
    setState({
      imageFile:file
    });
    setFormData({
      thumbnailUrl:src
    });
  };

  const removeHandle = () => {
    setState({
      imageFile:null
    });
    setFormData({
      thumbnailUrl:''
    });
  };

  const citySelectorChangeHandler = city => {
    const { county: cityName, township: districtName } = city;
    updateFormDataHandler('cityName',cityName);
    updateFormDataHandler('districtName',districtName);
  };

  const citySelectorValue = useMemo(() => ({
    addressCountyName: formData.cityName,
    addressDistrictName: formData.districtName
  }),[formData.cityName,formData.districtName]);

  /* 自訂首頁Logo Checkbox onChange */
  const onChangeCustomLogoCheckboxHandler = ( value ) => {
    // 勾->custom, 未勾->default
    const isChecked =
      value === true
        ? ORGANIZATION_SETTINGS_VALUE.CUSTOM
        : ORGANIZATION_SETTINGS_VALUE.DEFAULT;
    // 更新formData狀態
    updateFormDataHandler('organizationLogoOriginSetting', isChecked);
    // 勾->開啟modal
    if (
      isChecked === ORGANIZATION_SETTINGS_VALUE.CUSTOM &&
      formData.customOrganizationLogoUrl === ''
    ) {
      openModal();
    }
  };

  /* 開啟未上傳LOGO提醒Modal */
  const openModal = () => {
    setState({isModalOpen: true});
  };

  /* 未上傳LOGO提醒Modal之確認、取消事件 */
  const logoNotUploadedHandler = () => {
    // "使用首頁LOGO"取消勾選
    setFormData({ organizationLogoOriginSetting: ORGANIZATION_SETTINGS_VALUE.DEFAULT});
    // 設定Modal狀態為關閉
    setState({ isModalOpen: false });
  };

  /* 上傳並裁切LOGO之Modal */
  const CropperModal = () => {
    let croppedUrl = null;
    let croppedBlob = null;
    const cropperRef = useRef(null);
    const { setAlert } = useAlert();

    // 裁切事件
    const onCrop = () => {
      const imageElement = cropperRef.current;
      const cropper = imageElement.cropper;

      if (cropper !== undefined ) {
        croppedUrl = cropper.getCroppedCanvas({ width: 240, height: 48 }).toDataURL();
        croppedBlob = cropper.getCroppedCanvas({ width: 240, height: 48 }).toBlob(( blob )=>{
          croppedBlob = blob;
        }, 'image/jpeg');
      }
    };

    // Modal 取消、跳出按鈕
    const onCancelHandler = () => {
      setState({
        isCropperModalOpen: false,
        cropperModalStep: 'expecting',
        cropperModalOkText: '下一步',
      });
    };

    // Modal 下一步、設為首頁圖片按鈕
    const onOkHandler = () => {
      switch (cropperModalStep) {
        case 'expecting':
          setState({
            cropperModalStep: 'uploading',
          });
          break;
        case 'cropping':
          setState({
            cropperModalCroppedImageBlob: croppedBlob,
          });
          setFormData({
            organizationLogoOriginSetting: ORGANIZATION_SETTINGS_VALUE.CUSTOM,
            customOrganizationLogoUrl: croppedUrl,
          });
          setAlert('首頁LOGO圖片上傳成功', 'success');
          onCancelHandler();
          break;
        default:
          break;
      }
    };

    // 上傳LOGO 按鈕
    const onChangeLogoHandler = (e) => {
      // e.preventDefault();
      if (e.target.files.length < 1) return;

      let file = e.target.files[0];
      let objectUrl = window.URL.createObjectURL(file);

      setState({
        cropperModalUploadedImageFile: file,
        cropperModalUploadedImageSrc: objectUrl,
        cropperModalStep: 'cropping',
        cropperModalOkText: '設為首頁圖片',
      });
    };

    // Modal之render畫面
    const renderSwitch = () => {
      switch (cropperModalStep) {
        case 'expecting':
          return (
            <UiCropperModalContentBox border={false}>
              <img alt='' src={getStatic('modal_custom_logo_introduction')} />
            </UiCropperModalContentBox>
          );
        case 'uploading':
          return (
            <UiCropperModalContentBox  border={true}>
              <Icon.Svg name='Picture' className='uploading-icon' color='#D5D7DE'/>
              <div>
                檔案格式限定為：PNG、JPG、JPEG<br/>
                上傳圖檔尺寸：720*144<br/>
                建議使用白色或透明背景圖片，以達最佳顯示效果<br/>
              </div>
              <label className='button'>
                <Icon name="image" haveBg={false} />
                上傳首頁LOGO
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={e => (onChangeLogoHandler(e))}/>
              </label>
            </UiCropperModalContentBox>
          );
        case 'cropping':
          return (
            <UiCropperModalContentBox border={false}>
              <Cropper
                src={cropperModalUploadedImageSrc}
                style={{ height: '100%', width: '100%' }}
                viewMode={1}                // 限制裁切框不超過底圖
                background={false}          // 允許背景圖片馬賽克
                guides={false}              // 允許裁切框顯示輔助線
                cropBoxResizable={false}    // 允許裁切框可自由改變大小
                cropBoxMovable={true}       // 允許裁切框可以移動
                dragMode={'crop'}           // 限制裁切框為不可重新拉大小
                initialAspectRatio={5 / 1}  // 定義初始裁切框的比例
                aspectRatio={5 / 1}         // 定義裁切框的比例
                crop={onCrop}
                ref={cropperRef}
              />
            </UiCropperModalContentBox>
          );
        default:
          break;
      }
    };

    return (
      <BsModal
        open={isCropperModalOpen}
        title='自訂首頁LOGO'
        okText={cropperModalOkText}
        buttonsCenter={true}
        okDisplay={cropperModalOkDisplay}
        cancelDisplay={false}
        onOk={onOkHandler}
        onCancel={onCancelHandler}
      >
        {renderSwitch()}
      </BsModal>
    );
  };

  return (
    <UiOrganizationForm>
      <BsModal
        name={'prompt'}
        open={isBlockingModalOpen}
        title={'是否儲存設定變更？'}
        isFull={true}
        isLoading={isLoading}
        okText={'儲存變更'}
        cancelText={'放棄變更'}
        onOk={onOkHandler}
        onCancel={onCancelHandler}
      >
          儲存後新設定將覆蓋原有設定。確認儲存請按「儲存變更」。
      </BsModal>
      {/* <div className="left"> */}
      <div className="imageBox-container">
        <UploadImageBox
          label='變更大頭貼'
          upload={uploadHandle}
          remove={removeHandle}
          src={formData.thumbnailUrl}
        />
      </div>
      <div className="form-container">
      <UiButtonBox>
        <Button>新增付費</Button>
      </UiButtonBox>
      <Box py={2}>
        <RadioGroup
          name='status'
          label="組織狀態*"
          data={statusOptions}
          error={!!errors.status}
          helperText={errors.status && errors.status.message}
          onChange={e => updateFormDataHandler('status', e.target.value)}
          value={formData.status}
        />
      </Box>
      <UiBoxWrapper>
        <Box py={2} pr={'1%'} width={'100%'}>
          <TextField
            name='officialName'
            label="機構名稱*"
            value={formData.officialName}
            error={!!errors.officialName}
            helperText={errors.officialName && errors.officialName.message}
            onChange={e => updateFormDataHandler('officialName', e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Box>
        <Box py={2} pl={'1%'} width={'100%'}>
          <TextField
            name='GUINumber'
            label="統一編號"
            value={formData.GUINumber}
            error={!!errors.GUINumber}
            helperText={errors.GUINumber && errors.GUINumber.message}
            onChange={e => updateFormDataHandler('GUINumber', e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Box>
      </UiBoxWrapper>
      <UiBoxWrapper>
        <Box py={2} pr={'1%'} width={'100%'}>
          <TextField
            name='ownerName'
            label="負責人名稱*"
            value={formData.ownerName}
            error={!!errors.ownerName}
            helperText={errors.ownerName && errors.ownerName.message}
            onChange={e => updateFormDataHandler('ownerName', e.target.value)}
            variant={'outlined'}
            disabled
            fullWidth
          />
        </Box>
        <Box py={2} pl={'1%'} width={'100%'}>
          <TextField
            name='ownerMobileNumber'
            label="負責人手機號碼*"
            value={formData.ownerMobileNumber}
            onChange={e => updateFormDataHandler('ownerMobileNumber', e.target.value)}
            variant={'outlined'}
            disabled
            fullWidth
          />
        </Box>
      </UiBoxWrapper>
      <UiBoxWrapper>
        <Box py={2} width={'100%'}>
          <CitySelector
            variant="outlined"
            value={citySelectorValue}
            required
            onChange={citySelectorChangeHandler}
            isFullWidth={false} />
        </Box>
      </UiBoxWrapper>

      <Box py={2}>
        <TextField
          name='address'
          label="住址*"
          value={formData.address}
          error={!!errors.address}
          helperText={errors.address && errors.address.message}
          onChange={e => updateFormDataHandler('address', e.target.value)}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box py={2}>
        <TextField
          name='officialSiteUrl'
          label="官方網站*"
          value={formData.officialSiteUrl}
          error={!!errors.officialSiteUrl}
          helperText={errors.officialSiteUrl && errors.officialSiteUrl.message}
          onChange={e => updateFormDataHandler('officialSiteUrl', e.target.value)}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box py={2}>
        <TextField
          name='comment'
          label="備註"
          value={formData.comment}
          multiline
          rows={5}
          onChange={e => updateFormDataHandler('comment', e.target.value)}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box py={2}>
        <FormCheckBoxGroup
          name='educationNames'
          label="學制"
          value={formData.educationNames}
          data={educationNamesList}
          onChange={value => updateFormDataHandler('educationNames', value)}
          fullWidth
        />
      </Box>
      </div>

      <UiAdvancedSettingBox>
        <UiAdvancedSettingTitle>進階設定</UiAdvancedSettingTitle>
        {formData.organizationCustomLogoUsageSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW &&
          <Box py={1} display={'flex'} flexWrap={'wrap'}>
            <Checkbox
              name=''
              label='使用自訂首頁LOGO'
              helperText=''
              checkedValue={true}
              checked={formData.organizationLogoOriginSetting === ORGANIZATION_SETTINGS_VALUE.CUSTOM}
              onChange={onChangeCustomLogoCheckboxHandler}
            />
            <Button
              type='button'
              buttonColor='upload'
              icon="image"
              onClick={() => {
                setState({isCropperModalOpen: true});
              }}
            >上傳首頁LOGO</Button>
            {formData.customOrganizationLogoUrl &&
              <Box pt={2} px={3} width={'100%'}>
                <UiAdvancedSettingLogoBox>
                  <UiAdvancedSettingLogo
                    src={formData.customOrganizationLogoUrl}
                  />
                </UiAdvancedSettingLogoBox>
              </Box>
            }
        </Box>
        }

        <Box py={1}>
          <Checkbox
            name='groupCreationSetting'
            label="非機構教師可建班加入機構"
            helperText=''
            checkedValue={true}
            checked={formData.groupCreationSetting === ORGANIZATION_SETTINGS_VALUE.PUBLIC}
            onChange={value => {
              const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.PUBLIC :
                ORGANIZATION_SETTINGS_VALUE.PRIVATE;
              updateFormDataHandler('groupCreationSetting', isChecked);
            }}
          />
        </Box>
        <Box py={1}>
          <Checkbox
            name='publicFields'
            label="前台顯示所在縣市"
            helperText=''
            checkedValue={true}
            checked={formData.publicFields.includes('cityName')}
            onChange={value => {
              const isChecked = (value === true) ? ['cityName'] : [];
              updateFormDataHandler('publicFields', isChecked);
            }}
          />
        </Box>
        <Box py={1}>
          <Checkbox
            name='groupGradeSelectionSetting'
            label="班級所在年級可複選"
            helperText=''
            checkedValue={true}
            checked={formData.groupGradeSelectionSetting === ORGANIZATION_SETTINGS_VALUE.MULTIPLE}
            onChange={value => {
              const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.MULTIPLE :
                ORGANIZATION_SETTINGS_VALUE.SINGLE;
              updateFormDataHandler('groupGradeSelectionSetting', isChecked);
            }}
          />
        </Box>
        <Box py={1}>
          <Checkbox
            name='groupOwnerCreateSessionSetting'
            label="導師是否可以在前台開課"
            helperText=''
            checkedValue={true}
            checked={formData.groupOwnerCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW}
            onChange={value => {
              const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.ALLOW :
                ORGANIZATION_SETTINGS_VALUE.DISALLOW;
              updateFormDataHandler('groupOwnerCreateSessionSetting', isChecked);
            }}
          />
        </Box>
        <Box py={1}>
          <Checkbox
            name='groupOwnerInviteStaffSetting'
            label="導師是否可以在前台邀請老師"
            helperText=''
            checkedValue={true}
            checked={formData.groupOwnerInviteStaffSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW}
            onChange={value => {
              const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.ALLOW :
                ORGANIZATION_SETTINGS_VALUE.DISALLOW;
              updateFormDataHandler('groupOwnerInviteStaffSetting', isChecked);
            }}
          />
        </Box>
        <Box py={1}>
          <Checkbox
            name='sessionAttendanceManagementSetting'
            label="啟用簽到/簽退系統"
            helperText=''
            checkedValue={true}
            checked={formData.sessionAttendanceManagementSetting === ORGANIZATION_SETTINGS_VALUE.HOST}
            onChange={value => {
              const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.HOST :
                ORGANIZATION_SETTINGS_VALUE.NONE;
              updateFormDataHandler('sessionAttendanceManagementSetting', isChecked);
            }}
          />
        </Box>
        <Box py={1}>
          <Checkbox
            name='staffProfileOriginSetting'
            label="教師資料使用機構設定"
            helperText=''
            checkedValue={true}
            checked={formData.staffProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.CUSTOM}
            onChange={value => {
              const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.CUSTOM :
                ORGANIZATION_SETTINGS_VALUE.DEFAULT;
              updateFormDataHandler('staffProfileOriginSetting', isChecked);
            }}
          />
        </Box>
        <Box py={1}>
          <Checkbox
            name='customerProfileOriginSetting'
            label="學生資料使用機構設定"
            helperText=''
            checkedValue={true}
            checked={formData.customerProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.CUSTOM}
            onChange={value => {
              const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.CUSTOM :
                ORGANIZATION_SETTINGS_VALUE.DEFAULT;
              updateFormDataHandler('customerProfileOriginSetting', isChecked);
            }}
          />
        </Box>
      </UiAdvancedSettingBox>

      <UiButtonWrapper>
        <Button loading={isLoading} onClick={submitHandler}>確認</Button>
        <Button
          buttonColor="info"
          icon="exitToApp"
          type="submit"
          loading={isLoading}
          onClick={() => { history.goBack(); }}
        >
          回到上一頁
          </Button>
      </UiButtonWrapper>

      <BsModal
        open={isModalOpen}
        onOk={logoNotUploadedHandler}
        onCancel={logoNotUploadedHandler}
        cancelDisplay={false}
      >
      使用此設定前請先上傳首頁LOGO
      </BsModal>

      <CropperModal />
    </UiOrganizationForm>
  );
};

BsOrganizationForm.propTypes = {
  children: PropTypes.node,
};
