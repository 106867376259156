import styled from 'styled-components';

export const UiActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UiButton = styled.div`
  align-self: ${({ alignSelf }) => alignSelf };
  margin-right: ${({ marginRight }) => marginRight };
`;

export const UiMultipleSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const UiItem = styled.div`
  margin-bottom: 16px;
`;

export const UiItemsWrapper = styled.div`

`;

export const UiSessionForm = styled.div`
  width: 550px;
`;

export const UiTitle = styled.div`
  font-size: 1.6rem;
`;

export const UiTitleWrapper = styled.div`
  margin-bottom: 20px;
`;

