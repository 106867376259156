import styled from 'styled-components';
import { FormHelperText } from '@material-ui/core';

export const UiFormCheckBoxGroup = styled.div`
	display: flex;
`;

export const FormLabel = styled.div`
	display: flex;
	align-items: center;
	min-width: 100px;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.54);
	line-height: 1.1875em;
`;

export const CheckBoxGroup = styled.div`
`;

export const UiFormHelperText = styled(FormHelperText)`
	color: #f44336;
`;
