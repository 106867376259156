import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useClickAway } from 'ahooks';
import { UiOptionMenu, UiOption } from './OptionMenu.style';


/**
 * 更多按鈕的收納選單
 */

 export const OptionMenu = ({ onClose, options }) => {
  const ref = useRef(null);

  useClickAway(() => {
    onClose();
  }, ref);

  return (
    <UiOptionMenu ref={ref}>
      {options.map((option, index) => {
        const { label, onClick } = option;
        return (
          <UiOption key={index} onClick={onClick}>
            {label}
          </UiOption>
        );
      })}
    </UiOptionMenu>
  );
};

OptionMenu.propTypes = {
  onClose: PropTypes.func,
  options: PropTypes.array,
};


