import {
  Button,
  BsHeaderTitle,
  BsSubjectPermissionManagementPageHeaderTab,
  SubjectPermissionActions,
  SubjectPermissionTable,
  SubjectPermissionTablePagination
} from 'components';
import { EDUCATION_LEVEL } from 'constants/index';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getEducations } from 'services/api/organization/organizations';
import { UiBsContentContainer, UiflexBox } from 'styles';
import { useAlert } from 'utils/hooks/useAlert';
import {
  ALL_SUBJECTS,
  ALL_SUBJECTS_CODE
} from './SubjectPermissionPage.constant';
import { ConfirmModal } from './ConfirmModal/ConfirmModal';

const TAB_INDEX_MAP = {
  staff: 0,
  customer: 1
};

/**
 * 科目權限頁面
 */
// 狀態 users
// users =  [
//   {
//     id: '1',
//     nickname: 'aaa',
//     elementarySubjects: [SUBJECT.MA, SUBJECT.SO],
//     juniorHighSubjects: [SUBJECT.CH, SUBJECT.MA],
//   }
// ]
export const SubjectPermissionPage = () => {
  const location = useLocation();
  const pathLeaf = location.pathname.split('/').slice(-1)[0];
  const tabIndex = TAB_INDEX_MAP[pathLeaf];
  const { organizationId } = useParams();
  const { setAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [filterData, setFilterData] = useState({ keyword: '' });
  const [educationsApiData, setEducationApiData] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPage: 0
  });
  const [users, setUsers] = useState([]);
  const hasUsers = users.length > 0;

  const toggleConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  /* change users 的 checkbox */
  const changeUsers = ({ key, subjectCode, user }) => event => {
    let updatedUser = [];
    const isChecked = event.target.checked;
    // 全科
    if (subjectCode === ALL_SUBJECTS_CODE) {
      const updatedSubjects = isChecked ? ALL_SUBJECTS : [];
      updatedUser = {
        ...user,
        [key]: updatedSubjects
      };
    }
    // 單科
    else {
      const subjects = user[key];
      const updatedSubjects = isChecked
        ? [...subjects, subjectCode]
        : subjects.filter(subject => subject !== subjectCode);
      updatedUser = {
        ...user,
        [key]: updatedSubjects
      };
    }
    setUsers(prevUsers =>
      prevUsers.map(prevUser => {
        const modifiedUser = prevUser.id === user.id ? updatedUser : prevUser;
        return modifiedUser;
      })
    );
  };

  /* change 搜尋欄位 */
  const changeFilterData = keyword => {
    setFilterData(prev => ({ ...prev, keyword }));
  };

  /* 取得 科目權限 API 資料 */
  const fetchEducations = async params => {
    try {
      setIsLoading(true);
      const payload = {
        role: pathLeaf,
        ...params
      };
      const { status, data, error } = await getEducations(
        organizationId,
        payload
      );
      if (status === 'success') {
        setEducationApiData(data);
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      setAlert(error || '取得科目權限失敗', 'error');
    }
    setIsLoading(false);
  };

  /* 搜尋 科目權限 */
  const searchSubjectPermission = async params => {
    await fetchEducations(params);
    setPagination(prev => ({
      ...prev,
      page: 0,
    }));
  };

  /* 切上下頁 */
  const changePage = (event, newPage) => {
    const params = {
      skip: newPage * pagination.rowsPerPage,
      limit: pagination.rowsPerPage,
      ...(filterData.keyword && { userName: filterData.keyword })
    };
    fetchEducations(params);
    setPagination(prev => ({
      ...prev,
      page: newPage
    }));
  };

  /* 改每頁列數 */
  const changeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const params = {
      skip: 0,
      limit: rowsPerPage
    };
    fetchEducations(params);
    setPagination(prev => ({
      ...prev,
      page: 0,
      rowsPerPage
    }));
  };

  useEffect(() => {
    if (!educationsApiData) return;
    /* format educationsApiData 成 users */
    const formatUsersByApi = educationsApiData => {
      const newUsers = educationsApiData.list.map(apiUser => {
        const id = apiUser.userId;
        const nickname = apiUser.nickname;
        const elementarySettings = apiUser.settings.filter(
          setting => setting.id === EDUCATION_LEVEL.ELEMENTARY
        );
        const elementarySubjects = elementarySettings.flatMap(
          setting => setting.subjects
        );
        const juniorHighSettings = apiUser.settings.filter(
          setting => setting.id === EDUCATION_LEVEL.JUNIOR
        );
        const juniorHighSubjects = juniorHighSettings.flatMap(
          setting => setting.subjects
        );
        const highSettings = apiUser.settings.filter(
          setting => setting.id === EDUCATION_LEVEL.SENIOR
        );
        const highSubjects = highSettings.flatMap(
          setting => setting.subjects
        );
        return {
          id,
          nickname,
          elementarySubjects,
          juniorHighSubjects,
          highSubjects,
        };
      });
      return newUsers;
    };
    const users = formatUsersByApi(educationsApiData);
    setUsers(users);
    setPagination(prev => ({
      ...prev,
      totalPage: educationsApiData.total
    }));
  }, [educationsApiData]);

  useEffect(() => {
    const params = {
      skip: pagination.page * pagination.rowsPerPage,
      limit: pagination.rowsPerPage
    };
    fetchEducations(params);
  }, []);

  return (
    <>
      <BsHeaderTitle title="科目權限" />
      <BsSubjectPermissionManagementPageHeaderTab activeIndex={tabIndex} />
      <UiBsContentContainer>
        <SubjectPermissionActions
          filterData={filterData}
          onChange={changeFilterData}
          onSearch={searchSubjectPermission}
        />
        <SubjectPermissionTable
          isLoading={isLoading}
          users={users}
          setUsers={setUsers}
          onChangeUsers={changeUsers}
        />
        <SubjectPermissionTablePagination
          pagination={pagination}
          onChangePage={changePage}
          onChangeRowsPerPage={changeRowsPerPage}
        />
        {hasUsers && (
          <UiflexBox>
            <Button buttonColor="highlight" onClick={toggleConfirmModal}>
              儲存
            </Button>
          </UiflexBox>
        )}
      </UiBsContentContainer>

      <ConfirmModal
        isOpen={isOpenConfirmModal}
        users={users}
        onToggle={toggleConfirmModal}
      />
    </>
  );
};
