import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 * 獲取班級學生列表
 -@param {string} organizationId
 */
export const getCustomers = isHome => (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage, nickname, mobileNumber, customerId } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (nowPage && nowPage * rowsPage !== 0) queryParams.skip = nowPage * rowsPage;

  if (nickname) queryParams.nickname = nickname;

  if (mobileNumber) queryParams.mobileNumber = mobileNumber;

  if(customerId) queryParams.customerId = customerId;

  if (classId && !isHome) queryParams.groupId = classId;

  const querystring = await stringify(queryParams);
  const path = isHome ? `/groups/${classId}/users` : `/organizations/${organizationId}/customers`;
  const response = await Api.get(`${path}?${querystring}`);
  return response;
};

export const getOrgCustomers = async (organizationId, params) => {
  const { nowPage, rowsPage, nickname, mobileNumber, customerId } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (nowPage && nowPage * rowsPage !== 0) queryParams.skip = nowPage * rowsPage;

  if (nickname) queryParams.nickname = nickname;

  if (mobileNumber) queryParams.mobileNumber = mobileNumber;

  if(customerId) queryParams.customerId = customerId;

  const querystring = await stringify(queryParams);
  const path = `/organizations/${organizationId}/customers`;
  const response = await Api.get(`${path}?${querystring}`);
  return response;
};

/**
 * 獲取組織下所有學生列表
 -@param {string} organizationId
 */
export const getAllCustomers = isHome => (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage, nickname, mobileNumber } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 100,
  };
  if (nowPage && nowPage * rowsPage !== 0) queryParams.skip = nowPage * rowsPage;

  if (nickname) queryParams.nickname = nickname;

  if (mobileNumber) queryParams.mobileNumber = mobileNumber;

  const querystring = await stringify(queryParams);
  const path = isHome ?
    `/organizations/${organizationId}/groups/${classId}/customers` :
    `/organizations/${organizationId}/customers`;
  const response = await Api.get(`${path}?${querystring}`);
  return response;
};

/**
 * 獲取學生資料
 -@param {string} customerId
 */
export const getCustomer = (organizationId, customerId) => async () => {
  const response = await Api.get(`/organizations/${organizationId}/customers/${customerId}`);
  return response;
};


/**
 * 獲取學生資料
 -@param {string} customerId
 */
export const updateCustomer = (organizationId, customerId) => async params => {
  const response = await Api.put(`/organizations/${organizationId}/customers/${customerId}`, params);
  return response;
};


/**
 * 邀請學生
 * @param {object} customers
 */
export const inviteOrganizationCustomers = organizationId => async params => {
  const response = await Api.post(`/organizations/${organizationId}/customers/invite`, params);
  return response;
};


export const addUserToGroup = isHome => (organizationId, classId) => async params => {
  const { userIdList } = params;
  const path = isHome ? `/groups/${classId}/users` : `/organizations/${organizationId}/groups/${classId}/users`;
  const response = await Api.post(path, { userIdList });
  return response;
};


/**
 * Remove User From Organization Group
 */
export const removeUserToGroup = isHome => (organizationId, classId) => async params => {
  const { userId } = params;
  const path = isHome ? `/groups/${classId}/users` : `/organizations/${organizationId}/groups/${classId}/users`;
  const response = await Api.delete(`${path}/${userId}`);
  return response;
};

//設定學生座號
export const updateGroupAttendeeNumbers = classId => async params => {
  const response = await Api.put(`/groups/${classId}/users/attendee-numbers`, params);
  return response;
};

export const joinStudentsIntoOrganization = async (organizationId, payload) => {
  const response = await Api.post(`/organizations/${organizationId}/customers/join`, payload);
  return response;
};
