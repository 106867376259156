import styled from 'styled-components';
import { FormHelperText as MatFormHelperText } from '@material-ui/core';

export const UiButtonContainer = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

export const UiCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 80px;
  min-width: 170px;
  padding: 10px 12px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;

  &.card-enter {
    transform: scale(0)
  }
  &.card-enter-active {
    transform: scale(1);
    transition: transform 150ms linear;
    transition-delay: ${({ index }) => `${index * 200}ms`};
  }
  &.card-exit {
    transform: scale(1);
  }
  &.card-exit-active {
    transform: scale(0);
    transition: transform 100ms linear;
  }
`;

export const UiCards = styled.div`
  & > div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
`;

export const UiCardRemove = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
`;

export const UiCardSubTitle = styled.div`
  color: rgb(133, 133, 133);
  font-size: 0.8rem;
`;

export const UiCardTitle = styled.div`
  font-size: 0.93rem;
`;

export const UiMatFormHelperText = styled(MatFormHelperText)`
  color: #f44336;
`;

export const UiResourceModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiResourcesContainer = styled.div`
  display: flex;
  flex-direction: column ;
`;