import React from 'react';
import SVG from './svg';
import { UiSvgIcon } from './Icon.style';


export const IconSvg = ({ 
  className,
  name,
  color,
  onClick,
  size,
}) => {
  return (
      <UiSvgIcon 
        onClick={onClick} 
        className={className} 
        component={SVG[name]} 
        color={color}
        fontSize={size}
      />
  );
};
