import React, { useState } from 'react';
import {
  PdfUploadedTable,
  BsHeaderTitle,
  BsResourceManagementPageHeaderTab,
  BsHeaderTab,
  OrganizationPaper,
} from 'components';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { useUser } from 'store/user';
import { UiBsContentContainer } from './PdfUploadPage.style';


/**
 * 教學資源列表
 */

export const PdfUploadPage = () => {
  const [secondaryTab, setSecondaryTab] = useState(0);
  const [{ myOrganization }] = useUser();
  const isEnabledOrganizationSharedPapersSetting = myOrganization.organization?.organizationSharedPapersSetting === ORGANIZATION_SETTINGS_VALUE.ENABLED;
  const secondaryTabs = [
    {
      label: 'PDF檔',
      onClick: () => setSecondaryTab(0),
    },
    {
      label: '機構試卷',
      onClick: () => setSecondaryTab(1),
    },
  ];
  return (
    <>
      <BsHeaderTitle title="資源列表" />
      <BsResourceManagementPageHeaderTab activeIndex={0} />
      {isEnabledOrganizationSharedPapersSetting && <BsHeaderTab data={secondaryTabs} activeIndex={secondaryTab} theme="default" />}
      <UiBsContentContainer>
        {secondaryTab === 0 && <PdfUploadedTable />}
        {secondaryTab === 1 && <OrganizationPaper />}
      </UiBsContentContainer>
    </>
  );
};
