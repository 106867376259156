import styled from 'styled-components';

export const UiWebResource = styled.div`
	display: block;
`;

export const UiTipBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #8B90A0;
	padding: 20px 0;
`;

export const UiTable = styled.div`
	display: block;
	padding: ${({ theme }) => theme.table.padding};
	width: 100%;
	margin-bottom: 50px;
`;

export const UiTableHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-bottom: 8px;
	border-bottom: 1px solid #D5D7DE;
`;

export const UiTableHeaderTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	color: #242C3F;
`;

export const UiIcon = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	color: #454B5C;
	> svg {
		margin: 0 5px
	}
`;