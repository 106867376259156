import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';


export const UiBsModal = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: ${props => props.name === 'prompt' ? '48px 24px 20px 60px' : '48px 24px 20px' };
	max-width: 600px;
	background-color: #fff;
	flex-direction: column;
	border-radius: 4px;

	> .close {
		position: absolute;
		top: 29px;
		right: 29px;
		width: max-content;
		width: 24px;
		cursor: pointer;
		height: 24px;

		&:hover {
			opacity: 0.8;
		}
	}

	> .title {
		/* padding: 0 36px; */
		font-size: 24px;
		font-weight: 500;
		color: #454B5C;
		margin-bottom: 24px;
	}

	> .content {
		/* padding: 0 36px; */
		font-size: 14px;
		color: #505565;
		margin-bottom: 56px;
		line-height: 32px;
	}

	> .buttonBox {
		display: flex;
		align-items: center;
		justify-content: ${props => (props.buttonsCenter ? 'center' : 'flex-end')};

		.button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 36px;
			border-radius: 2px;
			transition: 0.35s;
			cursor: pointer;
			border-radius: 4px;

			&:hover {
				opacity: 0.8;
			}

			&.white {
				margin-right: 10px;
				font-size: 14px;
				color: #8B90A0;
				background-color: #FAFAFC;
			}

			&.black {
				font-size: 14px;
				color: #fff;
				background-color: #121232;
			}

			&.disabled {
				background-color: #f2f4f8;
				color: #d5d7de;
				cursor: not-allowed;
			}
		}
	}
`;

export const UiCircularProgress = styled(CircularProgress)`
	color: #fff;
`;

export const UiWhiteCircularProgress = styled(CircularProgress)`
	color: #8b90a0;
`;
