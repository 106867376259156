import React from 'react';
import PropTypes from 'prop-types';
import { BsOrganizationForm, BsHeaderTitle } from 'components';


/**
 * 在這邊描述這個組件
 */

 export const OrganizationEdit = props => {
  return (
    <>
      <BsHeaderTitle title="機構設定" />
      <BsOrganizationForm {...props} />
    </>
  );
};

OrganizationEdit.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool
};


