import styled from 'styled-components';

export const UiContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiListWrapper = styled.div`
  height: 480px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
`;

export const UiListAllContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 24px;
`;

export const UiListCheckedContainer = styled.div`
  width: 280px;
  background: #F2F4F8;
  border: 1px solid #E4E7EC;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const UiActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5px;
`;
