import React, { useEffect, useState, useMemo, } from 'react';
import PropTypes from 'prop-types';
import ContentTabs from './ContentTabs';
import ContentSearch from './ContentSearch';
import ContentListAll from './ContentListAll';
import ContentListChecked from './ContentListChecked';

import {
  Button,
  Loading,
} from 'components';
import { useResource } from 'store/resource';
import {
  UiContent,
  UiListWrapper,
  UiListAllContainer,
  UiListCheckedContainer,
  UiActionsWrapper,
} from './ResourceModalContent.style';

export const ResourceModalContent = ({
  checkedCheckbox,
  editStatus,
  fetchResourceOrganization,
  fetchResourcePublic,
  hostId,
  isCheckedCheckboxFull,
  isLoadingResources,
  onChangeCheckbox,
  onChangeUncheckCheckbox,
  onClickClose,
  onClickSubmit,
  privateResources,
}) => {
  const [{ resources, bookstoreBooks }] = useResource();
  const [isDidMount, setIsDidMount] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);            // tab index
  const [fileName, setFileName] = useState(null);         // 機構資源 - 搜尋後文字
  const [displayName, setDisplayName] = useState(null);   // 公開資源 - 搜尋後文字
  const [selectedOption, setSelectedOption] = useState({  // 公開資源 - 選擇後選項
    schoolYear: '',
    educationalSystem: '',
    grade: '',
    subject: ''
  });

  /* checkbox 列表 - 機構資源 */
  const checkboxListOrganization = resources.data.map(resource => ({
      id: resource.resourceId,
      name: resource.resourceId,
      label: resource.displayName,
      isChecked: checkedCheckbox.some((obj, index)=>{
        if(obj.id === resource.resourceId) return true;
        else if(index === checkedCheckbox.length - 1) return false;
      })
    })
  );

  /* checkbox 列表 - 公開資源 */
  const checkboxListPublic = bookstoreBooks.data.map(book => ({
      id: book.bookId,
      name: book.bookId,
      label: book.displayName,
      isChecked: checkedCheckbox.some((obj, index)=>{
        if(obj.id === book.bookId) return true;
        else if(index === checkedCheckbox.length - 1) return false;
      })
    })
  );

  /* checkbox 列表 - 我的資源 */
  const checkboxListPrivate = privateResources && privateResources.map(resource => ({
      id: resource.resourceId,
      name: resource.resourceId,
      label: resource.displayName,
      isChecked: checkedCheckbox.some((obj, index)=>{
        if(obj.id === resource.resourceId) return true;
        else if(index === checkedCheckbox.length - 1) return false;
      })
    })
  );

  /* enter 搜尋按鈕，設定搜尋參數 */
  const searchInputChangeHandler = (inputValue) => {
    tabIndex === 0 && setFileName(inputValue);
    tabIndex === 1 && setDisplayName(inputValue);
  };

  /* click 搜尋按鈕，設定搜尋參數 */
  const searchInputClickHandler = (inputValue) => {
    tabIndex === 0 && setFileName(inputValue);
    tabIndex === 1 && setDisplayName(inputValue);
  };

  /* change tab 選擇狀況 */
  const tabChangeHandler = (index) => {
    setTabIndex(index);
  };

  /* select 選擇後 */
  const selectChangeHandler = (key) => (value) => {
    let newSelectedOption = { ...selectedOption };
    newSelectedOption[key] = value;
    setSelectedOption(newSelectedOption);
  };

  useEffect(() => {
    setIsDidMount(true);
  }, []);

  /* 取得機構資源 */
  useEffect(() => {
    isDidMount && fetchResourceOrganization(fileName);
  }, [fileName]);

  /* 取得公開資源 */
  useEffect(() => {
    const tags = Object.values(selectedOption).filter(item => item);
    isDidMount && fetchResourcePublic(displayName, tags);
  }, [displayName, selectedOption]);

  /* 選到的tab，對應的checkboxList */
  const selectedCheckboxList = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return checkboxListOrganization;
      case 1:
        return checkboxListPublic;
      case 2:
        return checkboxListPrivate;
      default:
        break;
    }
  }, [resources, bookstoreBooks, privateResources, tabIndex, checkedCheckbox]);


  return(
    <>
      <UiContent>
        <UiListWrapper>
          <UiListAllContainer>
            {/* 教學資源、公開資源、我的資源 */}
            <ContentTabs editStatus={editStatus} hostId={hostId} tabIndex={tabIndex} onChange={tabChangeHandler} />
            {/* 搜尋列 */}
            <ContentSearch
              placeholder={'搜尋資源名稱'}
              selectedOption={selectedOption}
              tabIndex={tabIndex}
              onChangeSelect={selectChangeHandler}
              onChangeSearch={searchInputChangeHandler}
              onClickSearch={searchInputClickHandler} />
            {
              /* checkbox 列表 */
              isLoadingResources
                ? <Loading />
                : <ContentListAll data={selectedCheckboxList} onChange={onChangeCheckbox} />
            }
          </UiListAllContainer>
          <UiListCheckedContainer>
            {/* 右邊勾選的checkbox列表 */}
            <ContentListChecked
              checkedCheckbox={checkedCheckbox}
              isCheckedCheckboxFull={isCheckedCheckboxFull}
              onDelete={onChangeUncheckCheckbox} />
          </UiListCheckedContainer>
        </UiListWrapper>
        <UiActionsWrapper>
          <Button 
            buttonColor='highlight' 
            onClick={onClickClose}
          >取消</Button>
          <Button 
            onClick={onClickSubmit}
            disabled={checkedCheckbox.length === 0}
          >確定</Button>
        </UiActionsWrapper>
      </UiContent>
    </>
  );
};

ResourceModalContent.propTypes = {
  checkedCheckbox: PropTypes.array,
  editStatus: PropTypes.string,
  fetchResourceOrganization: PropTypes.func,
  fetchResourcePublic: PropTypes.func,
  hostId: PropTypes.string,
  isCheckedCheckboxFull: PropTypes.bool,
  isLoadingResources: PropTypes.bool,
  onChangeCheckbox: PropTypes.func,
  onChangeUncheckCheckbox: PropTypes.func,
  onClickClose: PropTypes.func,
  onClickSubmit: PropTypes.func,
  privateResources: PropTypes.array,
};
