import { stringify } from 'query-string';
import { Api } from 'services/api';


/**
 * 取得&查詢 機構教師 list
 * @param {string}} organizationId
 * @param {number}} nowPage
 * @param {number}} rowsPage
 * @param {string}} nickname
 * @param {string}} mobileNumber
 */
export const getTeachers = (isHome) => (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage, nickname, mobileNumber,targetUserId } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (nickname) {
    queryParams.nickname = nickname;
  }
  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }
  if(targetUserId) {
    queryParams.targetUserId = targetUserId;
  }
  const querystring = stringify(queryParams);

  let url = '';

  if (isHome) {
    url = `/groups/${classId}/host-candidates?${querystring}`;
    if (organizationId) {
      url = `/groups/${classId}/hosts?${querystring}`;
    }
  } else {
    url = `/organizations/${organizationId}/staffs?${querystring}`;
  }

  const response = await Api.get(url);
  return response;
};

// 機構成員
export const getOrganizationStaffs = (isHome) => (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage, nickname, mobileNumber, targetUserId, currentGroupHostOnly } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  const groupId = classId;
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (nickname) {
    queryParams.nickname = nickname;
  }
  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }
  if (targetUserId) {
    queryParams.targetUserId = targetUserId;
  }
  if (isHome && currentGroupHostOnly) {
    queryParams.currentGroupHostOnly = currentGroupHostOnly;
  }

  const querystring = stringify(queryParams);

  let url = '';

  if (isHome) {
    url = `/organizations/${organizationId}/groups/${groupId}/staffs?${querystring}`;
  } else {
    url = `/organizations/${organizationId}/staffs?${querystring}`;
  }

  const response = await Api.get(url);
  return response;
};

/**
 * 建立 機構教師 information
 * @param {object} payload
 */
export const createTeachInfo = organizationId => async params => {
  const response = await Api.post(`/organizations/${organizationId}/staffs`, params);
  return response;
};

export const updateTeachInfo = (organizationId, staffId) => async params => {
  const response = await Api.put(`/organizations/${organizationId}/staffs/${staffId}`, params);
  return response;
};

export const inviteTeachersToOrganizationGroup = (organizationId, classId) => async params => {
  const response = await Api.post(`/organizations/${organizationId}/groups/${classId}/hosts/invite`, params);
  return response;
};

/**
 * Remove Group Host Candidate
 * 移出 無組織教師(目前無課程才可移除)
 * @param {object} payload
 */
export const removeGroupHostCandidate = groupId => async (userId) => {
  const response = await Api.delete(`/groups/${groupId}/host-candidates/${userId}`);
  return response;
};
