import styled from 'styled-components';

export const UiFilter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	> * {
		height: max-content;
	}
`;

export const UiSearchInput = styled.div`
	width: 230px;
`;