import { Loading } from 'components';
import { EDUCATION_LEVEL, SUBJECT } from 'constants/index';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { UiTableContainer } from './SubjectPermissionTable.style';
import {
  ALL_SUBJECTS,
  ALL_SUBJECTS_CODE
} from 'components/pages/SubjectPermissionPage/SubjectPermissionPage.constant';
import { useUser } from 'store/user';

const CHECKBOX_OPTIONS = [
  {
    LABEL: '全科',
    SUBJECT_CODE: ALL_SUBJECTS_CODE
  },
  {
    LABEL: '國',
    SUBJECT_CODE: SUBJECT.CH
  },
  {
    LABEL: '數',
    SUBJECT_CODE: SUBJECT.MA
  },
  {
    LABEL: '英',
    SUBJECT_CODE: SUBJECT.EN
  },
  {
    LABEL: '自',
    SUBJECT_CODE: SUBJECT.NA
  },
  {
    LABEL: '社',
    SUBJECT_CODE: SUBJECT.SO
  }
];
const ROLE_MAP = {
  staff: '老師',
  customer: '學生',
};

/* 取得 tableHeader checkbox 的 checked 狀態  */
function getTableHeaderCheckedState({ key, subjectCode, users }) {
  const isChecked =
    subjectCode === ALL_SUBJECTS_CODE
      ? ALL_SUBJECTS.every(SUBJECT =>
          users.every(user => user[key].includes(SUBJECT))
        )
      : users.every(user => user[key].includes(subjectCode));
  return isChecked;
}
/* 取得 users checkbox 的 checked 狀態  */
function getUsersCheckedState({ subjects, subjectCode }) {
  const isChecked =
    subjectCode === ALL_SUBJECTS_CODE
      ? ALL_SUBJECTS.every(SUBJECT => subjects.includes(SUBJECT))
      : subjects.includes(subjectCode);
  return isChecked;
}

export const SubjectPermissionTable = ({
  isLoading,
  users,
  setUsers,
  onChangeUsers
}) => {
  const location = useLocation();
  const pathLeaf = location.pathname.split('/').slice(-1)[0];
  const role = ROLE_MAP[pathLeaf];
  const hasUsers = !!users.length;
  const tableHeaderNickname = `本頁共 ${users.length} 位${role}`;
  const [tableHeader, setTableHeader] = useState({
    elementarySubjects: [],
    juniorHighSubjects: [],
    highHighSubjects: [],
  });
  const [{ myOrganization }] = useUser();
  const educationNames = myOrganization.organization.educationNames;
  const hasElementary = educationNames.includes(EDUCATION_LEVEL.ELEMENTARY);
  const hasJuniorHigh = educationNames.includes(EDUCATION_LEVEL.JUNIOR);
  const hasHigh = educationNames.includes(EDUCATION_LEVEL.SENIOR);
  const tableHeadCells = [
    {
      name: '機構暱稱'
    }
  ];
  hasElementary &&
    tableHeadCells.push({
      name: '國小'
    });
  hasJuniorHigh &&
    tableHeadCells.push({
      name: '國中'
    });
  hasHigh &&
    tableHeadCells.push({
      name: '高中'
    });

  /* change tableHeader 的 checkbox */
  const changeTableHeader = ({ key, subjectCode, users }) => event => {
    let updatedUsers = [];
    let updatedSubjects = [];
    const isChecked = event.target.checked;
    const subjects = tableHeader[key];
    // 全科
    if (subjectCode === ALL_SUBJECTS_CODE) {
      updatedSubjects = isChecked ? ALL_SUBJECTS : [];
      updatedUsers = users.map(user => ({
        ...user,
        [key]: updatedSubjects
      }));
    }
    // 單科
    else {
      updatedSubjects = isChecked
        ? [...subjects, subjectCode]
        : subjects.filter(subject => subject !== subjectCode);
      updatedUsers = users.map(user => ({
        ...user,
        [key]: updatedSubjects
      }));
    }
    setTableHeader(prev => ({
      ...prev,
      [key]: updatedSubjects
    }));
    setUsers(updatedUsers);
  };

  return (
    <UiTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeadCells.map(cell => {
              return (
                <TableCell key={cell.name} align="center" scope="column">
                  {cell.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow style={{ backgroundColor: '#FFF' }}>
              <TableCell
                colSpan={tableHeadCells.length}
                style={{ textAlign: 'center' }}
              >
                <Loading />
              </TableCell>
            </TableRow>
          ) : hasUsers ? (
            <>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  <Box>
                    <Typography>{tableHeaderNickname}</Typography>
                  </Box>
                </TableCell>
                {hasElementary && (
                  <TableCell align="center">
                    <FormGroup
                      style={{ flexDirection: 'row', justifyContent: 'center' }}
                    >
                      {CHECKBOX_OPTIONS.map(option => {
                        return (
                          <FormControlLabel
                            label={option.LABEL}
                            key={option.SUBJECT_CODE}
                            control={
                              <Checkbox
                                color="primary"
                                checked={getTableHeaderCheckedState({
                                  key: 'elementarySubjects',
                                  subjectCode: option.SUBJECT_CODE,
                                  users
                                })}
                                onChange={changeTableHeader({
                                  key: 'elementarySubjects',
                                  subjectCode: option.SUBJECT_CODE,
                                  users
                                })}
                              />
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </TableCell>
                )}
                {hasJuniorHigh && (
                  <TableCell align="center">
                    <FormGroup
                      style={{ flexDirection: 'row', justifyContent: 'center' }}
                    >
                      {CHECKBOX_OPTIONS.map(option => {
                        return (
                          <FormControlLabel
                            label={option.LABEL}
                            key={option.SUBJECT_CODE}
                            control={
                              <Checkbox
                                color="primary"
                                checked={getTableHeaderCheckedState({
                                  key: 'juniorHighSubjects',
                                  subjectCode: option.SUBJECT_CODE,
                                  users
                                })}
                                onChange={changeTableHeader({
                                  key: 'juniorHighSubjects',
                                  subjectCode: option.SUBJECT_CODE,
                                  users
                                })}
                              />
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </TableCell>
                )}
                {hasHigh && (
                  <TableCell align="center">
                    <FormGroup
                      style={{ flexDirection: 'row', justifyContent: 'center' }}
                    >
                      {CHECKBOX_OPTIONS.map(option => {
                        return (
                          <FormControlLabel
                            label={option.LABEL}
                            key={option.SUBJECT_CODE}
                            control={
                              <Checkbox
                                color="primary"
                                checked={getTableHeaderCheckedState({
                                  key: 'highSubjects',
                                  subjectCode: option.SUBJECT_CODE,
                                  users
                                })}
                                onChange={changeTableHeader({
                                  key: 'highSubjects',
                                  subjectCode: option.SUBJECT_CODE,
                                  users
                                })}
                              />
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </TableCell>
                )}
              </TableRow>
              {users.map(user => {
                return (
                  <TableRow key={user.id}>
                    <TableCell component="th" scope="row" align="center">
                      {user.nickname}
                    </TableCell>
                    {hasElementary && (
                      <TableCell align="center">
                        <FormGroup
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}
                        >
                          {CHECKBOX_OPTIONS.map(option => {
                            return (
                              <FormControlLabel
                                label={option.LABEL}
                                key={option.SUBJECT_CODE}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={getUsersCheckedState({
                                      subjects: user.elementarySubjects,
                                      subjectCode: option.SUBJECT_CODE
                                    })}
                                    onChange={onChangeUsers({
                                      key: 'elementarySubjects',
                                      subjectCode: option.SUBJECT_CODE,
                                      user
                                    })}
                                  />
                                }
                              />
                            );
                          })}
                        </FormGroup>
                      </TableCell>
                    )}
                    {hasJuniorHigh && (
                      <TableCell align="center">
                        <FormGroup
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}
                        >
                          {CHECKBOX_OPTIONS.map(option => {
                            return (
                              <FormControlLabel
                                label={option.LABEL}
                                key={option.SUBJECT_CODE}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={getUsersCheckedState({
                                      subjects: user.juniorHighSubjects,
                                      subjectCode: option.SUBJECT_CODE
                                    })}
                                    onChange={onChangeUsers({
                                      key: 'juniorHighSubjects',
                                      subjectCode: option.SUBJECT_CODE,
                                      user
                                    })}
                                  />
                                }
                              />
                            );
                          })}
                        </FormGroup>
                      </TableCell>
                    )}
                    {hasHigh && (
                      <TableCell align="center">
                        <FormGroup
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}
                        >
                          {CHECKBOX_OPTIONS.map(option => {
                            return (
                              <FormControlLabel
                                label={option.LABEL}
                                key={option.SUBJECT_CODE}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={getUsersCheckedState({
                                      subjects: user.highSubjects,
                                      subjectCode: option.SUBJECT_CODE
                                    })}
                                    onChange={onChangeUsers({
                                      key: 'highSubjects',
                                      subjectCode: option.SUBJECT_CODE,
                                      user
                                    })}
                                  />
                                }
                              />
                            );
                          })}
                        </FormGroup>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </>
          ) : (
            <TableRow>
              <TableCell
                colSpan={tableHeadCells.length}
                style={{ textAlign: 'center', backgroundColor: '#FFF' }}
              >
                <Typography>沒有資料</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </UiTableContainer>
  );
};

SubjectPermissionTable.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.array,
  setUsers: PropTypes.func,
  onChangeUsers: PropTypes.func
};
