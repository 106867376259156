import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, BsModal, Table } from 'components';
import { useAlert } from 'utils/hooks/useAlert';
import { UiInput, UiTableContainer } from './UploadButtonExcel.style';
import Excel from 'exceljs';

const fileToBuffer = (file) => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    const readFile = function (event) {
      const buffer = reader.result;
      resolve(buffer);
    };

    reader.addEventListener('load', readFile);
    reader.readAsArrayBuffer(file);
  });
};

/* 刪掉指定的 column */
const filterSheet = ({ worksheet, removeColumns }) => {
  if (!removeColumns || !removeColumns?.length) return;
  removeColumns.forEach(column => {
    const tableHeaders = worksheet.getRow(1).values;
    const removeColIndex = tableHeaders.findIndex((header) => header === column);
    worksheet.spliceColumns(removeColIndex, 1);
  });
};

const UploadButtonExcel = ({
  children,
  title = children.toString(),
  validateExcel,
  handleJoin,
  handleAfterImport,
  removeColumns,
}) => {
  const { setAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelBody, setExcelBody] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isCompleteCheck, setIsCompleteCheck] = useState(false);
  const [isCompleteImport, setIsCompleteImport] = useState(false);
  const validateExcelData = async () => {
    const isError = await validateExcel(excelBody, setViewData);
    setIsError(isError);
    setIsCompleteCheck(true);
  };

  const schema = useMemo(() => {
    if (!excelHeader.length) return {};
    return (
      excelHeader.reduce((prev, curr) => ({
        ...prev,
        [curr]: {
          name: curr,
          defaultValue: '',
        }
      }), {})
    );
  }, [excelHeader]);

  const tableData = useMemo(() => {
    if (!viewData.length) return;
    const data = viewData.map(row =>
      row.reduce((prev, curr, index) => ({
        ...prev,
        [excelHeader[index]]: curr,
      }), {})
    );
    return data;
  }, [viewData]);

  /* 上傳檔案 */
  const handleUpload = async (e) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      const data = await fileToBuffer(file);
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(data);
      let headers = [];
      const body = [];
      workbook.eachSheet((worksheet, sheetId) => {
        filterSheet({ worksheet, removeColumns });
        worksheet.eachRow((row, rowNumber) => {
          const values = row.values.map(value => typeof value === String ? value.trim() : value);
          values.shift(); // 第一個 element 為 empty
          if (rowNumber === 1) {
            headers = values.map(value => value.toLowerCase());
            headers.unshift('狀態');
          } else {
            body.push(values);
          }
        });
      });
      setExcelHeader(headers);
      setExcelBody(body);
      setIsOpenModal(true);
    } else {
      setAlert('上傳檔案失敗', 'error');
    }
  };

  const handleOk = async () => {
    await handleJoin(tableData, setViewData, setIsLoading, setIsCompleteImport);
  };

  const handleOkAfterImport = async () => {
    await handleAfterImport();
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (!excelBody.length) return;
    validateExcelData();
  }, [excelBody]);

  return (
    <>
      {
        isOpenModal &&
        <BsModal
          title={title}
          open={isOpenModal}
          isFull={true}
          isLoading={isLoading}
          maxWidth={'900px'}
          okDisabled={!isCompleteCheck || isError}
          okText={isCompleteImport ? '確認' : '匯入'}
          onOk={isCompleteImport ? handleOkAfterImport : handleOk}
          onCancel={() => setIsOpenModal(false)}>
          <UiTableContainer>
            <Table
              schema={schema}
              data={tableData}
              isNeedPagination={false}
              totalPage={tableData.length}
            />
          </UiTableContainer>
        </BsModal>
      }
      <UiInput
        accept='.xlsx'
        id='file-upload'
        type='file'
        onChange={handleUpload}
        onClick={e => e.target.value = null}
      />
      <label htmlFor='file-upload'>
        <Button buttonColor='highlight' component='span' icon='upload'>
          {children}
        </Button>
      </label>

    </>
  );
};

export default UploadButtonExcel;

UploadButtonExcel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  validateExcel: PropTypes.func,
  handleJoin: PropTypes.func,
  handleAfterImport: PropTypes.func,
  removeColumns: PropTypes.array,
};
