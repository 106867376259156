import styled from 'styled-components';

export const UiWebResourceModal = styled.div`
	display: flex;
	overflow-x: auto;
	padding: 20px 0;
	width: 100%;
`;

export const UiTextField = styled.div`
	margin-top: 20px;
`;

export const UiTitle = styled.div`
	font-size: 18px;
	color: #8B90A0;
	font-weight: 400;
`;