import styled from 'styled-components';
import {
  Avatar as MatAvatar,
} from '@material-ui/core';

export const UiCustomerTable = styled.div`
	display: block;
`;

export const UiAvatar = styled(MatAvatar)`
	margin: auto;
`;
