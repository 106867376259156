import {
  MyOrganizationsPage,
} from 'components';

const routes = [
  {
    path: '/home',
    component: MyOrganizationsPage,
    exact: true
  },
];

export default routes;
