import styled from 'styled-components';

const imgWidth = '160px';

export const UiUploadImageBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.img {
		position: relative;
		overflow: hidden;
		width: ${imgWidth};
		height: ${imgWidth};
		border-radius: 20px;
		margin-bottom: 20px;

		svg {
			position: absolute;
			top: 5px;
			right: 5px;
			opacity: 0;
			transition: 0.35s;
			cursor: pointer;
		}

		&:hover svg {
			opacity: 1;
		}
	}

	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 12px;
		width: calc(${imgWidth} * 80%);
		color: #fff;
		background-color: #45A5E6;
		border-radius: 4px;
		cursor: pointer;
		opacity: 1;
		transition: 0.35s;

		&:hover {
			opacity: 0.8;
		}

		svg {
			margin-right: 5px;
		}

		input {
			display: none;
		}
	}
`;
