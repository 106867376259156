import { TablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const SubjectPermissionTablePagination = ({
  pagination,
  onChangePage,
  onChangeRowsPerPage
}) => {
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={pagination.totalPage}
      labelRowsPerPage="每頁顯示"
      rowsPerPage={pagination.rowsPerPage}
      page={pagination.page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
};

SubjectPermissionTablePagination.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    totalPage: PropTypes.number,
  }),
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func
};
