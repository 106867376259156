import styled from 'styled-components';
import { Tabs as MatTabs } from '@material-ui/core';

export const UiTabs = styled(MatTabs)(({ theme, indicatorTheme }) => {
	const getIndicatorBackgroundColor = () => {
		switch (indicatorTheme) {
			case 'default':
				return '#009be5';
			case 'primary':
			default:
				return theme.palette.common.white;
		}
	};
	const indicatorBackgroundColor = getIndicatorBackgroundColor();
	return {
		'& > div > span': {
			backgroundColor: indicatorBackgroundColor,
		}
	};
});