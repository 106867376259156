import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { getTownship } from 'services/api/organization/presets';
import { FormHelperText as MatFormHelperText } from '@material-ui/core';
import { UiCitySelector, UiSelector, UiHelperText } from './CitySelector.style';


/**
 * 城市選擇器
 *
 * @returns {string} county name
 * @returns {string} township name
 */

export const CitySelector = ({
  isFullWidth = true,
  variant = 'filled',
  value,
  required = false,
  onChange = () => { }
}) => {
  const [{ 
    county,
    countyArr,
    countyName,
    township,
    townshipArr,
    townshipName 
  }, setState] = useSetState({
    county: null,
    countyArr: [],
    countyName: '',
    township: null,
    townshipArr: [],
    townshipName: '',
  });

  useEffect(() => {
    if (
      !value ||
      Object.keys(value).length === 0 ||
      value.county ||
      value.addressCountyName === '') return;
    const { addressCountyName, addressDistrictName } = value;
    countyHandler(addressCountyName);
    setState({
      countyName: addressCountyName,
      townshipName: addressDistrictName,
    });
    onChange({
      county: addressCountyName,
      township: addressDistrictName
    });
  }, [value]);

  const fetchGetTownship = async () => {
    const { isSuccess, data } = await getTownship();
    if(isSuccess){
      setState({
        county: data.cities,
        township: data.districts,
      });
    }
  };

  useEffect(() => {
    fetchGetTownship();
  }, []);

  useEffect(() => {
    // 初始 縣市
    const nextCountyArr = county?.map(item => ({
      name: item,
      value: item,
    }));

    setState({
      countyArr: nextCountyArr,
    });
  }, [county]);

  // 改變 縣市
  const countyHandler = value => {
    if (!value || !township) return;
    const nextTownshipArr = township[value].map(item => ({
      name: item,
      value: item,
    }));

    onChange({
      county: value,
    });

    setState({
      countyName: value,
      townshipArr: nextTownshipArr,
    });
  };

  // 改變 鄉鎮
  const townshipHandler = value => {
    onChange({
      county: countyName,
      township: value
    });

    setState({ townshipName: value, });
  };

  return (
    <UiCitySelector isFullWidth={isFullWidth}>
      <UiSelector
        active={required && !countyName}
      >
        <Select
          label='縣市'
          variant={variant}
          width="100%"
          isOutline={false}
          options={countyArr}
          value={countyName}
          submitHandler={countyHandler}
        />
        {
          required && !countyName &&
          <UiHelperText>
            <MatFormHelperText>本欄位為必填</MatFormHelperText>
          </UiHelperText>
        }
      </UiSelector>
      <UiSelector
        active={required && !townshipName}
      >
        <Select
          label='鄉鎮'
          width="100%"
          variant={variant}
          isOutline={false}
          options={townshipArr}
          value={townshipName}
          submitHandler={townshipHandler}
        />
        {
          required && !townshipName &&
          <UiHelperText>
            <MatFormHelperText>本欄位為必填</MatFormHelperText>
          </UiHelperText>
        }
      </UiSelector>
    </UiCitySelector>
  );
};

CitySelector.propTypes = {
  variant: PropTypes.string,
  isFullWidth: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    addressCountyName: PropTypes.string,
    addressDistrictName: PropTypes.string,
    county: PropTypes.string
  })
};


