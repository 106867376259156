import React from 'react';
import PropTypes from 'prop-types';

import { Chip, } from 'components';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';


import {
  UiContentListChecked,
  UiListCheckedWrapper,
  UiTitleContainer,
  UiListContainer,
  UiChipBox,
  UiSnackbarContainer,
} from './ContentListChecked.style';

export const ContentListChecked = ({ checkedCheckbox, isCheckedCheckboxFull, onDelete, }) => {
  return (
    <UiContentListChecked>
      <UiListCheckedWrapper>
        <UiTitleContainer ckbLen={checkedCheckbox.length}>
          <span>已選擇：{checkedCheckbox.length}/6</span>
        </UiTitleContainer>
        <UiListContainer>
          <TransitionGroup>
            {
              checkedCheckbox.map(obj => {
                return (
                  <CSSTransition
                    key={obj.id}
                    classNames='chip'
                    timeout={500}
                  >
                    <UiChipBox>
                      <Chip id={obj.id} label={obj.name} onDelete={onDelete} />
                    </UiChipBox>
                  </CSSTransition>
                );
              })
            }
          </TransitionGroup>
        </UiListContainer>
      </UiListCheckedWrapper>
      {
        <CSSTransition
          in={isCheckedCheckboxFull}
          classNames='snackbar'
          timeout={1500}
          unmountOnExit
        >
          <UiSnackbarContainer>
            <span>選項已滿，最多6項</span>
          </UiSnackbarContainer>
        </CSSTransition>
      }
    </UiContentListChecked>
  );
};

export default ContentListChecked;

ContentListChecked.defaultProps = {
  onDelete: () => {},
};

ContentListChecked.propTypes = {
  checkedCheckbox: PropTypes.object,
  isCheckedCheckboxFull: PropTypes.bool,
  onDelete: PropTypes.func,
};