/**
 * 將國際電話轉為國內用的號碼
 * @param {string} internetPhone
 * @returns
 */
export function convertInternetPhoneToMobilePhone(internetPhone) {
  if (!internetPhone) return internetPhone;
  if (internetPhone.startsWith('0')) return internetPhone;
  const countryCodes = [ '+886', '+81' ];
  const countryCode = countryCodes.find(code => internetPhone.includes(code));
  const mobilePhone = internetPhone.replace(countryCode, 0);
  return mobilePhone;
}