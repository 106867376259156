import styled from 'styled-components';

export const UiSessionPerformance = styled.div`

`;

export const UiPerformanceTitle = styled.div`
	margin: 66px 0 48px;
	font-size: 25px;
	font-weight: 700;
`;