import styled from 'styled-components';

export const UiInput = styled('input')`
  display: none;
`;

export const UiTableContainer = styled('div')`
  display: block;
  max-height: 460px;
  overflow-y: auto;

  ::-webkit-scrollbar { width: 10px };
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;