import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SessionFormResourceModal from './SessionFormResourceModal';
import SessionFormTeacherModal from './SessionFormTeacherModal';

import { Button, Select } from 'components';
import { EDIT_STATUS } from 'constants/index';
import { format, getTime } from 'date-fns';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAlert } from 'utils/hooks/useAlert';
import { useClass } from 'store/class';
import { useSessions } from 'store/sessions';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import {
  UiActionsWrapper,
  UiButton,
  UiItem,
  UiItemsWrapper,
  UiMultipleSelectionWrapper,
  UiSessionForm,
  UiTitle,
  UiTitleWrapper,
} from './SessionForm.style';

const errorMsg = '本欄位為必填';
const sessionNameLabel = '課程名稱*';
const sessionTypeLabel = '課程類型*';
const sessionTypeOptions = [
  { name: '一般課程', value: 'normal' },
  { name: '視訊課程', value: 'video' },
  { name: '互動課程', value: 'interactive'},
  { name: '協作課程', value: 'collaborative'}
];
const sessionSubjectLabel = '科目*';
const sessionStartAtLabel = '上課時間*';
const sessionDurationLabel = '課程時長*';
const sessionDurationOptions = [
  { name: '15分', value: 15 },
  { name: '25分', value: 25 },
  { name: '30分', value: 30 },
  { name: '45分', value: 45 },
  { name: '50分', value: 50 },
  { name: '60分', value: 60 },
  { name: '90分', value: 90 },
  { name: '120分', value: 120 },
];
const buttonBackText = '返回';
const buttonSubmitText = '送出';

export const SessionForm = ({ editStatus }) => {
  const { setAlert }  = useAlert();
  const history = useHistory();
  const location = useLocation();
  const { state: locationState } = location;
  const { classId } = useParams();
  const [
    { subjects: { data: subjects }},
    { getSubjects, clearSubjects }
  ] = useClass();
  const [, { createOrganizationSession, updateOrganizationSession }] = useSessions();
  const [ isLoadedSessionData, setIsLoadedSessionData ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLoadingSubjects, setIsLoadingSubjects ] = useState(true);
  const [ startAt, setStartAt ] = useState(new Date());
  const [ isError, setIsError ] = useState({
    duration: false,
    hostId: false,
    name: false,
    resourceIdList: false,
    subjectCode: false,
  });
  const [ session, setSession ] = useState({
    duration: null,
    endAt: null,
    hostId: null,
    name: '',
    resourceIdList: [],
    sessionType: 'normal',
    subjectCode: null,
    timeSpanId: null,
    linkResources: [],
  });

  const isMmsCourse = !!locationState?.sessionMmsCourseId;
  const title = (editStatus === EDIT_STATUS.EDIT && '編輯課程') || (editStatus === EDIT_STATUS.COPY && '複製課程') || '新增課程';
  const sessionNameInputAdornment = `${session.name.length}/70`;

  const changeTeacherHandler = ({ id }) => setSession({ ...session, hostId: id });
  const changeResourcesHandler = (resourceIdList) => {
    isMmsCourse
      ? setSession({ ...session, linkResources: resourceIdList })
      : setSession({ ...session, resourceIdList });
  };
  const changeSessionHandler = (prop) => (value) => {
    setSession({ ...session, [prop]: value });
    setIsError({ ...isError, [prop]: !value});
  };

  /* 表單送出、檢查錯誤 */
  const fieldsErrorHandler = () => {
    !session.duration && setIsError((prevState) => ({ ...prevState, duration: true }));
    !session.name && setIsError((prevState) => ({ ...prevState, name: true }));
    !session.resourceIdList.length && setIsError((prevState) => ({ ...prevState, resourceIdList: true }));
    !session.subjectCode && setIsError((prevState) => ({ ...prevState, subjectCode: true }));
    !session.hostId && setIsError((prevState) => ({ ...prevState, hostId: true }));
  };
  const isCanSubmitHandler = () => {
    if(!session.duration) return false;
    else if(!session.name) return false;
    else if(!session.resourceIdList.length) return false;
    else if(!session.subjectCode) return false;
    else if(!session.hostId) return false;
    else return true;
  };
  const clickSubmitHandler = async () => {
    setIsLoading(true);
    if (isMmsCourse) {
      const data = {
        linkResources: session.linkResources,
      };
      const isSuccess = await updateOrganizationSession({ data });
      !isSuccess && setAlert('更新課程失敗', 'error');
    } else {
      fieldsErrorHandler();
      if(isCanSubmitHandler()){
        let isSuccess = null;
        const isEdit = (editStatus === EDIT_STATUS.EDIT);
        isEdit
          // 編輯課程頁面，更新課程資訊
          ? isSuccess = await updateOrganizationSession({ data: session })
          // 新增課程頁面，新增課程資訊
          : await createOrganizationSession({ data: session });
        // 編輯成功則返回
        (isEdit && isSuccess) && history.goBack();
      }else{
        setAlert('欄位有誤', 'warning');
      }
    }
    setIsLoading(false);
  };

  /* 計算下課時間 */
  const sessionEndAt = useMemo(() => {
    if(!session.duration) return;
    return getTime(startAt) + session.duration * 60000;
  }, [session.duration, startAt]);
  const sessionDurationHelperText = useMemo(() => {
    if(!sessionEndAt) return;
    return `下課時間 ${format(sessionEndAt, 'yyyy/MM/dd HH:mm')}`;
  }, [sessionEndAt]);
  useEffect(() => {
    setSession((prevState) => ({
      ...prevState,
      endAt: sessionEndAt,
      startAt: getTime(startAt),
    }));
  }, [sessionEndAt, startAt]);

  /* 編輯、複製課程時，載入該「課程科目」 */
  const loadSessionSubjectWhenCopyOrEdit = () => {
    const { sessionSubject } = locationState;
    const subjectCode = subjects.find(({ name }) => name === sessionSubject).value;
    setSession({ ...session, subjectCode });
  };
  useEffect(() => {
    if(!subjects.length || !isLoadedSessionData) return;
    const isCopyOrEdit = (editStatus === EDIT_STATUS.COPY) || (editStatus === EDIT_STATUS.EDIT);
    isCopyOrEdit && loadSessionSubjectWhenCopyOrEdit();
  }, [isLoadedSessionData, subjects]);

  /* 新建、複製、編輯課程時，打該班「科目API」 */
  const fetchSubjects = async () => {
    await getSubjects(classId);
    setIsLoadingSubjects(false);
  };
  useEffect(() => {
    fetchSubjects();
  }, [classId]);

  /* 編輯、複製課程時，載入該「課程資料」 */
  const loadSessionDataWhenCopyOrEdit = () => {
    const {
      sessionDuration,
      sessionHostId,
      sessionName,
      sessionResources,
      sessionStartAt,
      sessionTimeSpanId,
      sessionType,
      sessionLinkResources,
    } = locationState;
    setSession({
      ...session,
      duration: sessionDuration,
      hostId: sessionHostId,
      name: sessionName,
      resourceIdList: sessionResources,
      sessionType,
      timeSpanId: sessionTimeSpanId,
      linkResources: sessionLinkResources,
    });
    editStatus === EDIT_STATUS.EDIT && setStartAt(sessionStartAt);
    setIsLoadedSessionData(true);
  };
  const isLoadSessionData = useMemo(() => {
    const isCopyOrEdit = (editStatus === EDIT_STATUS.COPY) || (editStatus === EDIT_STATUS.EDIT);
    const isHaveLocationState = !!locationState;
    return (isCopyOrEdit && isHaveLocationState);
  }, [editStatus, locationState]);
  useEffect(() => {
    isLoadSessionData && loadSessionDataWhenCopyOrEdit();
    return () => {
      clearSubjects();
    };
  }, []);

  return (
    <UiSessionForm>
      <UiTitleWrapper>
        <UiTitle>{title}</UiTitle>
      </UiTitleWrapper>

      <UiItemsWrapper>
        {/* 課程名稱 */}
        <UiItem>
          <FormControl
            error={isError.name}
            fullWidth
            disabled={isMmsCourse}
          >
            <InputLabel>{sessionNameLabel}</InputLabel>
            <Input
              autoFocus={true}
              endAdornment={<InputAdornment position='end'>{sessionNameInputAdornment}</InputAdornment>}
              inputProps={{ maxLength: 70 }}
              onChange={(e)=>changeSessionHandler('name')(e.target.value)}
              value={session.name}
            />
            <FormHelperText>{isError.name && errorMsg}</FormHelperText>
          </FormControl>
        </UiItem>
        {/* 課程類型 */}
        <UiItem>
          <Select
            label={sessionTypeLabel}
            onChangeHandler={changeSessionHandler('sessionType')}
            options={sessionTypeOptions}
            value={session.sessionType}
            width='100%'
            disabled={isMmsCourse}
          />
        </UiItem>
        {/* 科目 */}
        <UiItem>
          <Select
            error={isError.subjectCode}
            helperText={isError.subjectCode && errorMsg}
            label={sessionSubjectLabel}
            loading={isLoadingSubjects}
            onChangeHandler={changeSessionHandler('subjectCode')}
            options={subjects}
            value={session.subjectCode}
            width='100%'
            disabled={isMmsCourse}
          />
        </UiItem>
        {/* 上課時間 */}
        <UiItem>
          <KeyboardDateTimePicker
            ampm={false}
            cancelLabel='取消'
            format='yyyy/MM/dd HH:mm'
            fullWidth
            inputVariant="outlined"
            label={sessionStartAtLabel}
            okLabel='確定'
            onChange={setStartAt}
            value={startAt}
            disabled={isMmsCourse}
          />
        </UiItem>
        {/* 課程時長 */}
        <UiItem>
          <Select
            error={isError.duration}
            helperText={
              isError.duration
                ? errorMsg
                : sessionDurationHelperText
            }
            label={sessionDurationLabel}
            onChangeHandler={changeSessionHandler('duration')}
            options={sessionDurationOptions}
            value={session.duration}
            width='100%'
            disabled={isMmsCourse}
          />
        </UiItem>
      </UiItemsWrapper>


      {!isMmsCourse &&
        <UiMultipleSelectionWrapper>
          <SessionFormTeacherModal
            editStatus={editStatus}
            error={isError.hostId}
            hostId={session.hostId}
            onChange={changeTeacherHandler}
            disabled={isMmsCourse}
          />
        </UiMultipleSelectionWrapper>}

      <UiMultipleSelectionWrapper>
        <SessionFormResourceModal
          editStatus={editStatus}
          error={isError.resourceIdList}
          onChange={changeResourcesHandler}
        />
      </UiMultipleSelectionWrapper>

      <UiActionsWrapper>
        <UiButton marginRight='10px'>
          <Button
            loading={isLoading}
            onClick={()=>history.goBack()}
          >
            {buttonBackText}
          </Button>
        </UiButton>
        <UiButton>
          <Button
            loading={isLoading}
            onClick={clickSubmitHandler}
          >
            {buttonSubmitText}
          </Button>
        </UiButton>
      </UiActionsWrapper>
    </UiSessionForm>
  );
};

SessionForm.propTypes = {
  editStatus: PropTypes.string,
  isOrganization: PropTypes.bool,
};