import { Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Button } from 'components';
import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

export const UpdateField = ({
  isLoading,
  sessionInProgressApiData,
  onFetchSession
}) => {
  const { organizationId } = useParams();
  const updatedTime =
    sessionInProgressApiData?.timestamp &&
    `上次更新時間：${format(sessionInProgressApiData.timestamp, 'HH:mm:ss')}`;
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        backgroundColor: '#fafafa'
      }}
    >
      <Box>
        <Button
          variant="contained"
          buttonColor="info"
          disabled={isLoading}
          onClick={() => onFetchSession({ organizationId })}
        >
          資訊更新
        </Button>
      </Box>
      <Box style={{ minHeight: '20px' }}>
        <Typography>{updatedTime}</Typography>
      </Box>
    </Box>
  );
};

UpdateField.propTypes = {
  isLoading: PropTypes.bool,
  sessionInProgressApiData: PropTypes.shape,
  onFetchSession: PropTypes.func
};
