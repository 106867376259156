import { Icon as Iconify } from '@iconify/react';
import { Box, Menu, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { UiMonitorUserMenuItem } from './MonitorUsersMenu.style';

/* 一位老師或學生進班狀況 */
const MonitorUserMenuItem = ({ user }) => {
  return user ? (
    <UiMonitorUserMenuItem>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Box style={{ display: 'flex' }}>
          <Iconify
            icon="material-symbols:circle"
            color={user.color}
            width="12"
          />
        </Box>
        <Box>
          <Typography style={{ color: '#212B36' }}>{user.name}</Typography>
        </Box>
      </Box>
      <Box>
        <Typography style={{ color: '#637381' }}>{user.statusText}</Typography>
      </Box>
    </UiMonitorUserMenuItem>
  ) : null;
};

export const MonitorUsersMenu = ({
  anchorEl,
  users,
  onClose
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => onClose(null)}
    >
      <Box
        onMouseLeave={() => onClose(null)}
        style={{ padding: '4px' }}
      >
        {users.map(user => {
          return <MonitorUserMenuItem key={user.id} user={user} />;
        })}
      </Box>
    </Menu>
  );
};

MonitorUsersMenu.propTypes = {
  anchorEl: PropTypes.element,
  users: PropTypes.array,
  onClose: PropTypes.func
};
