function isObject(object) {
  return !!object && typeof object === 'object';
}

export function isDeepEqual(obj1,obj2) {

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if(keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const isBothObjects = isObject(val1) && isObject(val2);

    // 如果都 不是 object 且 值互不相等
    if(!isBothObjects && val1 !== val2) return false;

    // 如果都是 object 且 值互不相等
    if(isBothObjects && !isDeepEqual(val1,val2)) return false;
  }

  return true;
}

