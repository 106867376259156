import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Icon, Loading } from 'components';
import { minute } from 'constants/index';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSessionsInProgress } from 'services/api/organization/session';
import { useAlert } from 'utils/hooks/useAlert';
import { v4 as uuidV4 } from 'uuid';
import { UiTableContainer } from './MonitorSessionTable.style';
import { MonitorCell } from './components/MonitorCell';
import { UpdateField } from './components/UpdateField';

const TABLE_HEAD_CELLS = [
  {
    ID: uuidV4(),
    LABEL: '課程時間'
  },
  {
    ID: uuidV4(),
    LABEL: '開課班級'
  },
  {
    ID: uuidV4(),
    LABEL: '導師'
  },
  {
    ID: uuidV4(),
    LABEL: '課程'
  },
  {
    ID: uuidV4(),
    LABEL: '教師'
  },
  {
    ID: uuidV4(),
    LABEL: '課程ID'
  },
  {
    ID: uuidV4(),
    LABEL: '更多操作'
  }
];
// 使用者的進班、未進班長數
const USER_STATUS = {
  PRESENCE: 'presence',
  ABSENCE: 'absence'
};
// 使用者的進班、未進班狀態
const MONITOR_STATUS = {
  [USER_STATUS.PRESENCE]: {
    label: '已進班',
    color: '#54D62C'
  },
  [USER_STATUS.ABSENCE]: {
    label: '未進班',
    color: '#FF4842'
  }
};

/**
 * 看課管理表格
 */
export const MonitorSessionTable = () => {
  const { organizationId } = useParams();
  const { setAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenSessionIdTooltips, setIsOpenSessionIdTooltips] = useState(false);
  const [sessionInProgressApiData, setSessionInProgressApiData] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalCounts: 0
  });
  const sessions = useMemo(() => {
    if (!sessionInProgressApiData) return [];
    const sessions = sessionInProgressApiData.sessions.map(session => {
      // 課程時間
      const duration = `${format(session.startAt, 'HH:mm')}~${format(
        session.endAt,
        'HH:mm'
      )}`;
      // 教師資訊
      const teachers = session.host?.id ? [
        {
          color: MONITOR_STATUS[session.host.status].color,
          statusText: MONITOR_STATUS[session.host.status].label,
          ...session.host,
        }
      ] : [];
      // 學生資訊
      const students = session.attendance?.length ? session.attendance.map(student => {
        return {
          color: MONITOR_STATUS[student.status].color,
          statusText: MONITOR_STATUS[student.status].label,
          ...student
        };
      }) : [];
      // 學生有無缺席
      const hasStudentAbsence = students.some(
        user => user.status === USER_STATUS.ABSENCE
      );
      // 班級資訊
      const classroomStatus = hasStudentAbsence
        ? MONITOR_STATUS[USER_STATUS.ABSENCE]
        : MONITOR_STATUS[USER_STATUS.PRESENCE];
      return {
        duration,
        teachers,
        students,
        classroomStatus,
        ...session
      };
    });
    return sessions;
  }, [sessionInProgressApiData]);
  /* 開關 課程名稱 tooltip */
  const toggleSessionIdTooltips = ({ isOpen, session, sessionIndex }) => {
    if (isOpen) {
      navigator.clipboard.writeText(session.id);
    }
    setIsOpenSessionIdTooltips(prev => {
      const newIsOpenSessionIdTooltips = prev.map(
        (prevIsOpen, index) => {
          const newIsOpenSessionIdTooltip =
            index === sessionIndex ? isOpen : prevIsOpen;
          return newIsOpenSessionIdTooltip;
        }
      );
      return newIsOpenSessionIdTooltips;
    });
  };
  /* click 觀課 */
  const onViewSession = session => {
    const url = `${
      process.env.REACT_APP_ONEBOARD_BS_DOMAIN
    }/player/${session.mmsCourseId || session.id}`;
    window.open(url);
  };
  /* 取得 看課管理 資料 */
  const fetchSessionInProgress = async ({ organizationId, params }) => {
    setIsLoading(true);
    const newParams = {
      page: pagination.page + 1,
      limit: pagination.rowsPerPage,
      ...params
    };
    const { status, data } = await getSessionsInProgress({
      organizationId,
      params: newParams
    });
    if (status === 'success') {
      const isOpenSessionIdTooltips = new Array(data.sessions.length).fill(
        false
      );
      setIsOpenSessionIdTooltips(isOpenSessionIdTooltips);
      setSessionInProgressApiData(data);
      setPagination(prev => ({ ...prev, totalCounts: data.totalCounts }));
    } else {
      setAlert('取得看課管理失敗', 'error');
    }
    setIsLoading(false);
  };
  /* 切上下頁 */
  const changePage = (event, newPage) => {
    const params = {
      page: newPage * pagination.rowsPerPage,
      limit: pagination.rowsPerPage
    };
    fetchSessionInProgress(params);
    setPagination(prev => ({
      ...prev,
      page: newPage
    }));
  };
  /* 改每頁列數 */
  const changeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const params = {
      page: 0,
      limit: rowsPerPage
    };
    fetchSessionInProgress(params);
    setPagination(prev => ({
      ...prev,
      page: 0,
      rowsPerPage
    }));
  };
  useEffect(() => {
    if (!organizationId) return;
    const interval = setInterval(() => {
      fetchSessionInProgress({ organizationId });
    }, 2 * minute);
    fetchSessionInProgress({ organizationId });
      return () => clearInterval(interval);
  }, [organizationId]);
  return (
    <UiTableContainer component={Paper}>
      <Box style={{ marginBottom: '8px' }}>
        <UpdateField
          isLoading={isLoading}
          sessionInProgressApiData={sessionInProgressApiData}
          onFetchSession={fetchSessionInProgress}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEAD_CELLS.map(cell => {
              return (
                <TableCell key={cell.ID} align="center" scope="column">
                  {cell.LABEL}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell
                colSpan={TABLE_HEAD_CELLS.length}
                style={{ textAlign: 'center' }}
              >
                <Loading />
              </TableCell>
            </TableRow>
          ) : sessions.length ? (
            sessions.map((session, index) => {
              return (
                <TableRow key={session.id}>
                  <TableCell component="th" scope="row" align="center">
                    {session.duration}
                  </TableCell>
                  <TableCell align="center">
                    <MonitorCell
                      color={session.classroomStatus.color}
                      users={session.students}
                      sessionIndex={index}
                    >
                      {session.groupName}
                    </MonitorCell>
                  </TableCell>
                  <TableCell align="center">{session.groupOwnerName}</TableCell>
                  <TableCell align="center">{session.name}</TableCell>
                  <TableCell align="center">
                    <MonitorCell
                      color={session.teachers?.[0].color}
                      users={session.teachers}
                      sessionIndex={index}
                    >
                      {session.teachers?.[0].name}
                    </MonitorCell>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      PopperProps={{
                        disablePortal: true
                      }}
                      open={isOpenSessionIdTooltips[index]}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="已複製"
                    >
                      <Typography
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          toggleSessionIdTooltips({
                            isOpen: true,
                            session,
                            sessionIndex: index
                          })
                        }
                        onMouseLeave={() =>
                          toggleSessionIdTooltips({
                            isOpen: false,
                            session,
                            sessionIndex: index
                          })
                        }
                      >
                        {session.id}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Icon
                      title="觀課"
                      name="visibility"
                      onClick={() => onViewSession(session)}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={TABLE_HEAD_CELLS.length}
                style={{ textAlign: 'center' }}
              >
                <Typography>沒有資料</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={pagination.totalCounts}
        labelRowsPerPage="每頁顯示"
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
      />
    </UiTableContainer>
  );
};
