import { Api } from 'services/api';

export const getWebResourceList = async organizationId => {
  const response = await Api.get(`/organizations/${organizationId}/webResources`);
  return response;
};

export const addWebResource = async (organizationId, params = {}) => {
  const response = await Api.post(`/organizations/${organizationId}/webResources`, params);
  return response;
};

export const updateWebResource = async (organizationId, webResourceId, params = {}) => {
  const response = await Api.patch(`/organizations/${organizationId}/webResources/${webResourceId}`, params);
  return response;
};

export const deleteWebResource = async (organizationId, webResourceId) => {
  const response = await Api.delete(`/organizations/${organizationId}/webResources/${webResourceId}`);
  return response;
};

export const addWebResourceData = async (organizationId, webResourceId, params = {}) => {
  const response = await Api.patch(`/organizations/${organizationId}/webResources/${webResourceId}/webResourceData`, params);
  return response;
};

export const updateWebResourceData = async (organizationId, webResourceId, webResourceDataId,  params = {}) => {
  const response = await Api.patch(`/organizations/${organizationId}/webResources/${webResourceId}/webResourceData/${webResourceDataId}`, params);
  return response;
};

export const deleteWebResourceData = async (organizationId, webResourceId, webResourceDataId) => {
  const response = await Api.delete(`/organizations/${organizationId}/webResources/${webResourceId}/webResourceData/${webResourceDataId}`);
  return response;
};


