import styled from 'styled-components';

export const UiOptionMenu = styled.div`
	@keyframes slider{
		0%{
			opacity: 0;
			transform: translate(-50px, 20px);
		};
		100%{
			opacity: 1;
			transform: translate(-50px, 30px);
		}
	};

	position: absolute;
    z-index: 100;
	transform: translate(-50px, 30px);
	background-color: #FFF;
	white-space: nowrap;
	padding: 4px 0;
	animation-name: slider;
	animation-duration: 0.3s;
`;

export const UiOption = styled.div`
	cursor: pointer;
	font-size: 0.93333rem;
	padding: 5px 12px;

	&:hover{
		background-color: #F5F5F5;
	}
`;