import React from 'react';
import PropTypes from 'prop-types';

import { Header, Footer } from 'components';
import { COMPANY_TITLE } from 'constants/index';
import { UiSingleLayout, UiSingleContent } from './SingleLayout.style';


/**
 * 沒有側邊欄的版型
 */

 const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;


export const SingleLayout = ({ children }) => {
  return (
    <UiSingleLayout>
      <Header />
      <UiSingleContent>
        {children}
      </UiSingleContent>
      {
        isCompany &&
          <Footer />
      }
    </UiSingleLayout>
  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
};


