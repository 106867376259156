import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { EDIT_STATUS, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { Table, IconInput, Select, Icon, Button, UploadButtonExcel } from 'components';
import { UiAvatar } from './TeacherManagementTable.style';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useOrganization } from 'store/organization';
import { useTeacher } from 'store/teacher';
import { useUser } from 'store/user';
import { getCheckOneClubId as getCheckOneClubIdApi } from 'services/api/oneClub';
import {
  getTeachers as getTeachersApi,
  createTeachInfo as createTeacherInfoApi
} from 'services/api/organization/teacher';
import { PromiseQueue } from 'utils/promiseQueue';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * 教師管理列表表單
 */

const schema = {
  nickname: {
    name: '機構暱稱',
    defaultValue: '',
  },
  originalName: {
    name: '姓名',
    defaultValue: '',
  },
  originalNickname: {
    name: '暱稱',
    defaultValue: '',
  },
  avatar: {
    name: '照片',
    defaultValue: ''
  },
  mobileNumber: {
    name: '聯絡電話',
    defaultValue: '',
  },
  role: {
    name: '權限',
    defaultValue: ''
  },
};

const stateShowText = [
  {
    id: 'role',
    state: {
      staff: '教師',
      owner: '機構管理者',
    }
  }
];

const stateOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];

export const TeacherManagementTable = () => {
  const { organizationId } = useParams();
  const history = useHistory();
  const { setAlert } = useAlert();
  // store teacher
  const [{ orgs: { dataInfo } }, { getOrgInfo }] = useOrganization();
  const [{ teachers: { data, total } }, { getTeachers }] = useTeacher();
  const [{ nowPage, rowsPage, searchKey, searchValue, tableData }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    searchKey: '',
    searchValue: '',
    tableData: [],
  });

  // store user
  const [
    { myOrganization, profile }
  ] = useUser();
  const { organization } = myOrganization;
  const ownerId = organization.ownerId;
  const isOwnerId = profile.id === ownerId;

  /* 驗證 id 是否存在於 oneClub */
  const validateIdExistsOneClub = async (body, setViewData) => {
    let isError = false;
    const tasks = body.map(row => {
      const id = row[0];
      return getCheckOneClubIdApi(id);
    });
    const responses = await Promise.all(tasks);
    const result = body.map((item, index) => {
      const isUserExist = responses[index].status === 'success' && responses[index]?.data?.status === 'failure' && responses[index]?.data?.error?.errorCode === 20115;
      const message = isUserExist ? '檢查中...' : '帳號不存在OneClub或其他錯誤';
      return [message, item[0], item[1]];
    });
    if (result.some(item => item[0] !== '檢查中...')) isError = true;
    setViewData(result);
    return isError;
  };
  /* 驗證 id 是否存在於機構中 */
  const validateIdExistsOrganization = async (body, setViewData) => {
    let isError = false;

    const tasks = body.map(row => {
      const id = row[0];
      return getTeachersApi()(organizationId)({ targetUserId: id });
    });
    const responses = await Promise.all(tasks);
    const result = body.map((item, index) => {
      const messages = [];
      const isUserExist = (responses[index].status === 'success' && responses[index]?.data?.total > 0);
      const otherError = (responses[index].status === 'failure' && responses[index]?.error?.errorCode !== 10001);
      isUserExist && messages.push('帳號已存在機構中');
      otherError && messages.push('有其他的錯誤');
      const message = messages.length > 0 ? messages.join(',') : '驗證成功';
      return [message, item[0], item[1]];
    });
    if (result.some(item => item[0] !== '驗證成功')) isError = true;
    setViewData(result);
    return isError;
  };
  /* 驗證 id 是否重複 */
  const validateIdRepeat = (body, setViewData) => {
    let isRepeat = false;
    let result = [];
    body.some((row, i) => {
      let data = null;
      const id_i = row[0];
      const name = row[1];
      body.some((row, j) => {
        const id_j = row[0];
        if (id_i === id_j && i !== j) isRepeat = true;
        return isRepeat;
      });
      isRepeat
        ? data = ['帳號重複', id_i, name]
        : data = ['檢查中...', id_i, name];
      result.push(data);
      return isRepeat;
    });
    setViewData(result);
    return isRepeat;
  };
  /* 驗證 id 格式 */
  const validateIdFormat = (body, setViewData) => {
    let isCorrect = true;
    let result = [];
    const rules = /^[\w]+$/; // 英文 or 數字 or 英數字
    body.every((row) => {
      const id = row[0];
      const name = row[1];
      isCorrect = rules.test(id);
      if (isCorrect) {
        const data = ['檢查中...', id, name];
        result.push(data);
      } else {
        const data = ['帳號格式錯誤', id, name];
        result.push(data);
      }
      return isCorrect;
    });
    setViewData(result);
    return !isCorrect;
  };
  /* 驗證 excel 格式 */
  const validateExcel = async (body, setViewData) => {
    let isError = false;
    isError = validateIdFormat(body, setViewData);
    if (isError) return isError;
    isError = validateIdRepeat(body, setViewData);
    if (isError) return isError;
    isError = await validateIdExistsOneClub(body, setViewData);
    if (isError) return isError;
    isError = await validateIdExistsOrganization(body, setViewData);
    return isError;
  };
  // table 操作
  const ActionComponents = params => {
    const teacherId = params.params.id;
    // 判斷此行是不是『機構管理者』
    const teacherIsOwner = organization.ownerId === teacherId;

    return (
      <>
        {
          teacherIsOwner ? (isOwnerId ? <Icon
            onClick={() =>
              history.push(
                `/organization/${organizationId}/teachers/${teacherId}/${EDIT_STATUS.EDIT}`
              )}
            name='edit'
          /> : '')
            : <Icon
              onClick={() =>
                history.push(
                  `/organization/${organizationId}/teachers/${teacherId}/${EDIT_STATUS.EDIT}`
                )}
              name='edit'
            />
        }
      </>
    );
  };


  /* 建立教師資料完成後動作 */
  const createTeacherInfoAfterComplete = (tableData, setViewData, setIsLoading, setIsCompleteImport) =>(isStopped, complete, errors, total) => {
    const viewData = [];
    for (let i = 0; i < total; i++) {
      const data = tableData[i];
      const row = [data.id, data.nickname];
      if (errors.length && complete.length === i) {
        // 有錯誤且已將完成的資料放進 viewData
        row.unshift('匯入失敗');
        viewData.push(row);
        break;
      } else if (errors.length) {
        // 第一筆資料即有錯誤
        row.unshift('匯入失敗');
        viewData.push(row);
        break;
      }
      else if (complete.length && complete.length > i) {
        // 無錯誤，且每次只能 set 一筆進 table
        row.unshift('匯入成功');
        viewData.push(row);
      }
    }
    setViewData(viewData);
    if ((complete.length + errors.length === total) || isStopped) {
      setIsCompleteImport(true);
      setIsLoading(false);
    } 
  };
  /* 匯入教師彈窗 - 匯入 func */
  const joinTeacherIntoOrganization = (tableData, setViewData, setIsLoading, setIsCompleteImport) => {
    try {
      setIsLoading(true);
      const tasks = tableData.map(data => {
        const payload = {
          staffId: data.id,
          permissionGroups: [],
          nickname: data.nickname,
          thumbnailUrl: ''
        };
        return async () => createTeacherInfoApi(organizationId)(payload);
      });
      const promiseQueue = new PromiseQueue(tasks, 1);
      promiseQueue.run(createTeacherInfoAfterComplete(tableData, setViewData, setIsLoading, setIsCompleteImport), true);  
    } catch (error) {
      setIsLoading(false);
      setAlert(error, 'error');
    }
  };
  /* 匯入教師彈窗 - 完成匯入後 func */
  const handleAfterImport = () => {
    const params = {
      nowPage,
      rowsPage,
      [searchKey]: searchValue
    };
    getTeachers(params);
  };


  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const submitSelectHandler = value => {
    setState({
      searchKey: value,
      nowPage: 0
    });
  };

  const submitInputHandler = value => {
    if (!searchKey) {
      alert('請先選擇搜尋目標');
      return;
    }
    setState({
      searchValue: value,
      nowPage: 0
    });
  };

  useEffect(() => {
    const params = {
      nowPage,
      rowsPage,
      [searchKey]: searchValue
    };
    getTeachers(params);
  }, [nowPage, rowsPage, searchValue]);

  useEffect(() => {
    const nextData = data.map(item => {
      if (item.id === ownerId) {
        item.role = 'owner';
      }
      return {
        ...item,
        avatar: <UiAvatar alt={item.nickname} src={item.thumbnailUrl} />,
      };
    });

    setState({
      tableData: nextData,
    });
  }, [data]);

  useEffect(() => {
    getOrgInfo();
  }, []);

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Select label="搜尋目標" options={stateOptions} submitHandler={submitSelectHandler} />
          <IconInput placeholder="搜尋" onChange={submitInputHandler} />
        </UiflexBox>
        <UiflexBox>
          {
            dataInfo?.organizationImportTeachersSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW &&
              <UploadButtonExcel
                validateExcel={validateExcel}
                handleJoin={joinTeacherIntoOrganization}
                handleAfterImport={handleAfterImport}>
                匯入教師
              </UploadButtonExcel>
          }
          <Button
            buttonColor='highlight'
            icon='add'
            onClick={
              () => { history.push(`/organization/${organizationId}/teachers/${EDIT_STATUS.CREATE}`); }
            }>新增教師</Button>
        </UiflexBox>
      </UiActionBox>
      <Table
        data={tableData}
        schema={schema}
        stateShowText={stateShowText}
        changePage_Rows={changePage_Rows}
        totalPage={total}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
    </UiTable>
  );
};



