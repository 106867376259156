import React, { useEffect, useState, } from 'react';
import PropTypes from 'prop-types';
import defaultImage from 'assets/images/teachingMaterials.png';

import {
  BsModal,
  Loading,
  RadioGroup,
} from 'components';
import { getSessionResourcesById as getSessionResourceByIdApi } from 'services/api/organization/resources';
import {
  UiModal,
  UiFigureContainer
} from './ResourceModal.style';

export const ResourceModal = ({
  session,
  isOpenResourceModal,
  onCancel,
  onOk,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValue, setDefaultValue] = useState(null);
  const [resourceData, setResourceData] = useState([]);     // 課程教材內容 { label, value }
  const [resourceValue, setResourceValue] = useState(null); // radioGroup 選到的 value
  const isMmsCourse = !!session?.mmsCourseId;

  const createResourceData = (resources) => {
    const newResourceData = resources.map((resource) => {
      return {
        label: resource.displayName,
        value: resource.resourceId,
      };
    });
    setResourceData(newResourceData);
    setDefaultValue(newResourceData?.[0]?.value);
    setResourceValue(newResourceData?.[0]?.value);
  };

  /* change RadioGroup */
  const changeRadioGroupHandler = (e) => {
    setResourceValue(e.target.value);
  };

  /* click Modal 確認按鈕 */
  const clickModalOkHandler = () => {
    onOk(resourceValue);
  };

  /* 打API，取得課程資源 */
  const fetchData = async () => {
    setIsLoading(true);
    const { status, data } = await getSessionResourceByIdApi(session.id);
    if(status === 'success') {
      createResourceData(data);
    }
    setIsLoading(false);
  };

  /* 建立 MMS 課程之 resourceData */
  const createResourceDataForMMS = () => {
    const { resourceObject } = session;
    createResourceData(resourceObject);
    setIsLoading(false);
  };

  useEffect(() => {
    isMmsCourse ? createResourceDataForMMS() : fetchData();
  }, [session]);

  return (
    <BsModal
      title='選擇課程教材'
      name='prompt'
      isFull={true}
      open={isOpenResourceModal}
      okDisabled={resourceData.length === 0}
      onCancel={onCancel}
      onOk={clickModalOkHandler} >
      {
        isLoading
          ? <Loading />
          : resourceData.length > 0
              ? <RadioGroup
                  defaultValue={defaultValue}
                  data={resourceData}
                  row={false}
                  onChange={changeRadioGroupHandler}
                />
              : <UiModal>
                  <UiFigureContainer>
                    <img alt='無課程教材' src={defaultImage} />
                    <figcaption>目前尚無課程教材</figcaption>
                  </UiFigureContainer>
                </UiModal>
      }
    </BsModal>
  );
};

ResourceModal.propTypes = {
  session: PropTypes.shape,
  isOpenResourceModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};