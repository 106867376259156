import React from 'react';
import { Route } from 'react-router-dom';


const AuthGuard = ({ children, ...props }) => {
  const { history, location, name  } = props;
  if(name === 'organizationSessionFormPageCopy' && !location.state) history.push('/');
  else if(name === 'organizationSessionFormPageEdit' && !location.state) history.push('/');
  return children;
};

const RouteWithSubRoutes = route => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props =>
        <AuthGuard {...props} {...route}>
          <route.component {...props} {...route} routes={route.routes} />
        </AuthGuard>
      }
    />
  );
};

const RenderRoutes = ({ routes = [] }) => (
  <>
    {
      routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </>
);


export default RenderRoutes;
