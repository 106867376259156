import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getWebResourceList, addWebResource, addWebResourceData, updateWebResource, updateWebResourceData, deleteWebResource, deleteWebResourceData } from 'services/api/organization/webResource';
import { Table, Button, Icon, BsModal, OptionMenu } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';
import { UiTable, UiTableHeader, UiTableHeaderTitle, UiTipBox, UiIcon} from './WebResource.style';
import { UiActionBox, UiflexBox } from 'styles';
import { validURL, isExist } from 'utils/helper';
import WebResourceModalContent from './WebResourceModalContent';
import noWebResourceImage from 'assets/images/noWebResource.svg';

/**
 *  常用資源
 */
const ModalType = {
  Type: '類別',
  Resource: '資源'
};

const Action = {
  Add: '新增',
  Edit: '編輯',
  Delete: '刪除'
};

const schema = {
  name: {
    name: '資源名稱',
    defaultValue: '',
  },
  url:{
    name:'網址',
    defaultValue: '',
  },
};

export const WebResource = () => {
  const { organizationId } = useParams();
  const { setAlert } = useAlert();
  const [resources, setResources] = useState([]);
  const [valid, setValid] = useSetState({
    isURLPattern: true,
    isTextNotEmptyPattern: true
  });

  const [{ type, action, modalOpened }, setModal]  = useSetState({
    type: '',
    action: '',
    modalOpened: false
  });
  const [inputs, setInputs] = useSetState({
    newTypeName: '',
    newResourceName: '',
    newResourceURL: '',
    editedTypeName: '',
    editedResourceName: '',
    editedResourceURL: ''
  });
  const [currentData, setCurrentData] = useSetState({
    resource: {
      id: '',
      title: ''
    },
    resourceData: {
      id: '',
      name: '',
      url: ''
    }
  });
  const [{ menuWebResourceId , isOptionMenuOpen} , setOptionMenu] = useSetState({
    menuWebResourceId: '',
    isOptionMenuOpen: false
  });
  const optionMenuData = [
    {
      label: '編輯類別名稱',
      onClick: () => openModal(ModalType.Type, Action.Edit)
    },
    {
      label: '刪除類別',
      onClick: () => openModal(ModalType.Type, Action.Delete)
    },
  ];
  const fetchWebResourceList = async() => {
    try {
      const { isSuccess, data} = await getWebResourceList(organizationId);
      if (isSuccess) setResources([...data]);
    } catch (error) {
      setAlert(error, 'error');
    }
  };
  const checkURLPattern = (value) => {
    const isURLPattern = validURL(value);
    setValid({
      isURLPattern: isURLPattern
    });
    return isURLPattern;
  };

  const checkNotEmpty = (value) => {
    const isTextNotEmptyPattern = isExist(value);
    setValid({
      isTextNotEmptyPattern: isTextNotEmptyPattern
    });
    return isTextNotEmptyPattern;
  }; 
  const openModal = (type, action, webResourceId, webResourceDataId) => {
    if(webResourceId && resources.length > 0) {
      const resource = resources.find(item => item.id === webResourceId);
      const data = {};
      const inputs = {};
      data.resource = resource;
      inputs.editedTypeName = resource.title;
      if(webResourceDataId) {
        const resourceData = resource.webResourceData.find(item => item.id === webResourceDataId);
        data.resourceData = resourceData;
        inputs.editedResourceName = resourceData.name;
        inputs.editedResourceURL =  resourceData.url;
      }
      setCurrentData(data);
      setInputs(inputs);
    }
    setModal({ type, action, modalOpened: true});
  };
  const closeModal = (type, action) => {
    setModal({ type, action, modalOpened: false});
    setValid({ isURLPattern: true, isTextNotEmptyPattern: true});
  };
  const doAction = async (type, action) => {
    try {
      let response = null;
      if(type === ModalType.Type && action === Action.Add) {
        if(!checkNotEmpty(inputs.newTypeName)) return;
        response = await addWebResource(organizationId, { title: inputs.newTypeName });
      }
      if(type === ModalType.Resource && action === Action.Add ) {
        if(!checkNotEmpty(inputs.newResourceName) ||!checkNotEmpty(inputs.newResourceURL) || !checkURLPattern(inputs.newResourceURL)) return;
        response = await addWebResourceData(organizationId, currentData.resource.id, { name: inputs.newResourceName , url: inputs.newResourceURL});
      }
      if(type === ModalType.Type && action === Action.Edit) {
        if(!checkNotEmpty(inputs.editedTypeName)) return;
        response = await updateWebResource(organizationId, currentData.resource.id, { title: inputs.editedTypeName });
      }
      if(type === ModalType.Resource && action === Action.Edit ) {
        if(!checkNotEmpty(inputs.editedResourceName) ||!checkNotEmpty(inputs.editedResourceURL) ||!checkURLPattern(inputs.editedResourceURL)) return;
        response = await updateWebResourceData(organizationId, currentData.resource.id, currentData.resourceData.id,{ name: inputs.editedResourceName , url: inputs.editedResourceURL});
      }
      if(type === ModalType.Type && action === Action.Delete) {
        response = await deleteWebResource(organizationId, currentData.resource.id);
      }
      if(type === ModalType.Resource && action === Action.Delete ) {
        response = await deleteWebResourceData(organizationId, currentData.resource.id, currentData.resourceData.id);
      }
      const { isSuccess, error } = response;
      if(isSuccess) {
        setAlert(`${action}${type}成功!`, 'success');
        fetchWebResourceList();
      } else {
        setAlert(error.description || error.message, 'error');
      }
    } catch(error) {
      setAlert(error, 'error');
    }
    setInputs({
      newTypeName: '',
      editedTypeName: '',
      newResourceName: '',
      editedResourceName: '',
      newResourceURL: '',
      editedResourceURL: ''
    });
    closeModal(type, action); 
  };
  const optionMenuToggle = (webResourceId) => {
    const title = resources.find(item => item.id === webResourceId).title;
    setCurrentData({ 
      resource: {
        id: webResourceId,
        title: title
      }
    });
    setInputs({ 
      editedTypeName: title
    });
    setOptionMenu({ 
      isOptionMenuOpen: !isOptionMenuOpen,
      menuWebResourceId: webResourceId,
    });
  };

  const ActionComponents = ({ params: { webResourceId, id: webResourceDataId } }) => {
    return (
      <UiIcon>
        <Icon 
          name="edit" 
          title="編輯資源"
          onClick={() => openModal(ModalType.Resource, Action.Edit, webResourceId, webResourceDataId)} />
        <Icon
          name="delete"
          title="刪除資源"
          type='danger'
          onClick={() => openModal(ModalType.Resource, Action.Delete, webResourceId, webResourceDataId)} />
      </UiIcon>
    );
  };

  useEffect(() => {
    if(resources.length === 0) {
      fetchWebResourceList();
    }
  }, []);

  const resourcesList = useMemo(() => {
    return resources
            .map(item => ({...item, webResourceData: item.webResourceData.sort((a, b) => {
                if(!a.createdAt || !b.createdAt) return 0;
                return new Date(a.createdAt) - new Date(b.createdAt);
              })}))
            .sort((a, b) =>{
              if(!a.createdAt || !b.createdAt) return 0;
              return new Date(a.createdAt) - new Date(b.createdAt);
            });
  }, [resources]);

  const isDeleteTypeModal = useMemo(() => {
    if(type === ModalType.Type && action === Action.Delete) {
      return true;
    }else {
      return false;
    }
  }, [type, action, currentData.resource]);
  return (
    <>
      <UiActionBox>
        <UiflexBox>
          <Button
            buttonColor='highlight'
            icon='add'
            onClick={() => openModal(ModalType.Type, Action.Add)}>
              新增類別
          </Button>
        </UiflexBox>
      </UiActionBox>
      { resourcesList?.length === 0 ? 
        <UiTipBox>
          <img src={noWebResourceImage} alt={noWebResourceImage} />
          <p>尚無資料</p>
        </UiTipBox>
        :
        resourcesList.map(item => (
          <UiTable key={item.id}>
            <UiTableHeader>
                <UiTableHeaderTitle>
                  { item.title }
                </UiTableHeaderTitle>
                <UiActionBox>
                <Button
                  icon='add'
                  onClick={() => openModal(ModalType.Resource, Action.Add, item.id)}
                >新增資源</Button>
                <UiIcon>
                  <Icon
                    title="更多"
                    name="moreVert"
                    haveBg={false}
                    icolor="#242C3F"
                    onClick={() => optionMenuToggle(item.id)}
                  />
                        {
                          (
                            menuWebResourceId === item.id
                            && isOptionMenuOpen
                          ) 
                          &&
                            <OptionMenu
                              options={optionMenuData}
                              onClose={() => optionMenuToggle(item.id)}
                            />
                      }
                  </UiIcon>
                </UiActionBox>
            </UiTableHeader>
            { item.webResourceData.length > 0 && 
              <Table
                data={item.webResourceData.map(data => ({...data, webResourceId: item.id }))}
                schema={schema}
                totalPage={item.webResourceData.length}
                ActionComponents={ActionComponents}
                isNeedPagination={false}
              />
            }
          </UiTable>
        ))
      } 
      <BsModal
        open={modalOpened}
        title={`${action + type}${isDeleteTypeModal ? `:「${currentData.resource.title}」`: ''}`}
        isFull={true}
        okDisplay={true}
        cancelDisplay={true}
        onOk={() => doAction(type, action)}
        onCancel={() => closeModal(type, action)}
      >
        <WebResourceModalContent 
          type={type}
          action={action} 
          ModalType={ModalType}
          Action={Action}
          currentData={currentData}
          valid={valid}
          inputs={inputs} 
          setInputs={setInputs}/>
      </BsModal>
    </>);
};

WebResource.propTypes = {

};


