import React from 'react';
// import PropTypes from 'prop-types';
import { BsHeaderTitle, SessionReport } from 'components';
import { UiSessionReportPage } from './SessionReportPage.style';


/**
 * 課堂報告頁面
 */

export const SessionReportPage = () => {
  return (
    <>
      <BsHeaderTitle title={'課堂報告'} />
      <UiSessionReportPage>
        <SessionReport />
      </UiSessionReportPage>
    </>
  );
};

SessionReportPage.propTypes = {

};


