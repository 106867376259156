import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { BsHeaderTab } from 'components';


/**
 * 科目權限 tabs
 */

export const BsSubjectPermissionManagementPageHeaderTab = ({ activeIndex = 0 }) => {
  const { organizationId } = useParams();
  const history = useHistory();
  const goPath = path => history.push(path);

  const tabData = [
    { label: '老師權限', onClick: () => goPath(`/organization/${organizationId}/subjectPermission/staff`) },
    { label: '學生權限', onClick: () => goPath(`/organization/${organizationId}/subjectPermission/customer`) }
  ];
  return (
    <BsHeaderTab data={tabData} activeIndex={activeIndex} theme="primary" />
  );
};

BsSubjectPermissionManagementPageHeaderTab.propTypes = {
  activeIndex: PropTypes.number
};
