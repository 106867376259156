
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';

import theme from 'theme/backstageTheme';
import RenderRoutes from 'routes/RenderRoutes';
import { useSetState } from 'utils/hooks/useSetState';
import { useUser } from 'store/user';
import { BsHeader, BsNavigator } from 'components';
import { UiBsLayout, UiContent, UiDrawer } from './BsLayout.style';


/**
 * backstage layout
 */

const drawerWidth = 256;

export const BsLayout = ({ routes }) => {
  const { organizationId } = useParams();
  const [{ myOrganization }, { fetchOrg }] = useUser();
  const [{ mobileOpen }, setState] = useSetState({ mobileOpen: false });

  const handleDrawerToggle = () => {
    setState({ mobileOpen: !mobileOpen });
  };

  useEffect(() => { 
    fetchOrg();
  },[organizationId]);

  return myOrganization.isLoaded && (
    <ThemeProvider theme={theme}>
      <UiBsLayout>
        <UiDrawer width={drawerWidth}>
          <Hidden smUp implementation="js">
            <BsNavigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <BsNavigator PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </UiDrawer>
        <UiContent drawerWidth={drawerWidth}>
          <BsHeader onDrawerToggle={handleDrawerToggle} />
          <RenderRoutes routes={routes} />
        </UiContent>
      </UiBsLayout>
    </ThemeProvider>
  );
};

BsLayout.propTypes = {

};


