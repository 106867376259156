import styled from 'styled-components';

export const UiInviteClassCode = styled.div`
	display: flex;
	align-items: center;
`;

export const UiSvg = styled.div`
	line-height: 0;

	& svg {
		cursor: pointer;
		margin-top: 5px;
		margin-left: 5px;
		font-size: 2rem;
	}
`;

export const UiInviteClassCodeDate = styled.div`
	color: #8b90a0;
`;

export const UiHiddenInput = styled.div`
	height: 0;
	opacity: 0;
`;