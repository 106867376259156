import styled from 'styled-components';
import { Checkbox as MatCheckbox } from '@material-ui/core';

export const UiContentListAll = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-y: hidden;
`;

export const UiEmpty = styled.div`
  align-self: center;
  font-size: 1rem;
`;

export const UiTitleContainer = styled.div`
  color: #1D4A60;
  font-family: Noto Sans CJK TC;
  font-size: 0.93rem;
  padding-bottom: 7px;
`;

export const UiListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar{ width: 10px };
  ::-webkit-scrollbar-thumb{
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const UiCheckboxContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 12px; 
`;


export const UiMatCheckbox = styled(MatCheckbox)`
  /* .cvDofJ.Mui-checked */
  &.Mui-checked {
		color: #4C99A5;
	}
`;