import styled from 'styled-components';

export const UiSecretDiscuss = styled.div`
	display: block;
`;
export const UiTitle = styled.div`
	font-size: 1.25rem;
	font-weight: bolder;
	color: #163e6f;
	margin-bottom: 1.5rem;
`;
export const UiButtonBox = styled.div`
	button {
		margin: auto;
		width: 45%;
		font-size: 1rem;
	}
`;
export const UiContent = styled.div`
	display: flex;
	align-items: center;
	height: 400px;
	text-align: center;

	& > div {
		margin: auto;
	}
`;
export const UiCardBox = styled.div`
	margin: 1rem 0;
`;

export const UiModal = styled.div`
	& > div {
		margin: 1rem 0;
	}
`;

export const UiModalInput = styled.div`
	margin: 1rem 0;

	& > div {
		width: 100%;
	}
`;
