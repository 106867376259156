import libphonenumber from 'google-libphonenumber';

export const phoneNumberValid = (number) => {//+886987654321
  let isValidNumber = false;
  if (number.length < 6) return isValidNumber;

  try {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const parseNumber = phoneUtil.parse(number);
    isValidNumber = phoneUtil.isValidNumber(parseNumber);
  } catch(e) {
    return isValidNumber;
  }
  return isValidNumber;
};
export const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;


export const taiwanPhoneValid = (number) => {  // 09xxxxxxxx
  const phoneReg = /09\d{2}\d{3}\d{3}/;
  return phoneReg.test(number);
};