import { BsModal } from 'components';
import { EDUCATION_LEVEL } from 'constants/index';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from 'utils/hooks/useAlert';
import { updateEducations } from 'services/api/organization/organizations';
import { useUser } from 'store/user';

export const ConfirmModal = ({ isOpen, users, onToggle }) => {
  const { setAlert } = useAlert();
  const { organizationId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [{ myOrganization }] = useUser();
  const educationNames = myOrganization.organization.educationNames;
  const hasElementary = educationNames.includes(EDUCATION_LEVEL.ELEMENTARY);
  const hasJuniorHigh = educationNames.includes(EDUCATION_LEVEL.JUNIOR);
  const hasHigh = educationNames.includes(EDUCATION_LEVEL.SENIOR);

  /* 儲存 */
  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const settings = users.map(user => {
        const userId = user.id;
        const elementarySubject = {
          id: EDUCATION_LEVEL.ELEMENTARY,
          subjects: user.elementarySubjects
        };
        const juniorHighSubject = {
          id: EDUCATION_LEVEL.JUNIOR,
          subjects: user.juniorHighSubjects
        };
        const highSubject = {
          id: EDUCATION_LEVEL.SENIOR,
          subjects: user.highSubjects
        };
        const settings = [];
        hasElementary && settings.push(elementarySubject);
        hasJuniorHigh && settings.push(juniorHighSubject);
        hasHigh && settings.push(highSubject);
        return {
          userId,
          settings
        };
      });
      const params = { settings };
      const { status, error } = await updateEducations(organizationId, params);
      if (status === 'success') {
        onToggle();
        setAlert('更新科目權限成功', 'success');
      } else {
        throw new Error(error?.message);
      }
    } catch (error) {
      setAlert(error || '更新科目權限失敗', 'error');
    }
    setIsLoading(false);
  };

  return (
    <BsModal
      title={'是否確定修改'}
      open={isOpen}
      isLoading={isLoading}
      okText={'確認'}
      onOk={onSubmit}
      cancelText={'取消'}
      onCancel={onToggle}
      isFull={true}
      uiBsModalPadding={'48px 60px 20px'}
    >
      是否確定修改權限設定？新的權限設定將覆蓋原有設定，所以資料送出後，動作將不會復原，若要修改請按確認鍵。
    </BsModal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  users: PropTypes.array,
  onToggle: PropTypes.func
};
