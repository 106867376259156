import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TransferList from './TransferList';
import { UiTransferListModal, UiFormHelperText, UiErrorMsg, UiSearchBox } from './TransferListModal.style';
import { Button, Modal, IconInput, Select } from 'components';
import { EDIT_STATUS } from 'constants/index';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { getSession as getSessionApi } from 'services/api/home/sessions';
import { getOrganizationStaffs as getOrganizationStaffsApi } from 'services/api/organization/teacher';


/**
 * 選擇列表彈窗with 按鈕
 */

export const TransferListModal = ({
  type,
  title,
  buttonName,
  data,
  onChange = () => { },
  search,
  value,
  helperText,
  error,
  isPreson = true,
  resetData = () => { },
  errorMsg = '',
  children,
  tagContent,
  tabs,
  isShowSearch = false,
  showText,
  selectOptions = [],
  onSearchChange = () => {},
  dataTotal,
  onScrollToApi,
  getCurrentValue = () => {},
  editStatus,
  isDataLoading = false,
  disabled,
}) => {
  const isHome = Boolean(useRouteMatch({ path: '/home' }));
  const { classId, organizationId, sessionId } = useParams();
  const { setAlert } = useAlert();
  const [{ changeModalState, choice, isLoading, isDisabled, searchValue }, setState] =
    useSetState({
      changeModalState: false,
      choice: [],
      isLoading: false,
      isDisabled: disabled,
      searchValue: '',
    });

  const getModalStateHandler = state => {
    setState({
      changeModalState: state
    });
  };

  const getValueHandler = value => {
    closeHandler();
    if (!Array.isArray(value)) {
      setState({
        choice: [value]
      });
    }
    onChange && onChange(value);
  };

  const closeHandler = () => {
    resetData && resetData();
    setState({
      changeModalState: false
    });
  };

  const onSubmitHandler = value => {
    search && search.onSearchHandler(value);
  };

  // useEffect(() => {
  //   setState({ isLoading: true });

  //   // 判斷 data 是否有值
  //   if (data.length === 0) {
  //     setState({
  //       isLoading: false,
  //     });
  //     return;
  //   }

  //   const nextData = data.filter(item => {
  //     if (item.id === value) {
  //       return item.title;
  //     }
  //   });
  //   setState({
  //     // choice: nextData,
  //     isLoading: false,
  //     isDisabled: false,
  //   });
  // }, [data]);

  const radioChangeHandler = (value) => {
    if (!value) return;
  };

  /* 編輯、複製課程時，載入教師資料 */
  const loadTeacherData = async () => {
    setState({ isLoading: true });
    // 取得該課程之hostId
    await getSessionApi(sessionId)
      .then((response) => {
        const { status, data } = response;
        if(status === 'success'){
          const { hostId } = data[0];
          return hostId;
        }
      })
      .then(async (hostId) => {
        // 取得教師資料
        const params = { targetUserId: hostId };
        const response = await getOrganizationStaffsApi(isHome)(organizationId, classId)(params);
        if (response?.data?.userProfiles.length) {
          const { id, mobileNumber, nickname, thumbnailUrl, } = response?.data.userProfiles[0];
          const choice = [{
            content: mobileNumber,
            id,
            imagePath: thumbnailUrl,
            title: nickname,
          }];
          setState({ choice });
        } else {
          setAlert('無教師資訊', 'error');
        }
      });
    setState({ isLoading: false });
  };
  useEffect(() => {
    (editStatus === EDIT_STATUS.COPY || editStatus === EDIT_STATUS.EDIT)
      && loadTeacherData();
  }, []);


  return (
    <UiTransferListModal>
      <Button
        loading={isLoading}
        disabled={isDisabled}
        onClick={() => getModalStateHandler(true)}
      >
        {buttonName || '請選擇'}
      </Button>
      {(helperText && error && choice.length === 0) && (
        <UiFormHelperText >
          {helperText}
        </UiFormHelperText>
      )}
      <span>
        {
          showText ? showText :(choice.length === 0 ?
            choice :
            choice.map(item => (<span key={item.id}> {item.title} (ID:{item.id}) {item.content || ''} </span>)))
        }
      </span>

      <Modal
        isIconShow={false}
        isOpen={changeModalState}
        title={title}
        content={
          <>
            {
              isShowSearch && <UiSearchBox>
                <Select
                  label="搜尋條件"
                  options={selectOptions}
                  submitHandler={value => onSearchChange(value, 'searchType')}
                />
                <IconInput
                  placeholder='搜尋條件'
                  value={searchValue}
                  onChange={value => onSearchChange(value, 'searchValue')}
                  onClick={value => onSearchChange(value, 'searchValue')}
                />
              </UiSearchBox>
            }
            {tabs}
            {tagContent}
            {search && <IconInput
              placeholder={search.placeholder}
              onChange={onSubmitHandler}
              onClick={onSubmitHandler} />
            }
            {children}
            <TransferList
              type={type}
              data={data}
              getValue={getValueHandler}
              closeHanlder={closeHandler}
              value={choice[0]?.id}
              isPreson={isPreson}
              isLoading={isDataLoading}
              dataTotal={dataTotal}
              onRadioChange={radioChangeHandler}
              onScrollToApi={onScrollToApi}
              errorMsg={errorMsg}
              getCurrentValue={getCurrentValue}
            />
            {data.length === 0 && errorMsg && <UiErrorMsg>{errorMsg}</UiErrorMsg>}
          </>
        }
        getModalState={getModalStateHandler}
      />
    </UiTransferListModal>
  );
};

TransferListModal.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  search: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    onSearchHandler: PropTypes.func.isRequired
  }),
  value: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  isPreson: PropTypes.bool,
  resetData: PropTypes.func,
  children: PropTypes.node,
  tabs: PropTypes.node,
  tagContent: PropTypes.node,
  errorMsg: PropTypes.string,
  dataTotal: PropTypes.number,
  onScrollToApi: PropTypes.func,
  getCurrentValue: PropTypes.func,
  editStatus: PropTypes.string,
  isDataLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};
