import styled from 'styled-components';

export const UiOrganizationPaperBox = styled.div`
	display: flex;
  flex-direction: column;
	width: 100%;
  flex: 1;
`;

export const UiTableBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const UiNoDataBox = styled.div`
	display: flex;
  flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #8B90A0;
	padding: 20px 0;
`;