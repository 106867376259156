import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar as MatAvatar,
  Radio as MatRadio,
  RadioGroup as MatRadioGroup
} from '@material-ui/core';

import { Button } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useIntersectionObserver } from 'utils/hooks/useIntersectionObserver';
import {
  UiTransferList,
  UiTransferContent,
  UiCheckList,
  UiCheckTitle,
  UiCheckItem,
  UiCheckContent,
  UiButtonBox
} from './TransferList.style';

/**
 * 多/單選列表可搜尋 type singleChoice(單選)  multipleChoice(多選)
 */

export const TransferList = (
  {
    listTitle = '列表',
    type = 'multipleChoice',
    data = [],
    dataTotal = 0,
    getValue,
    closeHanlder,
    value,
    isPreson,
    isLoading,
    onRadioChange,
    onScrollToApi,
    getCurrentValue = () => {},
  }) => {
  const [{ radioValue, left, right, isCallApi }, setState] = useSetState({
    radioValue: '',
    submitValue: '',
    left: data,
    right: [],
    isCallApi: false,
    onRadioChange: () => { }
  });

  const transferContentRef = useRef(null);
  const ref = useRef(null);
  const isBottomVisible = useIntersectionObserver(ref,{
    root: transferContentRef.current,
    threshold:0
  },false);

  const radioChangeHandler = value => {
    const redioObjectValue = data.filter(item => item.id === value)[0];
    onRadioChange(value);
    setState({
      radioValue: value,
      right: redioObjectValue || []
    });
  };

  const teansferHandler = (id, type) => {
    if (type === 'left') {
      const newItem = left.filter(item => item.id === id)[0];
      const not = left.filter(item => item.id !== id);
      setState({
        left: not,
        right: [newItem, ...right]
      });
      getCurrentValue(not,[newItem, ...right]);
    } else {
      const newItem = right.filter(item => item.id === id)[0];
      const not = right.filter(item => item.id !== id);
      setState({
        right: not,
        left: [newItem, ...left]
      });
      getCurrentValue([newItem, ...left] , not);
    }

  };

  const submitHandler = () => {
    getValue && getValue(right);
  };

  useEffect(() => {
    setState({ left: data });
    value && radioChangeHandler(value);
  }, [data]);

  useEffect(()=>{
    if(isBottomVisible && (left.length + right.length) < dataTotal) {
      setState({isCallApi: true});
    }else{
      setState({isCallApi: false});
    }
  },[isBottomVisible]);

  useEffect(()=>{
    if(!isCallApi || isLoading) return;
    onScrollToApi(isCallApi);
  },[isBottomVisible, isCallApi]);

  const customList = (title, dataList, contrlType) =>
    <UiCheckList type={type}>
      <UiCheckTitle>{title}</UiCheckTitle>
      {type === 'singleChoice'
        ? <MatRadioGroup name="list" value={radioValue} onChange={e => radioChangeHandler(e.target.value)}>
            {dataList.map(item =>
              <label key={item.id}>
                <UiCheckItem >
                  <MatRadio
                    value={item.id}
                  />
                  {isPreson && <MatAvatar alt={item.title} src={item.imagePath} spacing='samll' />}
                  <UiCheckContent>
                    <div>{item.title}(ID:{item.id})</div>
                    <div>{item.content}</div>
                  </UiCheckContent>
                </UiCheckItem>
              </label>
            )}
          </MatRadioGroup>
        : dataList.map(item =>
            <UiCheckItem key={item.id} onClick={() => teansferHandler(item.id, contrlType)}>
              <MatAvatar alt={item.title} src={item.imagePath} />
              <UiCheckContent>
                <div>{item.title}</div>
                <div>{item.content}</div>
              </UiCheckContent>
            </UiCheckItem>)
      }
      {
        contrlType === 'left' && <div ref={ref} style={{ width: '100%', height: '20px', }}></div>
      }
    </UiCheckList>;

  return (
    <UiTransferList>
      <UiTransferContent ref={transferContentRef}>
        {customList(listTitle, left, 'left')}
        {type === 'multipleChoice' && customList('已選擇', right, 'right')}
      </UiTransferContent>
      <UiButtonBox>
        <Button buttonColor='highlight' onClick={() => closeHanlder()}>取消</Button>
        <Button onClick={() => submitHandler()} disabled={(!radioValue && right.length === 0)}>確定</Button>
      </UiButtonBox>
    </UiTransferList>
  );
};

TransferList.propTypes = {
  listTitle: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  dataTotal: PropTypes.number,
  getValue: PropTypes.func,
  closeHanlder: PropTypes.func,
  isPreson: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRadioChange: PropTypes.func,
  onScrollToApi: PropTypes.func,
  getCurrentValue: PropTypes.func,
};


