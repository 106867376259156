import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon as Iconify } from '@iconify/react';
import { MonitorUsersMenu } from '../MonitorUsersMenu/MonitorUsersMenu';

export const MonitorCell = ({ children, color, users }) => {
  const [anchorEl, setAnchorEl] = useState();
  /* 開關 MonitorUsersMenu */
  const toggleMonitorUsersMenu = (event) => {
    setAnchorEl(event?.currentTarget || null);
  };
  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem'
        }}
      >
        <Box style={{ display: 'flex' }}>
          <Iconify icon="material-symbols:circle" color={color} width="12" />
        </Box>
        <span
          onMouseEnter={event => toggleMonitorUsersMenu(event)}
        >
          {children}
        </span>
      </Box>
      <MonitorUsersMenu
        anchorEl={anchorEl}
        users={users}
        onClose={toggleMonitorUsersMenu}
      />
    </>
  );
};

MonitorCell.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  users: PropTypes.array,
};