import styled from 'styled-components';

export const UiSessionReport = styled.div`
display: block;
padding: 48px 32px;
`;

export const UiSessionEmpty = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;