import { stringify } from 'query-string';
import { Api } from 'services/api';


/**
 * 班級列表
 * @param {object} params
 */
export const getClasses = organizationId => async params => {
  const { nowPage = 0, rowsPage, name, ownerName, ownerMobileNumber, createdAfter, status, expired } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (name) queryParams.name = name;
  if (ownerName) queryParams.ownerName = ownerName;
  if (ownerMobileNumber) queryParams.ownerMobileNumber = ownerMobileNumber;
  if (createdAfter) queryParams.createdAfter = createdAfter;
  if (status) queryParams.status = status;
  if (expired) queryParams.expired = expired;

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/groups?${querystring}`);
  return response;
};

/**
 * 取得  所有班級 list  limit 100
 */
export const getAllClasses = async params => {
  return getClasses({ rowsPage: 100, ...params });
};

/**
 * 建立 班級資訊
 * @param {srting} organizationId
 *
 * @param {srting} ownerId
 * @param {srting} name
 * @param {srting} description
 * @param {integer} expirationTime
 * @param {srting} educationName
 * @param {array} grades
 */
export const createClassInfo = organizationId => async (params = {}) => {
  const response = await Api.post(`/organizations/${organizationId}/groups`, params);
  return response;
};

/**
 * 編輯 班級資訊
 * @param {srting} organizationId
 * @param {srting} classId
 *
 * @param {srting} ownerId
 * @param {srting} name
 * @param {srting} description
 * @param {integer} expirationTime
 * @param {srting} educationName
 * @param {array} grades
 */
export const editClassInfo = (organizationId, classId) => async (params = {}) => {
  const response = await Api.put(`/organizations/${organizationId}/groups/${classId}`, params);
  return response;
};

/**
 * 移除 班級資訊
 * @param {srting} organizationId
 * @param {srting} classId
 */
export const removeClassInfo = (organizationId, classId) => () => {
  const response = Api.delete(`/organizations/${organizationId}/groups/${classId}`);
  return response;
};

/**
 * 取得班級邀請代碼
 * @param {string} classId
 */
 export const getClassInviteCode = async (organizationId,classId) => {
  const response = await Api.post(`/organizations/${organizationId}/groups/${classId}/invitation-codes`);
  return response;
};


/**
 * 取得班級資訊
 * @param {string} organizationId
 * @param {string} classId
 */
 export const getGroupById = async (organizationId,classId) => {
  const response = await Api.get(`/organizations/${organizationId}/groups/${classId}`);
  return response;
};

/**
 * 複製班級
 * @param {string} organizationId
 * @param {string} groupId
 * @returns
 */
export const copyGroup = async (organizationId, groupId) => {
  const response = await Api.post(`/organizations/${organizationId}/groups/${groupId}/copy`);
  return response;
};