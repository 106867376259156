// import { IconInput, Select } from 'components';
import { IconInput } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiActionBox, UiflexBox } from 'styles';

export const SubjectPermissionActions = ({
  filterData,
  onChange,
  onSearch
}) => {
  /* click 搜尋鈕 */
  const clickSearchIcon = value => {
    const params = {
      ...(value && { userName: value })
    };
    onSearch(params);
  };
  /* 按下 Enter 搜尋 */
  const keyPressSearchInput = event => {
    const value = event.target.value;
    if (event.key === 'Enter') {
      const params = {
        ...(value && { userName: value })
      };
      onSearch(params);
    }
  };
  return (
    <UiActionBox>
      <UiflexBox>
        {/* <Select
          label="學制"
          // options={educationOptions}
          // onChangeHandler={educationChangeHandler}
          // value={educationalSystem}
          options={[]}
          value={''}
        />
        <Select
          label="科目"
          // options={subjectOptions}
          // submitHandler={subjectChangeHandler}
          // value={subject}
          options={[]}
          value={''}
        /> */}
        <IconInput
          placeholder="搜尋"
          value={filterData.keyword}
          onChange={value => onChange(value)}
          onClick={clickSearchIcon}
          onKeyPress={keyPressSearchInput}
        />
      </UiflexBox>
    </UiActionBox>
  );
};

SubjectPermissionActions.propTypes = {
  filterData: PropTypes.shape({ keyword: PropTypes.string }),
  onChange: PropTypes.func,
  onSearch: PropTypes.func
};
