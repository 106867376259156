import React, {useState , useEffect, useMemo} from 'react';
import { TextField, Box } from '@material-ui/core';
import { phoneNumberValid, taiwanPhoneValid } from 'utils/validation';
import { ConnectLineButton } from 'components';
import { PHONE_LENGTH_NOT_INCLUDE_COUNTY_CODE } from 'constants/index';

/**
 * 檢查原本表單上的手機號碼與輸入的手機號碼與是否不一樣
 * @param {object} { formMobile: string, keyInMobile: string }
 * @returns
 */
function checkIsMobileNotEquality ({ formMobile, keyInMobile }) {
  // 原本表單上的手機號碼長度
  const formMobileLength = formMobile?.length;
  // 原本表單上的手機號碼（不含國碼）
  const formMobileNotIncludeCountryCode = formMobile?.substring(formMobileLength - PHONE_LENGTH_NOT_INCLUDE_COUNTY_CODE, formMobileLength);

  // 正在輸入的手機號碼
  const keyInMobileLength = keyInMobile.length;
  // 正在輸入上的手機號碼（不含國碼）
  const keyInMobileNotIncludeCountryCode = keyInMobile.substring(keyInMobileLength - PHONE_LENGTH_NOT_INCLUDE_COUNTY_CODE, keyInMobileLength);

  const isNotEquality = formMobileNotIncludeCountryCode !== keyInMobileNotIncludeCountryCode;
  return isNotEquality;
}

export function ContactInput({
  helperText,
  defaultObject,
  showTitle,
  onChange,
  isShowLineNotify,
  inputIndex,
}) {
  const [relation, setRelation] = useState('');
  const [mobile , setMobile] = useState('');
  const isConnectedLineNotify = mobile === defaultObject?.mobile && defaultObject?.lineLinked;
  const isValidPhone = phoneNumberValid(mobile) || taiwanPhoneValid(mobile);

  // 表單上手機號碼與輸入的手機號碼是否不一樣
  const isMobileNotEquality = checkIsMobileNotEquality({ formMobile: defaultObject?.mobile, keyInMobile: mobile });
  const isDisabledLineButton = !isValidPhone || isMobileNotEquality;

  const changeRelation = (event) => {
    setRelation(event.target.value);
  };
  const changeMobile = (event) => {
    setMobile(event.target.value);
  };
  useEffect(() => {
    onChange(relation, mobile);
  }, [relation, mobile]);

  useEffect(() => {
    if(defaultObject?.relation) {
      setRelation(defaultObject.relation);
      setMobile(defaultObject.mobile);
    }
  }, [defaultObject]);

  const errorMessage = useMemo(() => {

    const result = {
      relation: {show: false, message: ''},
      mobile:{show: false, message: ''},
    };
    if(helperText !== '') {
      const temp = helperText.split('::');

      result.relation.show = temp[0] === 'relation';
      result.mobile.show = temp[0] === 'mobile';
      if(result.relation.show) {
        result.relation.message = temp[1];
      }
      if(result.mobile.show) {
        result.mobile.message = temp[1];
      }
    }
    return result;
  }, [helperText]);

  return (
    <>
      {showTitle && <Box mb='12px'>聯絡人</Box>}
      <Box display='flex' alignItems='center'>
        <Box maxWidth='120px' width="100%" mr='8px'>
          <TextField error={errorMessage.relation.show} helperText={errorMessage.relation.message} value={relation} label="關係" variant="outlined" onChange={changeRelation}></TextField>
        </Box>
        <Box maxWidth='272px' width="100%" mr='8px'>
          <TextField error={errorMessage.mobile.show} helperText={errorMessage.mobile.message} value={mobile} fullWidth={true} label="手機號碼" variant="outlined" onChange={changeMobile}></TextField>
        </Box>
        {isShowLineNotify &&
          <Box width="100%">
            <ConnectLineButton isDisabled={isDisabledLineButton} isConnected={isConnectedLineNotify} inputIndex={inputIndex} />
          </Box>}
      </Box>
    </>

  );
}

export default ContactInput;