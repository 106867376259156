import React, { useEffect } from 'react';

import { Icon } from 'components';
import { useClass } from 'store/class';
import { UiHomeInfo, UiHomeInfoTitle, UiInfoBox, UiDescription, UiDetail } from './HomeInfo.style';


/**
 * 班級簡介頁面
 */

export const HomeInfo = () => {
  const [{ classBrief }, { getClassBrief }] = useClass();
  useEffect(() => {
    getClassBrief();
  }, []);
  return (
    <UiHomeInfo>
      <UiHomeInfoTitle>簡介</UiHomeInfoTitle>
      <UiInfoBox>
        <UiDescription>{classBrief.description}</UiDescription>
        <UiDetail>
          <div><Icon name='person' haveBg={false} />成員{classBrief.totalMemberCount}名</div>
          <div><Icon name='message' haveBg={false} />貼文{classBrief.totalPostCount}篇</div>
          <div><Icon name='photoAlbum' haveBg={false} />圖片{classBrief.totalAlbumEntryCount}張</div>
        </UiDetail>
      </UiInfoBox>

    </UiHomeInfo>
  );
};
