import { stringify } from 'query-string';
import { Api } from 'services/api';


/**
 * 取得班級列表
 * @param {string} createdBefore  搜尋建班時間在此之前的班級
 * @param {string} createdAfter  搜尋建班時間在此之後的班級
 */

export const getMyClasses = async params => {
  let queryParams = {};
  if (params) {
    const { createdBefore, createdAfter, ownerName, groupName } = params;
    if (createdBefore) queryParams.createdBefore = createdBefore;
    if (createdAfter) queryParams.createdAfter = createdAfter;
    if (ownerName) queryParams.ownerName = ownerName;
    if (groupName) queryParams.groupName = groupName;
  }

  const querystring = stringify(queryParams);
  const path = querystring ? `/groups?${querystring}` : '/groups';
  const response = await Api.get(path);
  return response;
};

/**
 * 建立獨立班級
 *
 * @param {string} schoolId
 * @param {string} name
 * @param {string} description
 * @param {number} expirationTime
 * @param {string} educationName
 * @param {array} grades
 *
 * @returns {array} school information
 */
export const createFreeGroup = async payload => {
  const response = await Api.post('/groups', payload);
  return response;
};

/**
 * Invite Users to Free Group 邀請使用者到無組織班級
 * @param {array of object} users
 * 使用者資訊清單	[{"userId": "rx0079"}, {"email": "rx0078@gmail.com"}, {"mobileNumber": "+886987654321"}]
 * @param {string} role  邀請使用者身分	hostCandidate , user
 */
export const inviteUsersToFreeGroup = classId => async params => {
  const response = await Api.post(`/groups/${classId}/users/invite`, params);
  return response;
};

/**
 * 取得班級邀請代碼
 * @param {string} classId
 */
export const getClassInviteCode = async classId => {
  const response = await Api.post(`/groups/${classId}/invitation-codes`);
  return response;
};

/**
 * 加入班級邀請代碼
 * @param {string} invitaeCode
 */
export const joinClassByInvitationCode = async invitaeCode => {
  const response = await Api.post(`/groups/invitation-codes/${invitaeCode}/join`);
  return response;
};

export const getClassSummary = classId => async category => {
  const querystring = stringify({ category });
  const response = await Api.get(`/groups/${classId}/dashboard/summary?${querystring}`);
  return response;
};

export const getClassBrief = async classId => {
  const response = await Api.get(`/groups/${classId}/brief`);
  return response;
};

/**
 * Update Group Visibility
 * 設定是否隱藏班級
 * @param {boolean} isHidden
 */
export const updateGroupVisibility = classId => async isHidden => {
  const params = {
    isHidden
  };
  const response = Api.put(`/groups/${classId}/visibility`, params);
  return response;
};


/**
 * Update Group
 * 更新班級資料
 * @param {string} name
 * @param {string} description
 * @param {integer} expirationTime
 * @param {string} year
 * @param {string} educationName
 * @param {array} grades
 * @param {string} thumbnailId (不可與 thumbnailUrl 併存)
 * @param {string} thumbnailUrl (不可與 thumbnailId 併存)
 */
export const updateGroup = classId => async params => {
  let queryParams = {};
  if (params) {
    const {
      name,
      description,
      expirationTime,
      year,
      educationName,
      grades,
      schoolId,
      thumbnailId,
      thumbnailUrl,
    } = params;

    if (name) queryParams.name = name;
    if (description) queryParams.description = description;
    if (expirationTime) queryParams.expirationTime = expirationTime;
    if (year) queryParams.year = year;
    if(educationName) queryParams.educationName = educationName;
    if(grades) queryParams.grades = grades;
    if (schoolId) queryParams.schoolId = schoolId;
    if (thumbnailId && !thumbnailUrl) queryParams.thumbnailId = thumbnailId;
    if ((thumbnailUrl && !thumbnailId) || thumbnailUrl === '') queryParams.thumbnailUrl = thumbnailUrl;
  }
  const response = await Api.put(`/groups/${classId}`, queryParams);
  return response;
};

/**
 * Delete Group
 */
export const deleteGroup = async classId => {
  const response = Api.delete(`/groups/${classId}`);
  return response;
};

/**
 * 獲取班級科目列表
 */
export const getGroupSubjects = async classId => {
  const response = Api.get(`/groups/${classId}/subjects`);
  return response;
};

/**
 * 獲取學年度
 */
 export const getSchoolYearOptions = async ({ full = false }) => {
  const querystring = stringify({ full });
  const response = Api.get(`/groups/school-year-options?${querystring}`);
  return response;
};

