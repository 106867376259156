import { BsHeaderTitle, MonitorSessionTable } from 'components';
import React from 'react';
import { UiBsContentContainer } from 'styles';

/**
 * 看課管理頁面
 */
export const MonitorSessionPage = () => {

  return (
    <>
      <BsHeaderTitle title="看課管理" />
      <UiBsContentContainer>
        <MonitorSessionTable />
      </UiBsContentContainer>
    </>
  );
};
