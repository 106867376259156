import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CitySelector, Select, FormCheckBoxGroup, RadioGroup, Button } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useOrganization } from 'store/organization';
import { EDUCATION_LEVEL, EDUCATION, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { FormHelperText as MatFormHelperText } from '@material-ui/core';
import {
  UiSchoolCitySelector,
  UiSchoolSelect,
  UieducationSelect,
  UiHelperText,
  UiSchoolBox
} from './SchoolCitySelector.style';

/**
 * 城市選擇器
 *
 * @returns {string} schoolId
 */

const educationArr = [
  {
    name: '國小',
    value: EDUCATION_LEVEL.ELEMENTARY
  },
  {
    name: '國中',
    value: EDUCATION_LEVEL.JUNIOR
  },
  {
    name: '高中',
    value: EDUCATION_LEVEL.SENIOR
  }
];

export const SchoolCitySelector = ({
  width,
  variant = 'filled',
  required = false,
  onChange = () => { },
  children,
  educationValue,
  schoolValue,
  gradesDefaultValue,
  setting = false,
}) => {
  const [{
    educationName,
    cityName,
    districtName,
    grades,
    organizationId,
    loading,
    isShow,
  }, setState] = useSetState({
    educationName: '', // API 學制
    cityName: '', //API 縣市
    districtName: '', // API 鄉鎮區
    grades: [], // 年級
    organizationId: '',
    loading: false,
    isShow: !setting
  });
  const [{ publicOrganizations }, { getPublicOrganizations }] = useOrganization();
  const { data, dataMap: organizationsMap  } = publicOrganizations;
  const organizations = data.map(item => ({ name: item.officialName, value: item.id }));
  const groupGradeSelectionSetting = organizationsMap[organizationId]?.groupGradeSelectionSetting;

  useEffect(() => {
    if(!cityName || !districtName || !educationName) return;
    fetchPublicSchools({ cityName, districtName, educationName });
  },[cityName, districtName, educationName]);

  const fetchPublicSchools = async ({ cityName, districtName, educationName }) => {
    setState({ loading: true });
    await getPublicOrganizations({ cityName, districtName, educationName });
    setState({ loading: false });
    if (schoolValue) setState({ schoolName: schoolValue });
  };

  const cityChangeHandler = ({ county, township }) => {
    setState({ cityName: county, districtName: township });
  };

  const organizationsChangeHandler = value => {
    setState({ organizationId: value });
  };

  const educationChangeHandler = education => {
    setState({ educationName: education });
  };

  const gradesChangeHandler = value => {
    setState({ grades: value });
  };

  const showSchoolSelect = () => {
    setState({ isShow: !isShow });
  };

  useEffect(() => {
    onChange({ grades, organizationId, educationName });
  }, [grades, organizationId, educationName]);

  useEffect(() => {
    if(groupGradeSelectionSetting === ORGANIZATION_SETTINGS_VALUE.SINGLE){
      setState({ grades: [] });
    }
  },[groupGradeSelectionSetting]);

  useEffect(() => {
    if (educationValue === '') return;
    setState({
      educationName: educationValue,
      grades: gradesDefaultValue || [],
    });
  }, [educationValue]);

  return (
    <UiSchoolCitySelector width={width}>
      {
        isShow ||
        <UiSchoolBox>
          <Button onClick={() => showSchoolSelect()}>編輯學校</Button>
        </UiSchoolBox>
      }
      {
        isShow &&
        <UieducationSelect
          width={children ? '49%' : '100%'}
        >
          {children}
        </UieducationSelect>
      }
      {
        isShow &&
        <UieducationSelect
          width={children ? '49%' : '100%'}
          active={required && !educationName}
        >
          <Select
            label='學制'
            width='100%'
            isOutline={false}
            options={educationArr}
            submitHandler={educationChangeHandler}
            variant={variant}
            value={educationName}
          />
          {
            required && !educationName &&
            <UiHelperText>
              <MatFormHelperText>本欄位為必填</MatFormHelperText>
            </UiHelperText>
          }
        </UieducationSelect>
      }
      {
        isShow &&
        <CitySelector
          variant={variant}
          required={required}
          onChange={cityChangeHandler}
          isFullWidth={false}
        />
      }
      {
        isShow && <UiSchoolSelect active={required && !organizationId} width="100%">
          <Select
            label='學校'
            width="100%"
            isOutline={false}
            options={organizations}
            value={organizationId}
            submitHandler={organizationsChangeHandler}
            loading={loading}
            variant={variant}
          />
          {
            required && !organizationId &&
            <UiHelperText>
              <MatFormHelperText>本欄位為必填</MatFormHelperText>
            </UiHelperText>
          }
        </UiSchoolSelect>
      }
      {
        isShow && organizationId && (
          <>
            {
              groupGradeSelectionSetting === ORGANIZATION_SETTINGS_VALUE.SINGLE ? (
                 <RadioGroup
                  label="年級"
                  data={EDUCATION[educationName]}
                  value={grades.length > 0 && grades[0]}
                  onChange={(e) => gradesChangeHandler([e.target.value])}
                />
              ) : (
                <FormCheckBoxGroup
                  groupClassName="formCheckBoxGroup"
                  name='grades'
                  label='年級'
                  data={EDUCATION[educationName]}
                  onChange={gradesChangeHandler}
                  value={grades}
                />
              )
            }
          </>
        )
      }
    </UiSchoolCitySelector >
  );
};

SchoolCitySelector.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node,
  educationValue: PropTypes.string,
  schoolValue: PropTypes.string,
  gradesDefaultValue: PropTypes.array,
  setting: PropTypes.bool,
};


