import React, { useEffect } from 'react';
import { EDIT_STATUS, EDUCATION_LEVEL } from 'constants/index';
import { useParams, useHistory } from 'react-router-dom';
import {
  Table,
  Button,
  IconInput,
  Select,
  TransferListModal,
  Modal,
  Icon,
  Breadcrumbs,
  UploadButtonExcel
} from 'components';
import {
  ORGANIZATION_SETTINGS_VALUE,
  SUBJECT_NAME_TO_CODE_FOR_SUBJECT_PERMISSION
} from 'constants/index';
import _ from 'lodash';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useCustomer } from 'store/customer';
import { useClass } from 'store/class';
import { useOrganization } from 'store/organization';
import { UiAvatar } from './CustomerTable.style';
import { getOrgCustomers } from 'services/api/organization/customer';
import { getCheckOneClubId as getCheckOneClubIdApi } from 'services/api/oneClub';
import {
  getCustomer as getCustomerApi,
  joinStudentsIntoOrganization as joinStudentsIntoOrganizationApi
} from 'services/api/organization/customer';
import { PromiseQueue } from 'utils/promiseQueue';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * 學生列表Table
 */
const schema = {
  nickname: {
    name: '機構暱稱',
    defaultValue: ''
  },
  originalName: {
    name: '姓名',
    defaultValue: ''
  },
  originalNickname: {
    name: '暱稱',
    defaultValue: '無'
  },
  avatar: {
    name: '照片',
    defaultValue: ''
  },
  mobileNumber: {
    name: '聯絡電話',
    defaultValue: '無'
  },
  email: {
    name: '信箱',
    defaultValue: '無'
  },
  status: {
    name: '狀態',
    defaultValue: '無'
  }
};

const stateShowText = [
  {
    id: 'status',
    state: {
      invited: '邀請中(等待同意)',
      rejected: '拒絕邀請',
      joined: '已加入',
      departed: '已離開'
    }
  }
];

const selectOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];

const selectModalOptions = [
  {
    name: '手機號碼',
    value: 'mobileNumber'
  },
  {
    name: '機構暱稱',
    value: 'nickname'
  },
  {
    name: '帳號ID',
    value: 'customerId'
  }
];

/* 取得匯入學生需要刪除的 columns */
function getRemoveColumnsForImportingStudents(orgs) {
  const educationNames = orgs?.dataInfo?.educationNames || [];
  const hasElementary = educationNames.includes(EDUCATION_LEVEL.ELEMENTARY);
  const hasJuniorHigh = educationNames.includes(EDUCATION_LEVEL.JUNIOR);
  const hasHigh = educationNames.includes(EDUCATION_LEVEL.SENIOR);
  const removeColumnsForImportingStudents = [];
  !hasElementary && removeColumnsForImportingStudents.push('ELEMENTARY');
  !hasJuniorHigh && removeColumnsForImportingStudents.push('JUNIOR');
  !hasHigh && removeColumnsForImportingStudents.push('HIGH');

  // const removeColumnsForImportingStudents = ['ELEMENTARY', 'HIGH'];
  return removeColumnsForImportingStudents;
}

/* 格式化成 subject codes */
function formatSubjectToSubjectCodes(row) {
  const { elementary, junior, high, ...rest } = row;
  const elementarySubjects = elementary?.split(',') || [];
  const elementarySubjectCodes = elementarySubjects.map(
    subject => SUBJECT_NAME_TO_CODE_FOR_SUBJECT_PERMISSION[subject]
  );
  const juniorHighSubjects = junior?.split(',') || [];
  const juniorHighSubjectCodes = juniorHighSubjects.map(
    subject => SUBJECT_NAME_TO_CODE_FOR_SUBJECT_PERMISSION[subject]
  );
  const highSubjects = high?.split(',') || [];
  const highSubjectCodes = highSubjects.map(
    subject => SUBJECT_NAME_TO_CODE_FOR_SUBJECT_PERMISSION[subject]
  );
  return {
    ...rest,
    ...elementary && { elementary: elementarySubjectCodes },
    ...junior && { junior: juniorHighSubjectCodes },
    ...high && { high: highSubjectCodes },
  };
}

/* 產生 JoinStudentsIntoOrganizationApi Payload */
function generatePayloadForJoinStudentsIntoOrganizationApi(row) {
  const elementarySubject = {
    id: EDUCATION_LEVEL.ELEMENTARY,
    subjects: row?.elementary,
  };
  const juniorHighSubject = {
    id: EDUCATION_LEVEL.JUNIOR,
    subjects: row?.junior,
  };
  const highSubject = {
    id: EDUCATION_LEVEL.SENIOR,
    subjects: row?.high,
  };
  const settings = [];
  row?.elementary && settings.push(elementarySubject);
  row?.junior && settings.push(juniorHighSubject);
  row?.high && settings.push(highSubject);
  const payload = {
    customer: {
      userId: row.id,
      nickname: row.nickname,
      settings,
    }
  };
  return payload;
}

export const CustomerTable = () => {
  const { organizationId, classId } = useParams();
  const { setAlert } = useAlert();
  const history = useHistory();
  const [
    { customers },
    { getCustomers, addUserToGroup, removeUserToGroup }
  ] = useCustomer();
  const [{ classes }] = useClass();
  const [{ orgs }, { getOrgInfo }] = useOrganization();
  const [
    {
      nowPage,
      rowsPage,
      searchType,
      searchValue,
      nickname,
      mobileNumber,
      modalSearchType,
      modalSearchValue,
      customerData,
      customerTotal,
      customerNowPage,
      customerRowsPage,
      isLoading
    },
    setState
  ] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    nickname: '',
    mobileNumber: '',
    searchType: '',
    searchValue: '',
    modalSearchType: '',
    modalSearchValue: '',
    customerData: [],
    customerTotal: 0,
    customerNowPage: 0,
    customerRowsPage: 10,
    isLoading: false
  });
  const removeColumnsForImportingStudents = getRemoveColumnsForImportingStudents(
    orgs
  );

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: classes.dataInfo && `${classes.dataInfo?.name || ''} 學生管理`,
      link: `/organization/${organizationId}/class/${classId}/customer`,
      icon: 'people'
    }
  ];

  const goCreateSession = () => {
    history.push(
      `/organization/${organizationId}/customer/${EDIT_STATUS.CREATE}`
    );
  };
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onSubmitHandler = (value, key) => {
    if (key === 'searchValue') {
      if (searchType === '') return;
      setState({
        nickname: '',
        mobileNumber: '',
        [searchType]: value,
        nowPage: 0
      });
    }
    setState({
      [key]: value
    });
  };
  const transferDataFormat = arr => {
    const orgCustomers = _.differenceBy(arr, customers.data, 'id');
    orgCustomers.forEach(item => {
      item.imagePath = '';
      item.title = item.nickname;
      item.content = item.email;
    });
    return orgCustomers;
  };

  const getTransferListValue = async params => {
    const userIdList = params.map(item => item.id);
    const isSuccess = await addUserToGroup({
      userIdList,
      userProfiles: params
    });
    if (isSuccess)
      await getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
  };

  const onTransferListSearchHandle = async (value, key) => {
    if (key === 'searchValue') {
      if (modalSearchType === '') return;
      setState({
        isLoading: true
      });

      const params = {
        [modalSearchType]: value,
        nowPage: 0,
        rowsPage: 10
      };

      const { data, isSuccess } = await getOrgCustomers(organizationId, params);
      if (isSuccess) {
        const { total, userProfiles } = data;
        setState({
          customerData: transferDataFormat(userProfiles),
          customerTotal: total,
          customerNowPage: 0
        });
      }

      setState({
        modalSearchValue: value,
        isLoading: false
      });
    } else if (key === 'searchType') {
      setState({ modalSearchType: value });
    }
  };

  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params }) => {
    const [{ isOpen }, setModal] = useSetState({
      isOpen: false
    });
    const userProfiles = params;
    const buttons = [
      {
        text: '確認',
        func: async () => {
          const isSuccess = await removeUserToGroup({ userProfiles });
          if (isSuccess)
            await getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
        }
      },
      {
        text: '取消',
        color: 'highlight'
      }
    ];
    const remove = () => {
      setModal({
        isOpen: true
      });
    };
    const getModalStateHandler = state => {
      setModal({
        isOpen: state
      });
    };
    return (
      <>
        <Button onClick={remove} type="table">
          移出班級
        </Button>
        <Modal
          text="此操作將會將此學生從班級移除"
          isOpen={isOpen}
          getModalState={getModalStateHandler}
          buttons={buttons}
        />
      </>
    );
  };

  // eslint-disable-next-line react/prop-types
  const orgCustomersActionComponents = ({ params: { id } }) => {
    const goPage = editStatus => () =>
      history.push(
        `/organization/${organizationId}/customer/${id}/${editStatus}`
      );

    return (
      <>
        <Icon name="watchFile" onClick={goPage(EDIT_STATUS.READ)} />
        <Icon name="edit" onClick={goPage(EDIT_STATUS.EDIT)} />
      </>
    );
  };

  const onScrollToApi = async isCallApi => {
    if (!isCallApi) return;
    const params = {
      [modalSearchType]: modalSearchValue,
      nowPage: customerNowPage,
      rowsPage: customerRowsPage
    };

    setState({ isLoading: true });
    const { data, isSuccess } = await getOrgCustomers(organizationId, params);
    if (isSuccess) {
      const { total, userProfiles } = data;
      const newCustomerData = customerData.concat(userProfiles);
      setState({
        customerData: transferDataFormat(newCustomerData),
        customerTotal: total,
        customerNowPage: customerNowPage + 1
      });
    }
    setState({ isLoading: false });
  };

  const getCurrentValue = (left, right) => {
    setState({
      customerData: left
    });
  };

  /* 驗證 id 格式 */
  const validateIdFormat = (body, setViewData) => {
    let isCorrect = true;
    let result = [];
    const rules = /^[\w]+$/; // 英文 or 數字 or 英數字
    body.every(row => {
      const id = row[0];
      isCorrect = rules.test(id);
      if (isCorrect) {
        const data = ['檢查中...', id, ...row];
        result.push(data);
      } else {
        const data = ['帳號格式錯誤', id, ...row];
        result.push(data);
      }
      return isCorrect;
    });
    setViewData(result);
    return !isCorrect;
  };
  /* 驗證 id 是否重複 */
  const validateIdRepeat = (body, setViewData) => {
    let isRepeat = false;
    let result = [];
    body.some((row, i) => {
      let data = null;
      const id_i = row[0];
      body.some((row, j) => {
        const id_j = row[0];
        if (id_i === id_j && i !== j) isRepeat = true;
        return isRepeat;
      });
      isRepeat
        ? (data = ['帳號重複', id_i, ...row])
        : (data = ['檢查中...', id_i, ...row]);
      result.push(data);
      return isRepeat;
    });
    setViewData(result);
    return isRepeat;
  };
  /* 驗證 id 是否存在於 oneClub */
  const validateIdExistsOneClub = async (body, setViewData) => {
    let isError = false;
    const tasks = body.map(row => {
      const id = row[0];
      return getCheckOneClubIdApi(id);
    });
    const responses = await Promise.all(tasks);
    const result = body.map((item, index) => {
      const isUserExist =
        responses[index].status === 'success' &&
        responses[index]?.data?.status === 'failure' &&
        responses[index]?.data?.error?.errorCode === 20115;
      const message = isUserExist ? '檢查中...' : '帳號不存在OneClub或其他錯誤';
      return [message, ...item];
    });
    if (result.some(item => item[0] !== '檢查中...')) isError = true;
    setViewData(result);
    return isError;
  };
  /* 驗證 id 是否存在於機構中 */
  const validateIdExistsOrganization = async (body, setViewData) => {
    let isError = false;

    const tasks = body.map(row => {
      const id = row[0];
      return getCustomerApi(organizationId, id)();
    });
    const responses = await Promise.all(tasks);
    const result = body.map((item, index) => {
      const messages = [];
      const isUserExist =
        responses[index].status === 'success' && responses[index]?.data;
      const otherError =
        responses[index].status === 'failure' &&
        responses[index]?.error?.errorCode !== 20004;
      isUserExist && messages.push('帳號已存在機構中');
      otherError && messages.push('有其他的錯誤');
      const message = messages.length > 0 ? messages.join(',') : '驗證成功';
      return [message, ...item];
    });
    if (result.some(item => item[0] !== '驗證成功')) isError = true;
    setViewData(result);
    return isError;
  };
  /* 驗證 excel 格式 */
  const validateExcel = async (body, setViewData) => {
    let isError = false;
    isError = validateIdFormat(body, setViewData);
    if (isError) return isError;
    isError = validateIdRepeat(body, setViewData);
    if (isError) return isError;
    isError = await validateIdExistsOneClub(body, setViewData);
    if (isError) return isError;
    isError = await validateIdExistsOrganization(body, setViewData);
    return isError;
  };

  /* 學生加入組織完成後動作 */
  const joinStudentsIntoOrganizationAfterComplete = (
    tableData,
    setViewData,
    setIsLoading,
    setIsCompleteImport
  ) => (isStopped, complete, errors, total) => {
    const viewData = [];
    for (let i = 0; i < total; i++) {
      const data = tableData[i];
      const row = [data.id, data.nickname];
      if (errors.length && complete.length === i) {
        // 有錯誤且已將完成的資料放進 viewData
        row.unshift('匯入失敗');
        viewData.push(row);
        break;
      } else if (errors.length) {
        // 第一筆資料即有錯誤
        row.unshift('匯入失敗');
        viewData.push(row);
        break;
      } else if (complete.length && complete.length > i) {
        // 無錯誤，且每次只能 set 一筆進 table
        row.unshift('匯入成功');
        viewData.push(row);
      }
    }
    setViewData(viewData);
    if (complete.length + errors.length === total || isStopped) {
      setIsCompleteImport(true);
      setIsLoading(false);
    }
  };
  /* 匯入學生彈窗 - 匯入 func */
  const joinStudentsIntoOrganization = (
    tableData,
    setViewData,
    setIsLoading,
    setIsCompleteImport
  ) => {
    try {
      setIsLoading(true);
      const tasks = tableData.map(data => {
        const newData = formatSubjectToSubjectCodes(data);
        const payload = generatePayloadForJoinStudentsIntoOrganizationApi(newData);
        return async () =>
          joinStudentsIntoOrganizationApi(organizationId, payload);
      });
      const promiseQueue = new PromiseQueue(tasks, 1);
      promiseQueue.run(
        joinStudentsIntoOrganizationAfterComplete(
          tableData,
          setViewData,
          setIsLoading,
          setIsCompleteImport
        ),
        true
      );
    } catch (error) {
      setIsLoading(false);
      setAlert(error, 'error');
    }
  };
  /* 匯入學生彈窗 - 完成匯入後 func */
  const handleAfterImport = () => {
    fetchCustomers();
  };

  /* 打 API，「選擇學生」的內容 */
  const fetchOrgCustomers = async () => {
    setState({ isLoading: true });

    const params = {
      nowPage: customerNowPage,
      rowsPage: customerRowsPage
    };

    const { data, isSuccess } = await getOrgCustomers(organizationId, params);
    if (isSuccess) {
      const { total, userProfiles } = data;
      const newCustomerData = customerData.concat(userProfiles);
      setState({
        customerData: transferDataFormat(newCustomerData),
        customerTotal: total,
        customerNowPage: customerNowPage + 1
      });
    }

    setState({ isLoading: false });
  };

  const fetchCustomers = async () => {
    setState({ isLoading: true });
    await getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
    setState({ isLoading: false });
  };

  useEffect(() => {
    if (!classId) getOrgInfo();
  }, []);

  useEffect(() => {
    if (!customers.isLoaded) return;
    fetchOrgCustomers();
  }, [customers]);

  useEffect(() => {
    fetchCustomers();
  }, [nowPage, rowsPage, nickname, mobileNumber]);

  return (
    <UiTable>
      {classId && <Breadcrumbs list={BreadcrumbsList} />}
      <UiActionBox>
        <UiflexBox>
          <Select
            label="搜尋條件"
            options={selectOptions}
            submitHandler={value => onSubmitHandler(value, 'searchType')}
          />
          <IconInput
            placeholder="搜尋條件"
            value={searchValue}
            onChange={value => onSubmitHandler(value, 'searchValue')}
          />
        </UiflexBox>
        {classId ? (
          <UiflexBox>
            <Button
              buttonColor="info"
              icon="exitToApp"
              onClick={() => history.goBack()}
            >
              回到上一頁
            </Button>
            <TransferListModal
              name="ownerId"
              type="multipleChoice"
              title="選擇學生"
              data={customerData || []}
              onChange={getTransferListValue}
              isShowSearch
              selectOptions={selectModalOptions}
              onSearchChange={onTransferListSearchHandle}
              dataTotal={customerTotal}
              onScrollToApi={onScrollToApi}
              getCurrentValue={getCurrentValue}
              isDataLoading={isLoading}
            />
          </UiflexBox>
        ) : (
          <UiflexBox>
            {orgs?.dataInfo?.organizationImportStudentsSetting ===
              ORGANIZATION_SETTINGS_VALUE.ALLOW && (
              <UploadButtonExcel
                validateExcel={validateExcel}
                handleJoin={joinStudentsIntoOrganization}
                handleAfterImport={handleAfterImport}
                removeColumns={removeColumnsForImportingStudents}
              >
                匯入學生
              </UploadButtonExcel>
            )}
            <Button
              buttonColor="highlight"
              icon="add"
              onClick={() => goCreateSession()}
            >
              邀請學生
            </Button>
          </UiflexBox>
        )}
      </UiActionBox>
      <Table
        data={customers.data.map(item => ({
          ...item,
          avatar: <UiAvatar alt={item.nickname} src={item.thumbnailUrl} />
        }))}
        schema={schema}
        stateShowText={stateShowText}
        changePage_Rows={changePage_Rows}
        totalPage={customers.total}
        ActionComponents={
          classId ? ActionComponents : orgCustomersActionComponents
        }
        nowPage={nowPage}
      />
    </UiTable>
  );
};
