import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { UiTextField, UiTitle } from './WebResourceModalContent.style';
/**
 * 常用資源彈窗
 */

export const WebResourceModalContent = ({ type, action, currentData, ModalType, Action, valid, inputs, setInputs }) => {
  if (type === ModalType.Type && action === Action.Add) {
    const label = `${type}名稱`;
    return (
      <TextField
        label={label}
        value={inputs.newTypeName}
        error={!valid.isTextNotEmptyPattern}
        helperText={!valid.isTextNotEmptyPattern ? '內容不得為空' : ''}
        onChange={e => setInputs({ newTypeName: e.target.value})}
        variant="outlined"
        fullWidth
      ></TextField>
    );
  }
  if (type === ModalType.Resource && action === Action.Add) {
    return (<>
      <UiTitle>類別: { currentData.resource?.title }</UiTitle>
      <UiTextField>
        <TextField
          label="資源名稱"
          value={inputs.newResourceName}
          error={!valid.isTextNotEmptyPattern}
          helperText={!valid.isTextNotEmptyPattern ? '內容不得為空' : ''}
          onChange={e => setInputs({ newResourceName: e.target.value})}
          variant="outlined"
          fullWidth
        ></TextField>
      </UiTextField>
      <UiTextField>
        <TextField
          label="網址"
          error={!valid.isTextNotEmptyPattern || !valid.isURLPattern}
          helperText={!valid.isTextNotEmptyPattern ? '內容不得為空' : !valid.isURLPattern ? '無效網址' : ''}
          value={inputs.newResourceURL}
          onChange={e => setInputs({ newResourceURL: e.target.value})}
          variant="outlined"
          fullWidth
        ></TextField>
      </UiTextField>  
    </>);
  }
  if (type === ModalType.Type && action === Action.Edit) {
    return (<>
      <UiTextField>
        <TextField
          label="資源名稱"
          value={inputs.editedTypeName}
          error={!valid.isTextNotEmptyPattern}
          helperText={!valid.isTextNotEmptyPattern ? '內容不得為空' : ''}
          onChange={e => setInputs({ editedTypeName: e.target.value})}
          variant="outlined"
          fullWidth
        ></TextField>
      </UiTextField>
    </>);
  }
  if (type === ModalType.Resource && action === Action.Edit) {
    return (<>
      <UiTitle>類別: { currentData.resource?.title }</UiTitle>
      <UiTextField>
        <TextField
          label="資源名稱"
          value={inputs.editedResourceName}
          error={!valid.isTextNotEmptyPattern}
          helperText={!valid.isTextNotEmptyPattern ? '內容不得為空' : ''}
          onChange={e => setInputs({ editedResourceName: e.target.value})}
          variant="outlined"
          fullWidth
        ></TextField>
      </UiTextField>
      <UiTextField>
        <TextField
          label="網址"
          value={inputs.editedResourceURL}
          error={!valid.isTextNotEmptyPattern || !valid.isURLPattern}
          helperText={!valid.isTextNotEmptyPattern ? '內容不得為空' : !valid.isURLPattern ? '無效網址' : ''}
          onChange={e => setInputs({ editedResourceURL: e.target.value})}
          variant="outlined"
          fullWidth
        ></TextField>
      </UiTextField>  
    </>);
  }
  if (type === ModalType.Type && action === Action.Delete) {
    return (<>
      <p>此類別下所有資源將會一起刪除，確認要刪除 ?</p>
    </>);
  }if (type === ModalType.Resource && action === Action.Delete) {
    return (<>
      <p>確認要刪除「{ currentData.resourceData?.name }」 ?</p>
    </>);
  }
};

WebResourceModalContent.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  ModalType: PropTypes.object,
  Action: PropTypes.object,
  inputs: PropTypes.object,
  setInputs: PropTypes.func,
  currentData: PropTypes.object,
  valid: PropTypes.object
};
