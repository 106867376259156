import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useClass } from 'store/class';
import { getGroupById as getGroupByIdApi } from 'services/api/organization/class';
import { getOrganizationSessionReport } from 'services/api/organization/session';
import {
  UiSessionReport,
  UiSessionEmpty,
} from './SessionReport.style';
import {
  Breadcrumbs,
  Loading,
} from 'components';
import SessionSummary from './SessionSummary';
import SessionRecord from './SessionRecord';
import SessionPerformance from './SessionPerformance';
// import Screenshots from './Screenshots';


/**
 * 課堂報告
 */

export const SessionReport = () => {
  // 網址參數
  const history = useHistory();
  const { groupId } = history.location.state;
  const { organizationId, classId, sessionId, timeSpanId } = useParams();
  const [{ classes }] = useClass();

  // 麵包屑
  const classBreadcrumbs = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes.dataInfo.name}-課程管理`,
      link: `/organization/${organizationId}/class/${classId}/session`,
      icon: 'importContacts'
    },
    {
      name: '課堂報吿',
      link: `/organization/${organizationId}/class/${classId}/session/${sessionId}/timespan/${timeSpanId}/report`,
      icon: 'assessment'
    }
  ];
  const sessionBreadcrumbs = [
    {
      name: '課程總覽',
      link: `/organization/${organizationId}/class/session`,
      icon: 'school'
    },
    {
      name: '課堂報吿',
      link: `/organization/${organizationId}/session/${sessionId}/timespan/${timeSpanId}/report`,
      icon: 'assessment'
    },
  ];

  const BreadcrumbsList = classId ? classBreadcrumbs : sessionBreadcrumbs;

  // state
  const [{
    isLoading,
    summaryData, // 摘要
    attendanceData, // 上課記錄
    performanceData, // 課堂表現
    // screenshotData, // 課堂截圖
    classData, // 班級資訊
  }, setState] = useSetState({
    isLoading: true,
    summaryData: null,
    attendanceData: null,
    performanceData: null,
    // screenshotData: null,
    classData: [],
  });

  // fetch
  const fetchSummaryOrganizationSessionReport = () => {
    const params = {
      organizationId,
      sessionId,
      timeSpanId,
      section: 'summary',
    };
    return getOrganizationSessionReport(params);
  };

  const fetchAttendanceOrganizationSessionReport = () => {
    const params = {
      organizationId,
      sessionId,
      timeSpanId,
      section: 'attendance',
    };
    return getOrganizationSessionReport(params);
  };

  const fetchPerformanceOrganizationSessionReport = () => {
    const params = {
      organizationId,
      sessionId,
      timeSpanId,
      section: 'performance',
    };
    return getOrganizationSessionReport(params);
  };

  // 由於oneboard 無法顯示 onebook等教材畫面，暫時拿掉
  // const fetchScreenshotOrganizationSessionReport = () => {
  //   const params = {
  //     organizationId,
  //     sessionId,
  //     timeSpanId,
  //     section: 'screenshot',
  //   };
  //   return getOrganizationSessionReport(params);
  // };

  const fetchSessionData = async () => {
    const res = await Promise.all([
      fetchSummaryOrganizationSessionReport(),
      fetchAttendanceOrganizationSessionReport(),
      fetchPerformanceOrganizationSessionReport(),
      // fetchScreenshotOrganizationSessionReport(),
    ]);

    if(res || res.length > 0){
      setState({
        summaryData: res[0].data,
        attendanceData: res[1].data,
        performanceData: res[2].data,
        // screenshotData: res[3].data,
      });
    }

    setState({
      isLoading: false,
    });
  };

   /* 打API，取得班級資訊 */
   const fetchGroupById = async () => {
    const { isSuccess, data } = await getGroupByIdApi(organizationId, groupId);
    if(isSuccess){
      setState({ 
        classData: data,
      });
    }
  };

  useEffect(() => {
    fetchSessionData();
    fetchGroupById();
  }, []);

  return (
    <UiSessionReport>
      <Breadcrumbs list={BreadcrumbsList} />
      {
        isLoading && <Loading />
      }
      {
        (!isLoading && summaryData && attendanceData && performanceData) &&
          <>
            <SessionSummary data={summaryData} classData={classData} />
            <SessionRecord data={attendanceData}/>
            <SessionPerformance data={performanceData} />
            {/* <Screenshots data={screenshotData} /> */}
          </>
      }
      {
        !isLoading && (
          !summaryData || 
          !attendanceData || 
          !performanceData 
        ) && <UiSessionEmpty>查無此課程紀錄</UiSessionEmpty>
      }
    </UiSessionReport>
  );
};
