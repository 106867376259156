import React from 'react';
import { UiBsContentContainer } from 'styles';
import { BsHeaderTitle, WebResource } from 'components';

/**
 * 常用資源頁面
 */

export const WebResourcePage = () => {
  return (
    <>
      <BsHeaderTitle title="常用資源" />
      <UiBsContentContainer>
        <WebResource/>
      </UiBsContentContainer>
    </>);
};


