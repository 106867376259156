import styled from 'styled-components';

export const UiTooltip = styled.div`
  &.tooltip-enter {
    opacity: 0.01;
  };
  &.tooltip-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in;
  }
  &.tooltip-exit {
    opacity: 1;
  };
  &.tooltip-exit-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in;
  };

	background: #FFF;
	border-radius: 4px;
	box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
	max-width: 320px;
	padding: 8px 16px;
	text-align: left;
	transition: 0.5s;
	white-space: pre;
	position: absolute;
	left: 50%;
	top: ${({ isReverse }) => isReverse ? 'initial' : '100%' };
  bottom: ${({ isReverse }) => isReverse ? '100%' : 'initial' };
	z-index: 999;
`;