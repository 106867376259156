import React from 'react';
import PropTypes from 'prop-types';
import { UiTooltip } from './Tooltip.style';
import { CSSTransition } from 'react-transition-group';


/**
 * 提示組件
 */

export const Tooltip = (props) => {
  const { children, isOpen, isReverse } = props;

  return (
    <CSSTransition
      in={isOpen}
      classNames='tooltip'
      unmountOnExit
    >
      <UiTooltip
        isReverse={isReverse}
      >
        {children}
      </UiTooltip>
    </CSSTransition>
  );
};

Tooltip.defaultProps = {
  isOpen: false,
  isReverse: false,
};

Tooltip.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isReverse: PropTypes.bool,
};
