import styled from 'styled-components';


export const UiMyOrganizations = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
	width: 100%;
	height: 100vh;
	background-color: #000;

	.pulse {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 200px;
		height: 200px;
		background: rgba(57, 197, 187, 1);
		border-radius: 50%;
		transform: translate(-50%, -50%);
		animation: animate 2s infinite linear;
	}

	.pulse h1 {
		position: relative;
		text-align: center;
		color: #fff;
		z-index: 3;
		line-height: 165px;
		text-transform: uppercase;
	}

	@keyframes animate {
		0% {
			box-shadow: 0 0 0 0 rgba(57, 197, 187, 1), 0 0 0 0 rgba(57, 197, 187, 1);
		}

		50% {
			box-shadow: 0 0 0 100px rgba(57, 197, 187, 0), 0 0 0 0 rgba(57, 197, 187, 1);
		}

		100% {
			box-shadow: 0 0 0 100px rgba(57, 197, 187, 0), 0 0 0 50px rgba(57, 197, 187, 0);
		}
	}
`;

