import styled from 'styled-components';
import { TableContainer } from '@material-ui/core';

export const UiTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: 'unset',
  boxShadow: 'unset',
  '& > table': {
    '& > thead': {
      backgroundColor: '#0787CF',
      '& > tr > th': {
        color: '#FFF',
      }
    },
    '& > tbody': {
      '& > tr:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      }
    },
  }
}));