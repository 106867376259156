import { SUBJECT } from 'constants/index';

export const ALL_SUBJECTS = [
  SUBJECT.CH,
  SUBJECT.MA,
  SUBJECT.EN,
  SUBJECT.NA,
  SUBJECT.SO
];

export const ALL_SUBJECTS_CODE = 'ALL';