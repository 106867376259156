import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper as MatPaper,
  InputBase as MatInputBase,
  IconButton as MatIconButton,
} from '@material-ui/core';
import {
  Search as MatSearchIcon,
} from '@material-ui/icons';
import { Icon } from 'components';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 4px',
		width: 250,
		border: theme.input.borderColor,
		boxShadow: 'none',

		'&:hover': {
			border: theme.input.borderHoverColor,
		}
	},

	input: {
		marginLeft: theme.spacing(1),
		padding: '11px 0',
		flex: 1,
	},

	iconbutton: {
		padding: 10,
	},

	divider: {
		margin: 4,
		height: 28,
	},
}));

export const IconInput = ({ placeholder, onChange, icon, type, getValue,value,isFull=false,onClick, onKeyPress }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);

  const changeHandler = event => {
    getValue(event.target.value);
    setInputValue(event.target.value);
  };

  const keyDownHandler = event => {
    if (event.key === 'Enter') onChange(inputValue);
  };

  return (
    <MatPaper
      className={classes.root}
      style={{width: `${isFull ? '100%' :'250px'}`, border: '1px solid rgba(0, 0, 0, 0.42)'}}>
      {
        icon && <Icon name={icon} haveBg={false} />
      }
      <MatInputBase
        type={type}
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        value={inputValue}
        onKeyPress={onKeyPress || keyDownHandler}
        onChange={changeHandler}
      />
      {!icon &&
        <MatIconButton className={classes.iconButton} aria-label="search" onClick={() => onClick(inputValue)}>
          <MatSearchIcon />
        </MatIconButton>
      }
    </MatPaper>
  );
};

IconInput.defaultProps = {
  placeholder: '搜尋檔案名稱',
  onChange: () => { },
  type: 'text',
  getValue: () => { },
};

IconInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.string,
  getValue: PropTypes.func,
  value:PropTypes.any,
  isFull:PropTypes.bool
};
