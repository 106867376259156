const images = {};

const req = require.context('../assets/images', false,/\.(png|jpe?g|svg)$/);

req.keys().forEach(key => {
  const imgName = key.replace(/^.+\/([^/]+)\.(png|jpe?g|svg)$/, '$1');
  images[imgName] = req(key);
});


export default name => {
    return images[name];
};

