export const FETCH_ERROR = {
  errorCode:'9527',
  message:'fetch error'
};


export const ERROR_CODE = {
  1000: '內部錯誤',
  20006:'使用者已加入這個機構！',
  20009:'無效的使用者',
  20020:'邀請已存在，請勿重複邀請！',
  500002: '卡號已重複'
};
