import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { BsHeaderTab } from 'components';
import { useUser } from 'store/user';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';

/**
 * 資源列表page tab
 */

export const BsResourceManagementPageHeaderTab = ({ activeIndex = 0 }) => {
  const { organizationId } = useParams();
  const [{ myOrganization: { organization } }] = useUser();
  const history = useHistory();
  const goPath = path => history.push(path);

  const [tabData, setTabData] = useState([
    { label: '機構資源', onClick: () => goPath(`/organization/${organizationId}/resources`) }
  ]);

  useEffect(() => {
    if(organization.accessToPublicResourceSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW){
      setTabData(prev => [
        ...prev,
        { label: '公開資源', onClick: () => goPath(`/organization/${organizationId}/resources/public`) }
      ]);
    }
  },[organization]);

  return (
      <BsHeaderTab data={tabData} activeIndex={activeIndex} theme="primary" />
    );
};

BsResourceManagementPageHeaderTab.propTypes = {
  activeIndex: PropTypes.number
};
